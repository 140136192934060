import React, { useLayoutEffect, useRef } from 'react';
import classNames from 'classnames';
import { MDFIcon } from './MDFIcon';

export interface IColumnHeader {
  adpFieldGrabbable?: 'disabled' | undefined;
  adpFieldId?: string;
  className?: string;
  id: string;
  label: string;
  sortable?: boolean;
}

export interface IMDFListHeaderProps {
  className?: string;
  columnHeaders: IColumnHeader[];
  defaultSortable?: boolean;
  sortBy?: string;
  sortDirection?: 'asc' | 'desc';
  onSort?: (columnId) => any;
}

export const MDFListHeader = (props: IMDFListHeaderProps) => {
  const listHeaderRef = useRef(null);

  const handleHeaderClick = (id) => {
    props.onSort?.(id);
  };

  const renderColumnHeader = (column, idx) => {
    const currentSortColumn = props.sortBy === column.id;
    const sortable = column.sortable === false ? column.sortable : column.sortable || props.defaultSortable;
    const labelProps: any = {};

    if (column.adpFieldId) {
      labelProps['data-adp-field-id'] = column.adpFieldId;
    }

    if (column.adpFieldGrabbable) {
      labelProps['data-adp-field-grabbable'] = 'disabled';
      delete labelProps['data-adp-field-id'];
    }

    return (
      <div className={classNames('mdf-list-header-column', column.className)} key={idx} {...labelProps}>
        <span className="mdf-list-header-column-name">{column.label}</span>
        {sortable && <span onClick={() => handleHeaderClick(column.id)}><MDFIcon className={'fa fa-sort' + `${currentSortColumn && props.sortDirection ? '-' + props.sortDirection : ''}`}></MDFIcon></span>}
      </div>
    );
  };

  const updateWidth = () => {
    if (listHeaderRef.current && listHeaderRef.current.parentElement) {
      const contentNodeRef = listHeaderRef.current.parentElement.querySelector('[data-list-header-id="mdfInfiniteList"]');

      if (contentNodeRef && contentNodeRef.clientWidth !== listHeaderRef.current.clientWidth) {
        listHeaderRef.current.style.width = contentNodeRef.clientWidth + 'px';
      }
    }
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWidth);
    updateWidth();

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, []);

  return (
    <div ref={listHeaderRef} className={classNames('mdf-list-header', props.className)}>
      {props.columnHeaders.map(renderColumnHeader)}
    </div>
  );
};
