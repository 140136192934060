import React, { useEffect, useState } from 'react';
import { MDFCore } from '@adp-wfn/mdf-core';
import { SdfAlertBanner } from '@waypoint/react-components';
import { ActionButtonPlacement, BannerAlertStatus } from '@waypoint/web-components/types/components/alert-banner/alert-banner';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-name';

export interface WfnAlertBannerProps {
  actionButtonPlacement?: ActionButtonPlacement;
  ariaLive?: 'polite' | 'assertive';
  autoClose?: boolean;
  autoCloseAfter?: number;
  closeable?: boolean;
  closeIconTitle?: string;
  children?: React.ReactNode;
  hidden?: boolean;
  hideIcon?: boolean;
  icon?: IconName;
  iconTitle?: string;
  status?: BannerAlertStatus;
  useAnimation?: boolean;
  onSdfAfterClose?: (event: CustomEvent) => void;
  onSdfAfterOpen?: (event: CustomEvent) => void;
  onSdfDismiss?: (event: CustomEvent) => void;
}

export const WfnAlertBanner = ({
  ariaLive = 'polite',
  hidden,
  ...props
}: WfnAlertBannerProps) => {
  const [hide, setHide] = useState(hidden);

  if (MDFCore.shouldLog()) {
    console.warn(`WfnAlertBanner(): Entering. hide = ${hide}, hidden = ${hidden}`);
  }

  useEffect(() => {
    // Delay showing the alert to allow the browser to catch
    // the aria-live region, and then update the region.
    // This should get screen readers to read alerts that are
    setTimeout(() => setHide(hidden), 25);
  }, [hidden]);

  return (
    <div aria-live={ariaLive}>
      <SdfAlertBanner {...props} hidden={hide ? hide : undefined}/>
    </div>
  );
};
