import React, { useEffect, useState } from 'react';
import { Button, OverlayTrigger, Placement, Tooltip } from '@synerg/vdl-react-components';
import { MDFIcon } from './MDFIcon';
import { generateId } from '@adp-wfn/mdf-core';
import { SdfButton } from '@waypoint/react-components';

export interface IMDFChatButtonProps {
  // The placement for the chat button.
  placement?: Placement;
  // An id for the chat data.
  rid: string;
}

interface IMDFChatButtonState {
  allowed?: boolean;
  displayChat: boolean;
  label?: string;
  titleTooltip?: string;
  tokenUri?: string;
}

export const MDFChatButton = (props: IMDFChatButtonProps) => {
  const [state, setState] = useState<IMDFChatButtonState>({ displayChat: false });
  let content: any = '';

  // This function will likely run only once. It never needs to run again unless props.rid changes.
  useEffect(() => {
    window['WFNShell']?.getChatData(props.rid)
      .then((chatData: any) => {
        setState(chatData);
      })
      .catch((error) => console.error('Error fetching chat data: ', error));
  }, [props.rid]);

  const launchChatSession = () => {
    if (state.allowed) {
      window['WFNShell'].launchChatSession(state.tokenUri, props.rid);
    }
  };

  if (state.displayChat) {
    if (window['isLegacyAppShell']) {
      content = <OverlayTrigger
        trigger={['hover', 'focus']}
        placement={props.placement || 'top'}
        overlay={
          <Tooltip id={generateId('MDFChatButton_tooltip')} className="mdf-chat-button-tooltip">
            {state.titleTooltip}
          </Tooltip>
        }
      >
        <Button buttonStyle="link" className="mdf-chat-button" onClick={launchChatSession}>
          {state.label}
          <MDFIcon className={'vdl-button__icon fa-2x icon-status-chat'} />
        </Button>
      </OverlayTrigger>;
    }
    else {
      content = <SdfButton buttonTitle={state.titleTooltip} icon="action-chat" iconOnly onClick={launchChatSession}></SdfButton>;
    }
  }

  return content;
};

MDFChatButton.displayName = 'MDFChatButton';
