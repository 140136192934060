import React from 'react';
import { isEqual, range } from 'lodash';
import { FormatHelper, LocaleHelper } from '@adp-wfn/mdf-core';
import { Button } from '@synerg/vdl-react-components';
import { SdfIcon } from '@waypoint/react-components';
import PropTypes from 'prop-types';
import { CompareOption, DateHelper } from './DateHelper';
import { getFirstEnabledCell, getLastEnabledCell, getNextActiveElement, getNextRangeActiveElement, getPreviousActiveElement, getPreviousRangeActiveElement } from '../util/DOMHelper';
import FaArrowCircleLeft from 'adp-react-icons/lib/fa/arrow-circle-left';
import FaArrowCircleRight from 'adp-react-icons/lib/fa/arrow-circle-right';
import FaAngleDown from 'adp-react-icons/lib/fa/angle-down';

const YEAR_PAGE_SIZE = 12;

interface IWeekDays {
  sunday: boolean;
  monday: boolean;
  tuesday: boolean;
  wednesday: boolean;
  thursday: boolean;
  friday: boolean;
  saturday: boolean;
}

export type CalenderType = 'Date' | 'Range';

enum CalendarRenderingModes {
  date = 'date',
  year = 'year',
  month = 'month'
}

// This is the main interface to hold all settings
export interface ICalendarProps {
  // The current date/startDate in case of DateRangePicker to select
  selectedDate: Date;
  // The default calendar date used to render Calender month, date when it opens incase of no selectedDate available
  defaultCalendarDate?: Date;
  // Called when a date is clicked
  onChange: (selectedDate: Date, isValid?: boolean, validationMessage?: string, isoDate?: string) => void;
  // Called when the calendar rendering month is changed to allow the consuming application to reset the data
  onVisibleMonthChange?: (startDateOfTheMonth: Date) => void;
  // min date to select
  min?: Date;
  // max date to select
  max?: Date;
  // To suppress weekday selection
  blackoutWeekDays?: IWeekDays;
  // To set weekOffset
  weekOffset?: number;
  // To enable/disable the widget
  disabled?: boolean;
  // Custom dates to apply style to specific date. Also, these values are passed directly to the custom date renderer
  dates?: ICalendarDateRenderer[];
  // To apply custom class
  className?: string;
  // custom renderer for date
  dateRenderer?: any;
  // reset renderingMode to Date
  reset: boolean;
  // monthFormat format month name in calender header
  monthFormat?: string;
  // calendar current rendering month
  renderingDate?: Date;
  // calendar end Date, this is used to display end date in case of between(DateRangePicker) dates
  endDate?: Date;
  // calender type Date calender or date range calender
  calenderType?: CalenderType;
}

// Interface to track the widget state
interface ICalendarState {
  // selected date
  selectedDate?: Date;
  // date used to render the current layout
  renderingDate?: Date;
  // calendar which holds start|end Date to show as selected
  endDate?: Date;
  // year layout current page number
  currentYearPageNumber?: number;
  // current rendering mode
  renderMode?: CalendarRenderingModes;
  // To supress weekday selection
  blackoutWeekDays?: number[];
  // min date to select
  min?: Date;
  // max date to select
  max?: Date;
  // Custom dates to apply style to specific date. Also, these values are passed directly to the custom date renderer
  dates?: ICalendarDateRenderer[];
  // To set weekOffset
  weekOffset?: number;
}

// Interface for each item in the widget
interface ICalendarItem<T> {
  // value of the item
  value: T;
  // the value is selected or not
  isSelected?: boolean;
  // to enable/disable the value selection
  isEnabled?: boolean;
  // to keep active date for tab key support
  isActive?: boolean;
  // value is in range between start and end date
  isInRange?: boolean;
  // event to handle when the value is selected
  onSelect?: (value: any) => void;
}

// Interface for Date layout
interface ICalendarDateRenderer extends ICalendarItem<number> {
  // class name of the rendering date */
  className?: string;
  // additional data to be set by the consuming application, which can be used by the template */
  data?: any;
  date: Date;
}

// Interface for year layout
interface ICalendarYearRendererProps {
  // list of years to render */
  years: ICalendarItemRender<number>[];
  // to enable/disable the previous button */
  hasPreviousPage: boolean;
  // to enable/disable the next button */
  hasNextPage: boolean;
}

// Interface for month layout
interface ICalendarItemRender<T> extends ICalendarItem<T> {
  // custom class name which will be passed by the consuming application*/
  className: string;
}

class CalendarHelper {
  static getRenderingDays = (renderingDate: Date, selectedDate: Date, min: Date, max: Date, weekOffset = 0, dates: ICalendarDateRenderer[], blackoutWeekDays: number[], endDate: Date): ICalendarDateRenderer[] => {
    const dateRange = CalendarHelper.getRenderingDateRange(renderingDate, weekOffset);

    let minSelectableDate = dateRange.monthStartDate;
    let maxSelectableDate = dateRange.monthEndDate;

    if (min && DateHelper.compare(min, dateRange.monthStartDate) === CompareOption.Greater) {
      minSelectableDate = min;
    }

    if (max && DateHelper.compare(max, dateRange.monthEndDate) === CompareOption.Less) {
      maxSelectableDate = max;
    }

    dates = dates || [];

    let isFirstItemActive = false;

    return dateRange.dates.map((item) => {
      let mappedRenderingDate: ICalendarDateRenderer = {
        value: item.getUTCDate(),
        date: item
      };

      mappedRenderingDate.isSelected = DateHelper.equals(item, selectedDate);

      if (!mappedRenderingDate.isSelected && endDate) {
        mappedRenderingDate.isSelected = DateHelper.equals(item, endDate);
      }

      mappedRenderingDate.isEnabled = DateHelper.isInRange(item, minSelectableDate, maxSelectableDate);
      mappedRenderingDate.isInRange = DateHelper.isInRange(item, selectedDate, endDate) || DateHelper.isInRange(item, endDate, selectedDate);

      if (blackoutWeekDays && mappedRenderingDate.isEnabled) {
        mappedRenderingDate.isEnabled = !blackoutWeekDays.includes(item.getUTCDay());
      }

      const customDate = dates.find((date) => DateHelper.equals(date.date, item));

      // If custom date is specified, set the value in the response so that the custom template can access the values
      if (customDate) {
        // transfer customDate props to template
        mappedRenderingDate = Object.assign({}, mappedRenderingDate, { ...customDate });
      }

      mappedRenderingDate.className = `${mappedRenderingDate.isSelected && mappedRenderingDate.isEnabled ? 'calendar-grid-item__selected' : mappedRenderingDate.isEnabled ? mappedRenderingDate.isInRange ? 'calendar-grid-item__range__selected' : '' : 'calendar-grid-item__disabled'}`;

      if (DateHelper.isToday(item)) {
        mappedRenderingDate.className = `${mappedRenderingDate.isEnabled ? mappedRenderingDate.isSelected || !selectedDate ? 'calendar-grid-item__today' : '' : 'calendar-grid-item__disabled'}`;
      }

      if (mappedRenderingDate.isEnabled && !isFirstItemActive) {
        mappedRenderingDate.isActive = true;
        isFirstItemActive = true;
      }

      return mappedRenderingDate;
    });
  };

  static getRenderingDateRange = (renderingDate: Date, weekOffset = 0): { start: Date; end: Date; monthStartDate: Date; monthEndDate: Date; dates: Date[] } => {
    // Get last month days difference
    const renderingMonthStartDate = DateHelper.getFirstDayOfTheMonth(renderingDate);

    // Calculate the prev months days based on weekOffSet
    let diff = renderingMonthStartDate.getUTCDay() - weekOffset;

    if (diff < 0) {
      diff += 7;
    }

    // Set the difference as startDate to render the Calendar
    const startDate = new Date(renderingMonthStartDate);
    startDate.setUTCDate((diff - 1) * -1);

    // Get current month last day of the month
    const renderingMonthEndDate = DateHelper.getLastDayOfTheMonth(renderingDate);
    const endDate = new Date(renderingMonthEndDate);

    // Calculate number of days been added in the Calender
    const daysAdded = diff + renderingMonthEndDate.getUTCDate();

    // Find if any additional date require from next month
    let daysToAdd = daysAdded % 7;

    // If any additional days is required, add them as endDate and move to next month
    if (daysToAdd > 0) {
      daysToAdd = 7 - daysToAdd;
      endDate.setUTCMonth(endDate.getUTCMonth() + 1);
      endDate.setUTCDate(daysToAdd);
    }

    const dateToBegin = new Date(startDate);
    dateToBegin.setUTCDate(dateToBegin.getUTCDate() - 1);

    return {
      start: startDate,
      end: endDate,
      monthStartDate: renderingMonthStartDate,
      monthEndDate: renderingMonthEndDate,
      dates: range(0, daysAdded + daysToAdd).map((/* index */) => DateHelper.getDate(new Date(dateToBegin.setUTCDate(dateToBegin.getUTCDate() + 1))))
    };
  };

  static getRenderingYears = (renderingDate: Date, currentPage: number, selectedDate?: Date, min?: Date, max?: Date): ICalendarYearRendererProps => {
    const selectedYear = selectedDate ? selectedDate.getUTCFullYear() : 0;
    // Calculating current rendering page details
    const yearPosition = renderingDate.getUTCFullYear() % YEAR_PAGE_SIZE;
    const pageMinYear = renderingDate.getUTCFullYear() + (currentPage * YEAR_PAGE_SIZE) - yearPosition;
    const pageMaxYear = pageMinYear + YEAR_PAGE_SIZE;

    const maxYear = max ? max.getUTCFullYear() : pageMaxYear + 1;
    const minYear = min ? min.getUTCFullYear() : pageMinYear - 1;

    const years = range(pageMinYear, pageMaxYear).map((index) => {
      const isEnabled = (index >= minYear && index <= maxYear);
      const isSelected = (index === selectedYear);

      return {
        value: index,
        isSelected: isSelected,
        isEnabled: isEnabled,
        className: ` ${isSelected ? 'calendar-grid-select-item__selected' : ''} ${!isEnabled ? 'calendar-grid-select-item__disabled' : ''}`
      };
    });

    return {
      years: years,
      hasNextPage: pageMaxYear > maxYear,
      hasPreviousPage: pageMinYear < minYear
    };
  };

  static getRenderingMonths = (renderingDate: Date, selectedDate?: Date, min?: Date, max?: Date): ICalendarItemRender<string>[] => {
    const currentDate = DateHelper.getFirstDayOfTheYear(renderingDate);
    const selectedMonth = (selectedDate ? selectedDate.getUTCMonth() : -1);

    max = max ? max : currentDate;
    min = min ? min : currentDate;

    min = DateHelper.getFirstDayOfTheMonth(min);

    return range(0, 12).map((index) => {
      // Get month names
      const monthName = FormatHelper.getLocaleFormats().dateFormat.months[currentDate.getUTCMonth()].short;

      // Check the date range validity
      const isEnabled = (currentDate.getTime() >= min.getTime() && currentDate.getTime() <= max.getTime());
      currentDate.setUTCMonth(currentDate.getUTCMonth() + 1);

      return {
        value: monthName,
        isEnabled: isEnabled,
        isSelected: index === selectedMonth,
        index: index,
        className: (isEnabled ? index === selectedMonth ? 'calendar-grid-select-item__selected' : '' : 'calendar-grid-select-item__disabled')
      };
    });
  };
}

// Component to render the header section
const CalendarHeaderRenderer = ({ calendarState, onBackClick, onNextClick, onMonthSelect, onYearSelect, monthFormat, calenderType }) => {
  const isADPUnified = !window['isLegacyAppShell'];
  const hasBackButton = calendarState.renderMode !== CalendarRenderingModes.month;
  const hasNextButton = calendarState.renderMode !== CalendarRenderingModes.month;
  let monthName = FormatHelper.formatDate(calendarState.renderingDate, { month: monthFormat ? monthFormat : 'short' });
  // remove '.' for french locales
  monthName = monthName.replace('.', '');
  // short month names must be in title case.
  monthName = monthName.charAt(0).toUpperCase() + monthName.slice(1);

  const renderDate = calendarState.renderingDate;

  // adding +1 to renderDate to get next month and -1 to get prev month.
  const prevMonth = new Date(Date.UTC(renderDate.getUTCFullYear(), renderDate.getUTCMonth() - 1, renderDate.getUTCDate()));
  const nextMonth = new Date(Date.UTC(renderDate.getUTCFullYear(), renderDate.getUTCMonth() + 1, renderDate.getUTCDate()));
  const nextMonthName = FormatHelper.formatDate(nextMonth, { month: monthFormat ? monthFormat : 'long', year: 'numeric' });
  const prevMonthName = FormatHelper.formatDate(prevMonth, { month: monthFormat ? monthFormat : 'long', year: 'numeric' });

  const year = calendarState.renderingDate.getUTCFullYear();
  const prevText = calendarState.renderMode === CalendarRenderingModes.year ? FormatHelper.formatMessage('@@label_previous_year') : FormatHelper.formatMessage('@@previous_month');
  const nextText = calendarState.renderMode === CalendarRenderingModes.year ? FormatHelper.formatMessage('@@label_tlm_nextyear') : FormatHelper.formatMessage('@@next_month');

  return <div className="calendar-header">
    {hasBackButton &&
      <div className="calendar-header-navbutton">
        <Button buttonStyle="link" aria-label={prevText + ',' + ' ' + prevMonthName} onClick={onBackClick}>
          { isADPUnified ? <SdfIcon icon="nav-page-back" /> : <FaArrowCircleLeft /> }
        </Button>
      </div>
    }
    <div className="calendar-header-currentDate" id="calendarHeader">
      <a className="calendar-header-currentDate-selector" onClick={onMonthSelect} aria-label={FormatHelper.formatDate(calendarState.renderingDate, { month: monthFormat ? monthFormat : 'long' })} {...(calenderType !== 'Range' && { href: 'javascript:void(0);' })}>
        {monthName}
        {calenderType !== 'Range' && (isADPUnified ? <SdfIcon icon="action-menu-open" /> : <FaAngleDown />)}
      </a>
      <a className="calendar-header-currentDate-selector" onClick={onYearSelect} {...(calenderType !== 'Range' && { href: 'javascript:void(0);' })}>
        {year}
        {calenderType !== 'Range' && (isADPUnified ? <SdfIcon icon="action-menu-open" /> : <FaAngleDown />)}
      </a>
    </div>
    {hasNextButton &&
      <div className="calendar-header-navbutton">
        <Button buttonStyle="link" aria-label={nextText + ',' + ' ' + nextMonthName} onClick={onNextClick}>
          { isADPUnified ? <SdfIcon icon="nav-page-next" /> : <FaArrowCircleRight/> }
        </Button>
      </div>
    }
  </div>;
};

// Component to render date layout
const CalendarDateRenderer = ({ renderingDate, selectedDate, min, max, weekOffset, onChange, onKeyDown, dates, blackoutWeekDays, dateRenderer, endDate }) => {
  const daysToRender = CalendarHelper.getRenderingDays(renderingDate, selectedDate, min, max, weekOffset, dates, blackoutWeekDays, endDate);
  const rows = [];

  while (daysToRender.length) {
    rows.push(daysToRender.splice(0, 7));
  }

  return <div>
    {rows.map((days, index) => (
      <div className="calendar-grid" onKeyDown={onKeyDown} key={index}>
        {days.map((day, i) => (
          React.createElement(dateRenderer, {
            ...day,
            onSelect: (() => day.isEnabled ? onChange(day.date) : null),
            key: `day-${i}`
          })
        ))}
      </div>
    ))}
  </div>;
};

// Component to render each day
export const CalendarDateItemRenderer = (props: ICalendarDateRenderer) => {
  let day;
  const currentDate = props.date;
  const monthName = FormatHelper.getLocaleFormats().dateFormat.months[currentDate.getUTCMonth()].long;
  const year = currentDate.getUTCFullYear();
  const moment = LocaleHelper.dateAndTime;

  // If timezone is GMT- then add 1 day to the currentDate moment in order to make it match with the currentDate moment when the timezone is GMT and GMT+
  if (currentDate.toString().includes('GMT-')) {
    day = moment(currentDate).add(1, 'day').format('dddd');
  }
  else {
    day = moment(currentDate).format('dddd');
  }

  return <div className={props.isInRange ? `calendar-grid-item-range ${props.className}` : `calendar-grid-item ${props.className}`} {...(!props.isEnabled && { disabled: true })} {...(!props.isEnabled && { 'aria-disabled': true })}>
    <Button
      buttonStyle="link" className="calendar-grid-item-day"
      disabled={!props.isEnabled}
      onClick={(/* e */) => props.isEnabled ? props.onSelect(props.date) : null}
      {...(!props.isActive && { tabIndex: -1 })}
      aria-label={props.value + ' ' + monthName + ' ' + year + ' ' + day}
    >
      {props.value}
    </Button>
  </div>;
};

CalendarDateItemRenderer.displayName = 'CalendarDateItemRenderer';

// Component to render each week name
const CalendarWeekItemRenderer = ({ weekName }) => (
  <div className="calendar-weekdays-item">
    {weekName}
  </div>
);

// Component to render the week names layout
const CalendarWeekRenderer = ({ weekOffSet }) => {
  const weekDays: { short: string; long: string }[] = FormatHelper.getLocaleFormats().dateFormat.weeks;

  return <div><div className="calendar-weekdays">
    {range(weekOffSet, 7 + weekOffSet).map((index) => (
      <CalendarWeekItemRenderer weekName={weekDays[index % 7].short} key={`day-${index}`} />
    ))}
  </div>
  </div>;
};

const CalendarMonthItemRenderer = (props: ICalendarItemRender<string>) => (
  <div className="calendar-grid-select" {...(!props.isEnabled && { 'aria-disabled': true })}>
    <Button
      buttonStyle={(props.isEnabled && props.isSelected) ? 'primary' : 'secondary'}
      className={`calendar-grid-select-item ${props.className || ''}`}
      disabled={!props.isEnabled}
      onClick={() => props.isEnabled ? props.onSelect(props.value) : null}
    >
      {props.value}
    </Button>
  </div>
);

CalendarMonthItemRenderer.displayName = 'CalendarMonthItemRenderer';

const CalendarMonthRenderer = ({ renderingDate, selectedDate, min, max, onChange }) => {
  const monthsToRender = CalendarHelper.getRenderingMonths(renderingDate, selectedDate, min, max);

  return <div className="calendar-grid">
    {monthsToRender.map((month, i) => (
      <CalendarMonthItemRenderer {...month} onSelect={() => onChange(i)} key={`month-${i}`} />
    ))}
  </div>;
};

CalendarMonthRenderer.type = 'CalendarMonthRenderer';

const CalendarYearItemRenderer = (props: ICalendarItemRender<number>) => (
  <div className="calendar-grid-select" {...(!props.isEnabled && { 'aria-disabled': true })}>
    <Button
      buttonStyle={(props.isEnabled && props.isSelected) ? 'primary' : 'secondary'}
      className={`calendar-grid-select-item ${props.className || ''}`}
      disabled={!props.isEnabled}
      onClick={() => props.isEnabled ? props.onSelect(props.value) : null}
    >
      {props.value}
    </Button>
  </div>
);

CalendarYearItemRenderer.displayName = 'CalendarYearItemRenderer';

const CalendarYearRenderer = ({ renderingDate, currentPage, selectedDate, min, max, onChange }) => {
  const yearsToRender = CalendarHelper.getRenderingYears(renderingDate, currentPage, selectedDate, min, max);

  return <div className="calendar-grid">
    {yearsToRender.years.map((year, i) => (
      <CalendarYearItemRenderer {...year} onSelect={() => onChange(year.value)} key={`year-${i}`} />
    ))}
  </div>;
};

CalendarYearRenderer.displayName = 'CalendarYearRenderer';

export class Calendar extends React.Component<ICalendarProps, ICalendarState> {
  static propTypes = {
    selectedDate: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    onChange: PropTypes.func,
    onVisibleMonthChange: PropTypes.func,
    min: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    max: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
    weekOffset: PropTypes.number,
    disabled: PropTypes.bool,
    className: PropTypes.string,
    dateRenderer: PropTypes.any,
    reset: PropTypes.bool
  };

  dateRenderer: JSX.Element;

  // Initializing the widget
  constructor(props: ICalendarProps) {
    super(props);

    const selectedDate = this.initializeDate(props.selectedDate);
    const defaultCalendarDate = this.initializeDate(props.defaultCalendarDate);
    const renderingDate = this.initializeDate(props.renderingDate);
    const endDate = this.initializeDate(props.endDate);

    const calendarState: ICalendarState = {
      renderingDate: DateHelper.getFirstDayOfTheMonth(renderingDate || selectedDate || defaultCalendarDate || DateHelper.today),
      renderMode: CalendarRenderingModes.date,
      endDate,
      selectedDate
    };

    calendarState.currentYearPageNumber = 0;

    this.state = this.setCalendarState(props, calendarState);

    // Getting custom renderer from the children
    if (props.dateRenderer) {
      this.dateRenderer = props.dateRenderer;
    }
  }

  initializeDate(propsDate) {
    let date = null;

    if (propsDate) {
      date = DateHelper.getDate(propsDate);
    }

    return date;
  }

  componentDidUpdate(prevProps) {
    const selectedDate = this.props.selectedDate ? DateHelper.getDate(this.props.selectedDate) : null;

    if (!isEqual(prevProps, this.props)) {
      if (this.hasPropsChanged(this.props)) {
        this.setState({ selectedDate: selectedDate }, () => {
          const newState = this.setCalendarState(this.props, this.state);
          this.setState(newState);
        });
      }
    }
  }

  // This method shows the next/previous page layout based on the current rendering mode.
  private handleCalendarPage = (newPosition: number) => {
    if (this.state.renderMode === CalendarRenderingModes.year) {
      this.setState({ currentYearPageNumber: this.state.currentYearPageNumber + newPosition });
    }
    else {
      // get the 1st day of the rendering month
      const nextDate = DateHelper.getFirstDayOfTheMonth(this.state.renderingDate);
      // Change the rendering month based on the prev/next selection
      nextDate.setUTCMonth(nextDate.getUTCMonth() + newPosition);
      // update the rendering date
      this.setState({ renderingDate: nextDate });

      if (this.props.onVisibleMonthChange) {
        this.props.onVisibleMonthChange(nextDate);
      }
    }
  };

  private hasPropsChanged = (nextProps: ICalendarProps): boolean => {
    let hasPropsChanged = false;

    if (nextProps.blackoutWeekDays) {
      hasPropsChanged = isEqual(nextProps.blackoutWeekDays, this.state.blackoutWeekDays);
    }

    if (hasPropsChanged || this.state.blackoutWeekDays) {
      return true;
    }

    if (!hasPropsChanged && nextProps.dates) {
      hasPropsChanged = isEqual(nextProps.dates, this.state.dates);
    }

    if (hasPropsChanged || this.state.dates) {
      return true;
    }

    if (nextProps.selectedDate) {
      hasPropsChanged = !DateHelper.equals(this.initializeDate(nextProps.selectedDate), this.state.selectedDate);
    }

    if (hasPropsChanged || this.state.selectedDate) {
      return true;
    }

    if (nextProps.renderingDate) {
      hasPropsChanged = !DateHelper.equals(this.initializeDate(nextProps.renderingDate), this.state.renderingDate);
    }

    if (nextProps.endDate) {
      hasPropsChanged = !DateHelper.equals(this.initializeDate(nextProps.endDate), this.state.endDate);
    }

    if (nextProps.max) {
      hasPropsChanged = !DateHelper.equals(DateHelper.getDate(nextProps.max), this.state.max);
    }

    if (hasPropsChanged || this.state.max) {
      return true;
    }

    if (nextProps.min) {
      hasPropsChanged = !DateHelper.equals(DateHelper.getDate(nextProps.min), this.state.min);
    }

    if (hasPropsChanged || this.state.min) {
      return true;
    }

    if (nextProps.weekOffset) {
      hasPropsChanged = this.state.weekOffset !== nextProps.weekOffset;
    }

    if (nextProps.selectedDate) {
      hasPropsChanged = !DateHelper.equals(DateHelper.getDate(nextProps.selectedDate), this.state.selectedDate);
    }

    if (hasPropsChanged || this.state.selectedDate) {
      return true;
    }

    return hasPropsChanged;
  };

  private setCalendarState = (props: ICalendarProps, currentState: ICalendarState): ICalendarState => {
    // reset the renderingDate to selectedDate (if available) when popup is closed
    // but, on initial render, the selectedDate could be empty, so we need to use the renderingDate
    if (props.reset) {
      currentState.renderMode = CalendarRenderingModes.date;
      currentState.renderingDate = currentState.selectedDate || currentState.renderingDate;
    }

    // If blackout weekdays, convert the weekday in to its weekday Index.
    if (props.blackoutWeekDays) {
      const weekDays: IWeekDays = Object.assign({
        sunday: false,
        monday: false,
        tuesday: false,
        wednesday: false,
        thursday: false,
        friday: false,
        saturday: false
      }, props.blackoutWeekDays);

      const blackoutWeekDays: number[] = [];

      Object.keys(weekDays).forEach((item, index) => {
        if (props.blackoutWeekDays[item]) {
          blackoutWeekDays.push(index);
        }
      });

      currentState.blackoutWeekDays = blackoutWeekDays;
    }

    currentState.min = props.min ? DateHelper.getDate(props.min) : null;
    currentState.max = props.max ? DateHelper.getDate(props.max) : null;
    currentState.weekOffset = props.weekOffset || 0;

    if (props.selectedDate instanceof Date) {
      currentState.selectedDate = this.initializeDate(props.selectedDate);
    }
    else if (typeof props.selectedDate !== 'string') {
      // String format comes only at construction time, and has already been handled by the constructor.
      currentState.selectedDate = null;
    }

    if (props.endDate instanceof Date) {
      currentState.endDate = this.initializeDate(props.endDate);
    }
    else if (typeof props.endDate !== 'string') {
      // String format comes only at construction time, and has already been handled by the constructor.
      currentState.endDate = null;
    }

    if (props.renderingDate instanceof Date) {
      currentState.renderingDate = this.initializeDate(props.renderingDate);
    }
    else {
      currentState.renderingDate = DateHelper.getFirstDayOfTheMonth(props.renderingDate || currentState.selectedDate || this.initializeDate(props.defaultCalendarDate) || DateHelper.today);
    }

    // If custom dates are provided, then parse it to Date object
    if (props.dates) {
      currentState.dates = props.dates.map((item) => {
        item.date = DateHelper.getDate(item.date);
        return item;
      });
    }

    return currentState;
  };

  handleForward = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleCalendarPage(1);
  };

  handleBackwards = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.handleCalendarPage(-1);
  };

  showYearLayout = (event) => {
    event.preventDefault();
    event.stopPropagation();

    this.setState({
      renderMode: CalendarRenderingModes.year,
      currentYearPageNumber: 0
    });
  };

  showMonthLayout = (event) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ renderMode: CalendarRenderingModes.month });
  };

  onMonthSelect = (month) => {
    const date = new Date(Date.UTC(this.state.renderingDate.getUTCFullYear(), month, this.state.renderingDate.getUTCDate()));

    this.setState({
      renderingDate: date,
      renderMode: CalendarRenderingModes.date
    });

    if (this.props.onVisibleMonthChange) {
      this.props.onVisibleMonthChange(date);
    }
  };

  onYearSelect = (year) => {
    const date = new Date(Date.UTC(year, this.state.renderingDate.getUTCMonth(), this.state.renderingDate.getUTCDate()));

    this.setState({
      renderingDate: date,
      renderMode: CalendarRenderingModes.date
    });

    if (this.props.onVisibleMonthChange) {
      this.props.onVisibleMonthChange(date);
    }
  };

  onChange = (selectedDate: Date) => {
    this.setState({ selectedDate });

    if (this.props.onChange) {
      this.props.onChange(selectedDate);
    }
  };

  onKeyDown = (e) => {
    const key = e.key;

    if (e.defaultPrevented) {
      return;
    }

    if (key === 'ArrowDown' || key === 'ArrowRight') {
      e.preventDefault();

      if (this.props.calenderType === 'Range') {
        if (document.activeElement.parentElement === getLastEnabledCell()) {
          // To move to the next calendar's first active element
          getNextRangeActiveElement(document.activeElement.parentElement.parentElement)?.querySelector('button').focus();
        }
        else if (document.activeElement.parentElement === document.activeElement.parentElement.parentElement.lastElementChild) {
          // To move to the next row within the same calendar when the focus is on the lastElementChild of the current row
          document.activeElement.parentElement.parentElement.nextElementSibling?.querySelector('button').focus();
        }
        else {
          // To move to the next cell within the same calendar
          getNextRangeActiveElement(document.activeElement.parentElement)?.querySelector('button').focus();
        }
      }
      else {
        if (document.activeElement.parentElement === document.activeElement.parentElement.parentElement.lastElementChild) {
          // To move to the next row when the focus is on the lastElementChild of the current row
          getNextActiveElement(document.activeElement.parentElement.parentElement)?.querySelector('button').focus();
        }
        else {
          // To move to the next cell
          getNextActiveElement(document.activeElement.parentElement)?.querySelector('button').focus();
        }
      }
    }
    else if (key === 'ArrowUp' || key === 'ArrowLeft') {
      e.preventDefault();

      if (this.props.calenderType === 'Range') {
        if (document.activeElement.parentElement === getFirstEnabledCell()) {
          // To move to the previous calendar's last active element
          getPreviousRangeActiveElement(document.activeElement.parentElement.parentElement)?.querySelector('button').focus();
        }
        else if (document.activeElement.parentElement === document.activeElement.parentElement.parentElement.firstElementChild) {
          // To move to the previous row within the same calendar when the focus is on the firstElementChild of the current row
          document.activeElement.parentElement.parentElement.previousElementSibling.lastElementChild?.querySelector('button').focus();
        }
        else {
          // To move to the previous cell within the same calendar
          getPreviousRangeActiveElement(document.activeElement.parentElement)?.querySelector('button').focus();
        }
      }
      else {
        if (document.activeElement.parentElement === document.activeElement.parentElement.parentElement.firstElementChild) {
          // To move to the previous row when the focus is on the firstElementChild of the current row
          document.activeElement.parentElement.parentElement.previousElementSibling.lastElementChild?.querySelector('button').focus();
        }
        else {
          // To move to the previous cell
          getPreviousActiveElement(document.activeElement.parentElement)?.querySelector('button').focus();
        }
      }
    }
  };

  render() {
    return (
      <div role="application" tabIndex={-1} className={`calendar ${this.props.className || ''}`}>

        <CalendarHeaderRenderer
          calendarState={this.state}
          onBackClick={this.handleBackwards}
          onNextClick={this.handleForward}
          onMonthSelect={this.showMonthLayout}
          onYearSelect={this.showYearLayout}
          monthFormat={this.props.monthFormat}
          calenderType={this.props.calenderType}
        />

        {this.state.renderMode === CalendarRenderingModes.date &&
          <div aria-labelledby="calendarHeader">
            <CalendarWeekRenderer weekOffSet={this.state.weekOffset} />
            <CalendarDateRenderer
              renderingDate={this.state.renderingDate}
              selectedDate={this.state.selectedDate}
              weekOffset={this.props.weekOffset}
              onChange={this.onChange}
              onKeyDown={this.onKeyDown}
              dates={this.state.dates}
              max={this.state.max}
              min={this.state.min}
              blackoutWeekDays={this.state.blackoutWeekDays}
              dateRenderer={this.dateRenderer ? this.dateRenderer : CalendarDateItemRenderer}
              endDate={this.state.endDate} />
          </div>
        }

        {this.state.renderMode === CalendarRenderingModes.month &&
          <CalendarMonthRenderer
            renderingDate={this.state.renderingDate}
            selectedDate={this.state.selectedDate}
            onChange={this.onMonthSelect}
            max={this.state.max}
            min={this.state.min}
          />
        }

        {this.state.renderMode === CalendarRenderingModes.year &&
          <CalendarYearRenderer
            renderingDate={this.state.renderingDate}
            selectedDate={this.state.selectedDate}
            onChange={this.onYearSelect}
            max={this.state.max}
            min={this.state.min}
            currentPage={this.state.currentYearPageNumber}
          />
        }
      </div>
    );
  }
}
