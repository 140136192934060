import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { AlertNumberIndicator } from './AlertNumberIndicator';
import { Sidebar, SidebarItem } from '@synerg/vdl-react-components';
import { ComponentManager } from '@adp-wfn/mdf-core';
import { MDFButton } from './MDFButton';
import { MDFCustomSidebarItem } from './MDFCustomSidebarItem';
import { isEqual } from 'lodash';

export interface ISidebarItemData {
  id?: string;
  key?: string;
  ariaLabel?: string;
  label?: string;
  icon?: string;
  view?: string;
  active?: boolean;
  customItem?: string;
}

export interface ISideBarState {
  selectedItem?: number;
  menuOpen?: boolean;
  canNavigate?: boolean;
}

export interface ISideBarProps {
  onChange?: (index: number) => void;
  data: ISidebarItemData[];
  className?: any;
  alertNum?: number;
  title?: any;
  active?: boolean;
  canNavigate?: boolean;
  selectedItem: number;
}

export class MDFSidebar extends React.Component<ISideBarProps, ISideBarState> {
  static propTypes = {
    data: PropTypes.any,
    className: PropTypes.any
  };

  constructor(props) {
    super(props);

    this.state = {
      selectedItem: this.getSelectedItem(),
      menuOpen: false,
      canNavigate: !(this.props.canNavigate === false)
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    const nextState: any = {};

    if (!isEqual(prevState.canNavigate, !(nextProps.canNavigate === false))) {
      nextState.canNavigate = nextProps.canNavigate;

      if (nextState.canNavigate && (!isEqual(prevState.selectedItem, nextProps.selectedItem))) {
        nextState.selectedItem = nextProps.selectedItem;
        nextState.menuOpen = false;
      }
    }

    return nextState;
  }

  getSelectedItem = () => {
    const data = this.props.data;
    let selectedIndex = 0;

    data.forEach((dataItem, i) => {
      if (dataItem.active) {
        selectedIndex = i;
      }
    });

    return selectedIndex;
  };

  handleMenuToggle = () => {
    this.setState({
      menuOpen: !this.state.menuOpen
    });
  };

  handleSelect = (selectedItem: number) => {
    if (this.state.canNavigate) {
      this.setState({
        menuOpen: false,
        selectedItem
      });
    }

    if (this.props.onChange) {
      this.props.onChange(selectedItem);
    }
  };

  private static createIcon(iconClass: string) {
    return iconClass && <div className={iconClass}/>;
  }

  render() {
    const data = this.props.data;
    const { className, alertNum } = this.props;
    // Get navigation views by the selected Itemindex
    const viewComponent = ComponentManager.getComponent(data[this.state.selectedItem].view);
    const rootClasses = {
      'left-nav-framework': true,
      'left-nav-framework--menu-open': this.state.menuOpen
    };

    return (
      <div className={classNames(className, rootClasses)}>
        <div className="left-nav-framework__container vdl-row">
          <div className="vdl-framework-template__sidebar">
            <Sidebar>
              {
                data.map((dataItem, i) => (
                  dataItem['customItem'] ?
                    <MDFCustomSidebarItem
                      key={dataItem.key}
                      id={dataItem.id}
                      label={dataItem.label}
                      icon={MDFSidebar.createIcon(dataItem.icon)}
                      active={i === this.state.selectedItem}
                      className={dataItem.key}
                      onSelect={() => this.handleSelect(i)}
                      customItem={dataItem.customItem}
                    />
                    :
                    <SidebarItem
                      key={dataItem.key}
                      id={dataItem.id}
                      ariaLabel={dataItem.ariaLabel}
                      label={dataItem.label}
                      icon={MDFSidebar.createIcon(dataItem.icon)}
                      active={i === this.state.selectedItem}
                      className={dataItem.key}
                      onSelect={() => this.handleSelect(i)}
                    />
                ))
              }
            </Sidebar>
          </div>
          <div className="left-nav-framework__page-container">
            <div className="left-nav-framework__top-bar">
              <h1 className="left-nav-framework__title">
                <div className="hamburger-icon">
                  <MDFButton
                    buttonStyle="link"
                    onClick={this.handleMenuToggle}
                    iconClass={'fa fa-bars fa-2x'}
                  >
                  </MDFButton>
                  {
                    alertNum ? <AlertNumberIndicator alertNumber={alertNum}/> : undefined
                  }
                </div>
                {this.props.title}
              </h1>
            </div>
            <div className="left-nav-framework__page">
              <div className="left-nav-framework__content">
                {React.createElement(viewComponent)}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
