import React from 'react';
import { generateId } from '@adp-wfn/mdf-core';
import { SdfActionMenu, SdfMenu } from '@waypoint/react-components';
import { iconMapper } from '../util/iconMapper';

const validPositions = ['auto', 'auto-end', 'auto-start', 'bottom', 'bottom-end', 'bottom-start', 'left', 'left-end', 'left-start', 'right', 'right-end', 'right-start', 'top', 'top-end', 'top-start'];

export const ActionMenu2 = ({
  buttonClassName,
  children,
  iconClass,
  id,
  menuLabel,
  position,
  size,
  onSdfChange,
  ...props
}) => {
  const actionId = id ?? generateId('mdf-ActionMenu2-Container');

  const menuProps: any = {
    id: actionId
  };

  if (buttonClassName) {
    menuProps.className = buttonClassName;
  }

  if (iconClass) {
    menuProps.icon = iconMapper(iconClass);
  }

  if (menuLabel) {
    menuProps.label = menuLabel;
  }
  else {
    menuProps['icon-only'] = true;

    if (props['aria-label']) {
      menuProps.label = props['aria-label'];
    }
    else if (props.ariaLabel) {
      menuProps.label = props.ariaLabel;
    }
  }

  if (position) {
    if (validPositions.includes(position)) {
      menuProps.attachment = position;
    }
  }

  if (size) {
    menuProps.size = size === 'lg' || size === 'xl' ? 'md' : 'sm';
  }

  if (onSdfChange) {
    menuProps.onSdfChange = onSdfChange;
  }

  return (
    <SdfActionMenu {...menuProps}>
      <SdfMenu>
        {children}
      </SdfMenu>
    </SdfActionMenu>
  );
};

ActionMenu2.displayName = 'ActionMenu2';
