import React from 'react';
import { SdfAlertInline } from '@waypoint/react-components';
import { AlertEmphasis, AlertSize, AlertStatus } from '@waypoint/web-components/types/components/alert-inline/alert-inline';
import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-name';

export interface WfnAlertInlineProps {
  ariaLive?: 'polite' | 'assertive';
  children?: React.ReactNode;
  emphasis?: AlertEmphasis;
  hidden?: boolean;
  hideIcon?: boolean;
  icon?: IconName;
  iconTitle?: string;
  size?: AlertSize;
  status?: AlertStatus;
}

export const WfnAlertInline = ({
  ariaLive = 'polite',
  ...props
}: WfnAlertInlineProps) => {
  return (
    <div aria-live={ariaLive}>
      <SdfAlertInline {...props} />
    </div>
  );
};
