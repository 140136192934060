import React, { useCallback, useContext, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { MDFHelpIcon } from '../MDFHelpIcon';
import { MDFValidationContainer } from '../MDFValidationContainer';
import { ValidationContainerContext } from '../MDFContext';
// import { SdfFocusPane } from '@waypoint/react-components';
import { WfnFocusPaneClone } from '@adp-wfn/mdf-wc-react';
import { MDFCore } from '@adp-wfn/mdf-core';

export interface IMDFSidePanelProps {
  // Required props
  children: React.ReactNode;
  closeButton: boolean;
  onHide: () => void;
  onSubmit: () => void;
  show: boolean;
  // Optional props
  animation?: boolean;
  autoFocus?: boolean;
  backdrop?: any;
  className?: string;
  closeable?: boolean;
  closeButtonAriaLabel?: string;
  closeButtonLabel?: React.ReactNode;
  containerClassName?: string;
  id?: string;
  dialogClassName?: string;
  footerView?: React.ReactNode;
  headerView?: React.ReactNode;
  helpUrl?: string;
  isBusy?: boolean;
  keyboard?: boolean;
  modalSize?: 'sm' | 'md' | 'lg' | 'xl';
  onCancel?: () => void;
  onChange?: () => void;
  onEntered?: () => void;
  onEntering?: () => void;
  onEscapeKeyUp?: () => void;
  onExiting?: () => void;
  onExited?: () => void;
  onScrollToTop?: () => void;
  onSubmitEnd?: () => void;
  renderFooter?: boolean;
  renderHeader?: boolean;
  portalEnabled?: boolean;
  portalsRoot?: string;
  // Accessibility feature - Moves focus to a specific element after the slide in closes.
  // Enable feature by passing matching string values to the slide in's restoreFocusNodeId property and the next focused element's id property.
  restoreFocusNodeId?: string;
  scrollToTop?: boolean;
  showHelpIcon?: boolean;
  size?: 'auto' | 'sm' | 'md' | 'lg' | 'xl';
  snackBar?: React.ReactNode;
  title?: string;
  useFocusLock?: boolean;
  // Validation props and events
  validation?: {};
  waitToRenderContent?: boolean;
  zIndex?: number;
}

interface ISidePanelWrapperProps {
  // Required props
  children: React.ReactNode;
  onHide: () => void;
  renderHeader: boolean;
  renderFooter: boolean;
  show: boolean;
  // Optional props
  animation?: boolean;
  autoFocus?: boolean;
  backdrop?: any;
  className?: string;
  closeable?: boolean;
  closeButton?: boolean;
  closeButtonAriaLabel?: string;
  closeButtonLabel?: string;
  containerClassName?: string;
  dialogClassName?: string;
  footerView?: React.ReactNode;
  headerView?: React.ReactNode;
  helpUrl?: string;
  id?: string;
  isBusy?: boolean;
  keyboard?: boolean;
  modalSize: 'sm' | 'md' | 'lg' | 'xl';
  onCancel?: () => void;
  onChange?: () => void;
  onEntered?: () => void;
  onEntering?: () => void;
  onEscapeKeyUp?: () => void;
  onExiting?: () => void;
  onExited?: () => void;
  onScrollToTop?: () => void;
  onSubmit: () => void;
  onSubmitEnd?: () => void;
  portalEnabled?: boolean;
  portalsRoot?: string;
  restoreFocusNodeId?: string;
  scrollToTop?: boolean;
  showHelpIcon?: boolean;
  size?: 'auto' | 'sm' | 'md' | 'lg' | 'xl';
  snackBar?: React.ReactNode;
  title?: string;
  toggleSidePanel?: () => void;
  useFocusLock?: boolean;
  // Validation props and events
  validation?: any;
  waitToRenderContent?: boolean;
  zIndex?: number;
}

export const MDFSidePanel = (props: ISidePanelWrapperProps) => {
  return (
    <MDFSidePanelWrapper
      show={props.show}
      onHide={props.onHide}
      autoFocus={props.autoFocus}
      animation={props.animation}
      backdrop={props.backdrop}
      className={props.className}
      closeable={props.closeable}
      closeButton={props.closeButton}
      closeButtonAriaLabel={props.closeButtonAriaLabel}
      closeButtonLabel={props.closeButtonLabel}
      containerClassName={props.containerClassName}
      dialogClassName={props.dialogClassName}
      footerView={props.footerView}
      headerView={props.headerView}
      helpUrl={props.helpUrl}
      id={props.id}
      isBusy={props.isBusy}
      keyboard={props.keyboard}
      modalSize={props.modalSize}
      onCancel={props.onCancel}
      onChange={props.onChange}
      onEntered={props.onEntered}
      onEntering={props.onEntering}
      onEscapeKeyUp={props.onEscapeKeyUp}
      onExiting={props.onExiting}
      onExited={props.onExited}
      onScrollToTop={props.onScrollToTop}
      onSubmit={props.onSubmit}
      onSubmitEnd={props.onSubmitEnd}
      portalEnabled={props.portalEnabled}
      portalsRoot={props.portalsRoot}
      renderFooter={props.renderFooter}
      renderHeader={props.renderHeader}
      restoreFocusNodeId={props.restoreFocusNodeId}
      scrollToTop={props.scrollToTop}
      showHelpIcon={props.showHelpIcon}
      size={props.size}
      snackBar={props.snackBar}
      title={props.title}
      useFocusLock={props.useFocusLock}
      validation={props.validation}
      waitToRenderContent={props.waitToRenderContent}
      zIndex={props.zIndex}
    >
      {props.children}
    </MDFSidePanelWrapper>
  );
};

MDFSidePanel.displayName = 'MDFSidePanel';

function MDFSidePanelWrapper(props: ISidePanelWrapperProps) {
  const [isOpen, setIsOpen] = useState(false);
  const [isShowing, setIsShowing] = useState(props.show);
  const [trigger, setTrigger] = useState(null);
  const showHeader = props.headerView && props.renderHeader;
  const showFooter = (props.footerView || props.snackBar) && (props.renderFooter !== false);
  const focusPane = useRef<HTMLSdfFocusPaneElement>(null);

  const afterOpened = useCallback(() => {
    props.onEntered?.();
  }, []);

  const afterClosed = useCallback(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFSidePanel.afterClosed: Calling setIsShowing(${false})`);
    }

    setIsShowing(false);
    props.onExited?.();

    // Accessibility: Use restoreFocusNodeId property to place focus on a custom element after the slide in closes.
    if (props.restoreFocusNodeId) {
      const id = props.restoreFocusNodeId;
      const customFocusElement = document.getElementById(id) as any;

      if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
        customFocusElement.setFocus();
      }
      else if (customFocusElement) {
        customFocusElement.focus();
      }
    }
  }, []);

  const dismissed = useCallback(() => {
    props.onHide?.();
  }, []);

  const focusPaneProps: any = {
    'back-label': props.closeButtonLabel,
    closeable: props.closeable,
    'dismiss-on-click-outside': props.backdrop,
    heading: props.title,
    'hide-accept-button': true,
    'hide-dismiss-button': true,
    'hide-footer': !showFooter,
    'pane-type': 'anchored',
    size: props.size,
    onSdfAfterOpen: afterOpened,
    onSdfAfterClose: afterClosed,
    onSdfDismiss: dismissed
  };

  if (props.id) {
    focusPaneProps.id = props.id;
  }

  useEffect(() => {
    if (props.scrollToTop) {
      const scrollToContent = focusPane.current?.shadowRoot?.querySelector('#content');

      if (scrollToContent) {
        scrollToContent.scrollTop = 0;
        props.onScrollToTop?.();
      }
    }
  }, [props.scrollToTop]);

  useEffect(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFSidePanel.useEffect(): Entering. isShowing = ${isShowing}, isOpen = ${isOpen}`);
    }

    setIsShowing(props.show);
  }, [props.show]);

  useLayoutEffect(() => {
    if (MDFCore.shouldLog()) {
      console.warn(`MDFSidePanel.useLayoutEffect(): Entering. isShowing = (${isShowing})`);
    }

    // The open/close methods need to be run before repainting the screen.
    if (isShowing) {
      // Focus management: Identify element that opens the slide in.
      setTrigger(document.activeElement.id);
      void focusPane.current?.open();

      if (MDFCore.shouldLog()) {
        console.warn(`MDFSidePanel.useLayoutEffect(): Calling setIsOpen(${true})`);
      }

      setIsOpen(true);
    }
    else {
      if (MDFCore.shouldLog()) {
        console.warn(`MDFSidePanel.useLayoutEffect(): Calling setIsOpen(${false})`);
        console.warn(`MDFSidePanel.useLayoutEffect(): Resetting focus. props.restoreFocusNodeId = ${props.restoreFocusNodeId}, trigger = ${trigger}`);
      }

      void focusPane.current?.close();
      setIsOpen(false);

      // Handles focus management upon slide in closing
      if (props.restoreFocusNodeId || trigger) {
        // Checks if a custom focus element (restoreFocusNodeId property) is specified first. If a
        // focus element is not specified, then focus will be moved back to the original trigger element.
        const focusNodeId = props.restoreFocusNodeId ? props.restoreFocusNodeId : trigger;
        const customFocusElement = document.getElementById(focusNodeId) as any;

        if (customFocusElement?.tagName.toLowerCase().includes('sdf-')) {
          customFocusElement.setFocus();
        }
        else if (customFocusElement) {
          customFocusElement.focus();
        }
      }
    }
  }, [isShowing]);

  function getFooter() {
    if (showFooter && (props.snackBar || props.footerView)) {
      return <div slot="custom-buttons" className="w-full">{props.snackBar || props.footerView}</div>;
    }
    else {
      return null;
    }
  }

  function renderContent() {
    if (props.show && isOpen) {
      return <MDFSidePanelBody>{props.children}</MDFSidePanelBody>;
    }
    else if (!(props.show && isOpen)) {
      return null;
    }
  }

  const bodyContent = () => {
    // This gets tricky. If the app is using a text header, but wants a help icon,
    // we can do that by adding the help icon to the "title" slot and giving it the URL
    // for the help content. If the app wants to provide its own header in an MDF view,
    // we can do that too, by putting the content of that view into the "title" slot.
    // Best practice is to use a text header with showHelpIcon rather than re-creating it
    // using an MDF view.
    return (
      <React.Fragment>
        {props.showHelpIcon && !showHeader && <span slot="title"><MDFHelpIcon helpUrl={props.helpUrl} /></span>}
        {showHeader && <div slot="title" className="w-full">{props.headerView}</div>}
        <div className="px-4">{renderContent()}</div>
        {getFooter()}
      </React.Fragment>
    );
  };

  const renderBody = () => {
    if (props.validation) {
      return (
        <MDFValidationContainer {...props.validation} isSidePanelValidation={true} onChange={props.onChange} onCancel={props.onCancel} onSubmit={props.onSubmit} onSubmitEnd={props.onSubmitEnd}>
          {bodyContent()}
        </MDFValidationContainer>
      );
    }
    else {
      return (
        <React.Fragment>
          {bodyContent()}
        </React.Fragment>
      );
    }
  };

  if (MDFCore.shouldLog()) {
    console.warn(`MDFSidePanel: Rendering. isShowing = ${isShowing}. props =`, props);
  }

  if (props.show || isShowing) {
    if (props.portalEnabled) {
      return createPortal(<WfnFocusPaneClone {...focusPaneProps} ref={focusPane}>{renderBody()}</WfnFocusPaneClone>, document.body);
    }
    else {
      return <WfnFocusPaneClone {...focusPaneProps} ref={focusPane}>{renderBody()}</WfnFocusPaneClone>;
    }
  }
  else {
    return null;
  }
}

// allow better control over the parts of the component when using MDFSidePanel instead of SidePanel (CustomSidePanel),like MDFVersoView feature
MDFSidePanelWrapper.defaultProps = {
  renderFooter: null,
  renderHeader: true,
  closeButton: true,
  size: 'lg'
};

MDFSidePanelWrapper.displayName = 'MDFSidePanelWrapper';

export interface IMDFSidePanelBodyProps {
  children?: React.ReactNode;
  className?: string;
  validation?: any;
}

function MDFSidePanelBody(props: IMDFSidePanelBodyProps) {
  const sidePanelContentRef = useRef(null);
  const validationContainerContext: any = useContext(ValidationContainerContext);
  const isValidationContainerValid = validationContainerContext?.formIsValid?.()?.isValid;
  const validationMsg = props.validation?.defaultErrorMessage;

  return (
    <React.Fragment>
      <div tabIndex={-1} ref={sidePanelContentRef} className={`${props.className}`}>
        {validationMsg && (isValidationContainerValid && validationContainerContext?.buttonClicked) && <div className="mdf-side-panel-label-error">{validationMsg}</div>}
        {props.children}
      </div>
    </React.Fragment>
  );
}
