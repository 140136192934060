import React from 'react';
import { SdfAlertToast } from '@waypoint/react-components';
import { ToastStatus } from '@waypoint/web-components/types/components/alert-toast/alert-toast';

export interface WfnAlertToastProps {
  actionHref?: string;
  actionLabel?: string;
  ariaLive?: 'polite' | 'assertive';
  autoClose?: boolean;
  autoCloseAfter?: number;
  buttonTitle?: string;
  closeable?: boolean;
  closeIconTitle?: string;
  hidden?: boolean;
  hideIcon?: boolean;
  message?: string;
  status?: ToastStatus;
  useAnimation?: boolean;
  onSdfAction?: (event: CustomEvent) => void;
  onSdfAfterClose?: (event: CustomEvent) => void;
  onSdfAfterOpen?: (event: CustomEvent) => void;
  onSdfDismiss?: (event: CustomEvent) => void;
}

export const WfnAlertToast = ({
  ariaLive = 'polite',
  ...props
}: WfnAlertToastProps) => {
  return (
    <div aria-live={ariaLive}>
      <SdfAlertToast {...props} />
    </div>
  );
};
