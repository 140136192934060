import React from 'react';
import { SdfMenuItem } from '@waypoint/react-components';
import { MDFIcon } from './MDFIcon';

export interface IActionLink2Props {
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  focused?: boolean;
  iconClass?: string; // From ActionMenuOption
  text?: string; // From MDFActionMenuOption
  onClick?: (event) => void;
}

export const ActionLink2 = ({
  children,
  className,
  disabled,
  focused,
  iconClass,
  text,
  onClick,
  ...additionalProps
}: IActionLink2Props) => {
  const itemProps: any = {
    disabled: disabled ? disabled : undefined
  };

  if (className) {
    console.error('ActionLink2(): Do not attempt to override the menu item styles. This property is now ignored.');
  }

  if (focused) {
    console.error('ActionLink2(): The component no longer needs/uses the "focused" property.');
  }

  if (onClick) {
    console.error('ActionLink2(): Do not put click handlers on action menu items. Set the "data" property to identify the item and then listen to the sdfChange event on the parent menu instead.');
    itemProps.onClick = onClick;
  }

  if (additionalProps || Object.keys(additionalProps).length > 0) {
    console.error('ActionLink2(): The component does not support these properties:', Object.keys(additionalProps));
  }

  return (
    <SdfMenuItem {...itemProps}>
      {iconClass && <span slot="before"><MDFIcon className={iconClass} /></span>}
      {text ?? children}
    </SdfMenuItem>
  );
};

ActionLink2.displayName = 'ActionLink2';
