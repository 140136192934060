import React from 'react';
import { isEqual } from 'lodash';
import { VDLColors } from '../util/colorStyles';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export interface IDataRingProps {
  // Data ring badge content
  badgeContent?: IDataBadgeContent;

  // Data ring color
  color: VDLColors;

  // A unique identifier to be returned at the parameter of the onClick function
  id: any;

  // If the data ring's badge background color is filled
  dataBadgeFilled?: boolean;

  // If the data ring's background color is filled
  dataRingFilled?: boolean;

  // If the badge should stretch to fit its contents (up to +/- 14 characters) or truncate contents to fit inside badge
  stretchBadge?: boolean;

  // The status ring size - defaults to 'md'
  size?: 'sm' | 'smd' | 'md' | 'lg' | 'xl';

  // The Data Ring status color. Overrides the "color" input property
  status?: 'success' | 'error' | 'warning' | 'info';

  // Called when dataRing is clicked
  onClick: (id: any) => void;

  // Accessibility feature
  role?: string;

  // Accessibility feature
  ariaLabel?: string;
  'aria-label'?: string;
}

export interface IDataBadgeContent {
  // Data ring badge content type
  type?: 'icon' | 'text' | 'status' | 'none';

  // Data ring badge content. If type is "icon", the value must be an icon name i.e. 'fa-check'
  value?: string;
}

export class MDFDataRing extends React.Component<React.PropsWithChildren<IDataRingProps>, any> {
  constructor(props: any) {
    super(props);

    this.state = {
      badgeContent: this.props.badgeContent,
      color: this.props.color
    };
  }

  static getDerivedStateFromProps(nextProps, prevState) {
    let shouldUpdateState = false;
    const nextState: any = {};
    let color;
    let badgeContent;

    if (!isEqual(nextProps.color, prevState.color)) {
      nextState.color = nextProps.color;
      shouldUpdateState = true;
    }

    if (!isEqual(nextProps.badgeContent, prevState.badgeContent)) {
      nextState.badgeContent = nextProps.badgeContent;
      shouldUpdateState = true;
    }

    if (nextProps.status) {
      switch (nextProps.status) {
        case 'warning':
          color = 'accent-5';
          badgeContent = {
            type: 'icon',
            value: 'fa-exclamation'
          };

          shouldUpdateState = true;
          nextState.badgeContent = badgeContent;
          nextState.color = color;

          break;

        case 'success':
          color = 'accent-1';
          badgeContent = {
            type: 'icon',
            value: 'fa-check'
          };

          shouldUpdateState = true;
          nextState.badgeContent = badgeContent;
          nextState.color = color;

          break;

        case 'error':
          color = 'accent-6';
          badgeContent = {
            type: 'icon',
            value: 'fa-times'
          };

          shouldUpdateState = true;
          nextState.badgeContent = badgeContent;
          nextState.color = color;

          break;

        case 'info':
          color = 'accent-0-dark';
          badgeContent = {
            type: 'icon',
            value: 'fa-info'
          };

          shouldUpdateState = true;
          nextState.badgeContent = badgeContent;
          nextState.color = color;

          break;
      }
    }

    return shouldUpdateState ? nextState : null;
  }

  handleDataRingClick = (e) => {
    if (this.props.onClick) {
      this.props.onClick(this.props.id || null);
    }
    else {
      e.preventDefault();
    }
  };

  handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter' || e.key === ' ') {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
      e.preventDefault();

      if (this.props.onClick) {
        this.props.onClick(this.props.id || null);
      }
    }
  };

  render() {
    const dataRingContainerClasses = 'vdl-status-ring--' + (this.props.size || 'md') + '-' + (this.state.color || this.props.color);
    const statusRingContainerClasses = 'status-ring' + (this.props.dataRingFilled ? ' vdl-status-ring-bg-color' : '');
    const statusRingBackgroundClasses = 'bg-container';
    const badgeContainerClasses = 'vdl-badge-circle-foreground fa fa-circle' + (this.props.dataBadgeFilled ? ' vdl-badge-foreground-filled' : '');
    const badgeIconClasses = 'vdl-badge-circle-background fa fa-circle';
    const badgeBackgroundClasses = 'fa ' + (this.state.badgeContent && this.state.badgeContent.value) + ' vdl-badge-icon' + (this.props.dataBadgeFilled ? ' vdl-badge-font-color' : '');
    const badgeTextClasses = 'status-badge' + (this.props.stretchBadge ? '-flexible' : '') + (this.props.dataBadgeFilled ? ' vdl-status-badge-bg-color' : '');
    let role;

    // Assign role
    if (this.props.onClick) {
      role = 'button';
    }
    else {
      role = this.props.role ? this.props.role : 'img';
    }

    const ariaLabel = resolveAriaProperty('MDFDataRing', 'aria-label', 'ariaLabel', this.props);

    return (
      <div className={dataRingContainerClasses} aria-label={(ariaLabel ? ariaLabel : '') + (this.props.status ? (' Status: ' + this.props.status) : '')} onMouseDown={this.handleDataRingClick} onKeyDown={this.handleKeyDown} tabIndex={this.props.onClick ? 0 : -1} role={role}>
        <div className="status-ring-container">
          <div className={statusRingContainerClasses}>
            <div className={statusRingBackgroundClasses}>
              {this.props.children}
            </div>
          </div>
        </div>
        {
          ((this.props.badgeContent && this.props.badgeContent.type === 'icon') || this.props.status) &&
          <div className="vdl-badge-circle-stack">
            <i className={badgeIconClasses}></i>
            <i className={badgeContainerClasses}></i>
            <i className={badgeBackgroundClasses}></i>
          </div>
        }
        {
          (this.props.badgeContent && this.props.badgeContent.type === 'text') &&
          <div className={badgeTextClasses}>
            <div className="status-badge-content">
              <span className="truncate-text">{this.props.badgeContent.value}</span>
            </div>
          </div>
        }
      </div>
    );
  }
}
