import React from 'react';
import { cloneDeep, isEqual } from 'lodash';
import { FormatHelper } from '@adp-wfn/mdf-core';

import Highcharts from 'highcharts';
import 'highcharts/modules/heatmap';
import 'highcharts/modules/exporting';
import 'highcharts/modules/stock';
import 'highcharts/modules/export-data';
import 'highcharts/modules/accessibility';

export interface IChartProps {
  // An id for the chart.
  id: string;
  // The Highcharts configuration object. Please see the Highcharts documentation.
  config: Highcharts.Options;
}

export class MDFChart extends React.Component<IChartProps, any> {
  // the instance of the Highchart object
  chart = null;
  // a local clone of the chart configuration used to detect changes.
  chartConfig: Highcharts.Options = null;

  constructor(props: any) {
    super(props);
    this.chart = undefined;
  }

  componentDidUpdate() {
    // compare existing chartConfig and new Config if modified update the chart
    const newConfig = cloneDeep(this.props.config);
    this.updateChartConfig(newConfig);

    // this.chartConfig contains the previous updated configuration from componentDidMount/componentDidUpdate.
    const areEqual = isEqual(this.chartConfig, newConfig);

    if (!areEqual) {
      this.chartConfig = newConfig;
      this.chart.update(newConfig);
    }
  }

  componentDidMount() {
    const { id, config } = this.props;

    this.updateChartConfig(config);

    // clone the config object and store in temporary variable
    this.chartConfig = cloneDeep(config);
    this.renderChart(id, config);
  }

  updateChartConfig = (config) => {
    // override the default behaviour of the chart
    // update the chart font family
    if (!config.hasOwnProperty('chart')) {
      config['chart'] = {};
    }

    if (!config.chart.hasOwnProperty('style')) {
      config.chart['style'] = {};
    }

    config.chart.style['fontFamily'] = 'var(--font-family-base)';

    // update the chart credits to hide the highcharts.com water mark at the bottom of the chart
    if (!config.hasOwnProperty('credits')) {
      config['credits'] = {};
    }

    config.credits['enabled'] = false;
  };

  renderChart = (id, config) => {
    // set charts default text
    Highcharts.setOptions({
      lang: {
        viewFullscreen: FormatHelper.formatMessage('@@viewFullscreen'),
        printChart: FormatHelper.formatMessage('@@printChart'),
        downloadPNG: FormatHelper.formatMessage('@@downloadPNG'),
        downloadJPEG: FormatHelper.formatMessage('@@downloadJPEG'),
        downloadPDF: FormatHelper.formatMessage('@@downloadPDF'),
        downloadSVG: FormatHelper.formatMessage('@@downloadSVG'),
        downloadCSV: FormatHelper.formatMessage('@@DOWNLOADCSV'),
        downloadXLS: FormatHelper.formatMessage('@@downloadXLS'),
        viewData: FormatHelper.formatMessage('@@viewData')
      }
    });
    this.chart = Highcharts.chart(id, config);
  };

  render() {
    const { id } = this.props;

    return (
      <div id={id}/>
    );
  }
}
