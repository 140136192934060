import React from 'react';
import PropTypes from 'prop-types';

import { FormatHelper, generateId, LocaleHelper } from '@adp-wfn/mdf-core';

import { IAutoCompleteAddress } from './AutoCompleteAddress';
import { MDFIcon } from './MDFIcon';
import { MDFLabeledAutoCompleteAddress, MDFLabeledCountry, MDFLabeledDropdownList, MDFLabeledTextbox } from './MDFLabeledComponents';
import { GenericValidator } from '../validators/GenericValidator';
import { ValidationContainerContext } from './MDFContext';
import { getStateList } from '../util/getState';
import { cloneDeep } from 'lodash';
import { SdfAlert } from '@waypoint/react-components';
import equals from 'ramda/es/equals';

import meta from './data/static.json';
import countryList from './data/countries.json';

export interface IAddressProps {
  id?: string;
  adaptive?: boolean;
  numberOfColumns?: number;
  isAutoPay?: boolean;
  isRequired?: boolean;
  onAddressChange?: (newState: object, providedByGoogle?: boolean) => void;
  readOnly?: boolean;
  isCompactStatic?: boolean;
  className?: string;
  useLengthLimits?: boolean;
  disabled: boolean;
  validateOnRender: boolean;
  isCityRequired?: boolean;
  isStateRequired?: boolean;
  isPostalCodeRequired?: boolean;
  nosExpandedFields?: boolean;
  address1RegExp?: string;
  address2RegExp?: string;
  cityRegExp?: string;
  address1RegExpInvalidMessage?: string;
  address2RegExpInvalidMessage?: string;
  cityRegExpInvalidMessage?: string;
  autoFocus?: boolean;
}

export interface IAddress extends IAutoCompleteAddress {
  address3?: string;
  county?: string;
}

export const MAX_LENGTH = {
  ADDRESS_LINE_2: 30,
  CITY: 17,
  CITY_30: 30
};

export const REG_EXP = {
  // À-ӿ this includes characters from other languages and Cyrillic.
  // Ạ-ỹ this includes characters from Vietnamese.
  // The complete list can be found here 'https://en.wikipedia.org/wiki/List_of_Unicode_characters'.
  ADDRESS_LINE_1: /^[-—\wÀ-ӿẠ-ỹ ,'.\/#]*$/,
  ADDRESS_LINE_2: /^[-—\wÀ-ӿẠ-ỹ#$/ ,'.]*$/,
  CITY: /^[-—\wÀ-ӿẠ-ỹ ,'.]*$/,
  COUNTRY: ''
};

export const LABEL_KEYS = {
  CITY: 'city',
  STATE: 'state',
  ADDRESS_LINE_1: 'addressLine1',
  ADDRESS_LINE_2: 'addressLine2',
  ADDRESS_LINE_3: 'addressLine3',
  COUNTRY: 'country',
  COUNTY: 'county',
  POSTAL_CODE: 'postalCode'
};

export class AddressConstants {
  static readonly MAX_LENGTH = { ...MAX_LENGTH };
  static readonly REG_EXP = { ...REG_EXP };
  static readonly LABEL_KEYS = { ...LABEL_KEYS };
}

interface IAddressConfig {
  postalCodeFormat?: string;
  showCounty?: boolean;
  isStateCombo?: boolean;
  showAddress3?: boolean;
  city?: string;
  showCity?: boolean;
  state?: string;
  showState?: boolean;
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  county?: string;
  postalCode?: string;
  postalCodeHelpMessage?: string;
  showPostalCode?: boolean;
  invalidPostalCodes?: string[];
  isAddressRequired?: boolean;
  isPostalCodeRequired?: boolean;
  isCityRequired?: boolean;
  className?: string;
  useLengthLimits?: boolean;
  isStateRequired?: boolean;
}

class AddressConfig implements IAddressConfig {
}

export class Address extends React.Component<any, any> {
  private meta: any;
  private countryList: any;

  declare context: React.ContextType<typeof ValidationContainerContext>;
  static contextType = ValidationContainerContext;

  // Variable to store previousAddressDetails
  private previousAddressDetails;

  // variable to set if selectedAddress is tied to google Address
  private isGoogleSuggestedAddress;
  // variable to set if selectedAddress is tied to google Address
  private isSelectedAddress;

  constructor(props) {
    super(props);

    this.meta = meta;
    this.countryList = countryList;

    let address: IAddress = {};

    if (props.addressDetails) {
      address = cloneDeep(props.addressDetails);
    }
    else if (props.canonicalAddress) {
      address = {
        address1: props.canonicalAddress.lineOne,
        address2: props.canonicalAddress.lineTwo,
        address3: props.canonicalAddress.lineThree,
        city: props.canonicalAddress.cityName,
        provinceCode: props.canonicalAddress.countrySubdivisionLevel1.codeValue,
        provinceName: props.canonicalAddress.countrySubdivisionLevel1.longName,
        postalCode: props.canonicalAddress.postalCode,
        countryCode: props.canonicalAddress.countryCode
      };
    }
    else {
      address = { countryCode: 'us' };
    }

    const showDetails = this.getShowAddressDetails(address);

    this.state = {
      value: {
        addressDetails: address,
        addressConfig: this.updateConfig({ id: address.countryCode }, props.readOnly ? false : !!props.isRequired),
        stateList: getStateList(address.countryCode),
        showAlert: false,
        ...showDetails
      },
      // if readOnly property is used, the required field will always be false
      isRequired: props.readOnly ? false : !!props.isRequired,
      validationMessages: {},
      isValid: true
    };

    this.validateAddressComponent(this.state, true);
  }

  onDismissAlert = () => {
    this.setState({ showAlert: false });
  };

  // When state, county, zip code, addressline2, addressline3 of google suggested address is changed show this alert.
  // Hide the alert when the fields are undone or the google suggested address is changed and country is changed.
  renderAlert = () => {
    return (
      <SdfAlert {...!this.state.showAlert && { hidden: true }} closeable status={'warning'} size={'sm'} onSdfDismiss={this.onDismissAlert}>
        <span slot={'title'}>{FormatHelper.formatMessage('@@AlertTitle')}</span>
        {FormatHelper.formatMessage('@@GoogleAlertMessage')}
      </SdfAlert >
    );
  };

  getShowAddressDetails = (address: IAddress) => (
    {
      showExpandAddress: !GenericValidator.isNotNullOrEmpty(address.address2) && !this.props.isAutoPay,
      showAddressField2: GenericValidator.isNotNullOrEmpty(address.address2) && !this.props.isAutoPay,
      showAddAddress3: !GenericValidator.isNotNullOrEmpty(address.address3) && !this.props.isAutoPay,
      showAddressField3: GenericValidator.isNotNullOrEmpty(address.address3) && !this.props.isAutoPay,
      showCloseAddress2: GenericValidator.isNotNullOrEmpty(address.address3) && !this.props.isAutoPay
    }
  );

  validateAddressComponent = (state: any, checkAll = false) => {
    state.isValid = this.validateAddress(LABEL_KEYS.ADDRESS_LINE_1, checkAll, state, !this.props.validateOnRender, !checkAll);
  };

  static propTypes = {
    numberOfColumns: PropTypes.oneOf([1, 2, 3])
  };

  componentDidMount() {
    if (this.context.registerField) {
      if (!this.props.name) {
        console.error('Validated fields must have a name attribute!');
      }

      // Attaching the component to the form
      this.context.registerField(this);
    }

    if (this.props.validateOnRender && (this.state.isValid === false)) {
      this.updateState(this.state);
    }
  }

  private getCountryName(countryCode: string) {
    let countryName = '';
    const locale: any = LocaleHelper.getUserLocale().split('-')[0];

    if (countryCode) {
      const countryOption = this.countryList.options.find((country) => country.id === countryCode.toUpperCase());

      if (countryOption) {
        countryName = countryOption.labels.find((lable) => lable.locale === locale).value;

        if (countryName && countryOption.code) {
          countryName = countryName.replace(countryOption.code + ' - ', '');
        }
      }
    }

    return countryName;
  }

  componentWillReceiveProps(nextProps: any) {
    const addressDetails = this.state.value.addressDetails;
    const newState = { ...this.state };
    newState.isRequired = nextProps.readOnly ? false : !!nextProps.isRequired;

    if (nextProps && nextProps.addressDetails && ((nextProps.addressDetails.address1 !== addressDetails.address1) || (nextProps.addressDetails.address2 !== addressDetails.address2) || (nextProps.addressDetails.address3 !== addressDetails.address3) || (nextProps.addressDetails.city !== addressDetails.city) || (nextProps.addressDetails.provinceCode !== addressDetails.provinceCode) || (nextProps.addressDetails.countryCode !== addressDetails.countryCode) || (nextProps.addressDetails.county !== addressDetails.county) || (nextProps.addressDetails.postalCode !== addressDetails.postalCode))) {
      const newValue = newState.value = Object.assign({}, this.state.value, {
        addressConfig: { ...this.state.value.addressConfig },
        addressDetails: { ...this.state.value.addressDetails },
        stateList: this.state.value.stateList
      });

      newValue.addressDetails = nextProps.addressDetails;

      if (nextProps.addressDetails.countryCode !== this.state.value.addressDetails.countryCode) {
        newValue.addressConfig = this.updateConfig({ id: nextProps.addressDetails.countryCode }, newState.isRequired);
        newValue.stateList = getStateList(nextProps.addressDetails.countryCode);
      }

      this.validateAddressComponent(newState, true);
      this.updateState(newState);
    }
    else if (nextProps && nextProps.canonicalAddress) {
      const newValue = newState.value = Object.assign({}, this.state.value, {
        addressConfig: { ...this.state.value.addressConfig },
        addressDetails: { ...this.state.value.addressDetails },
        stateList: this.state.value.stateList
      });

      newValue.addressDetails = Object.assign({}, newValue.addressDetails, {
        address1: nextProps.canonicalAddress.lineOne,
        address2: nextProps.canonicalAddress.lineTwo,
        address3: nextProps.canonicalAddress.lineThree,
        city: nextProps.canonicalAddress.cityName,
        provinceCode: nextProps.canonicalAddress.countrySubdivisionLevel1.codeValue,
        provinceName: nextProps.canonicalAddress.countrySubdivisionLevel1.longName,
        postalCode: nextProps.canonicalAddress.postalCode,
        countryCode: nextProps.canonicalAddress.countryCode
      });

      if (nextProps.canonicalAddress.countryCode !== this.state.value.addressDetails.countryCode) {
        newValue.addressConfig = this.updateConfig({ id: nextProps.addressDetails.countryCode }, newState.isRequired);
        newValue.stateList = getStateList(nextProps.addressDetails.countryCode);
      }

      this.validateAddressComponent(newState, true);
      this.updateState(newState);
    }
    else if (nextProps && this.state.isRequired !== (nextProps.readOnly ? false : !!nextProps.isRequired)) {
      const newValue = newState.value = Object.assign({}, this.state.value, {
        addressConfig: { ...this.state.value.addressConfig },
        addressDetails: { ...this.state.value.addressDetails },
        stateList: this.state.value.stateList
      });
      newValue.addressConfig = this.updateConfig({ id: newState.value.addressDetails.countryCode }, newState.isRequired);

      this.validateAddressComponent(newState, true);
      this.updateState(newState);
    }
  }

  componentWillUnmount() {
    if (this.context.unregisterField) {
      // Detaching when unmounted
      this.context.unregisterField(this);
    }
  }

  componentDidUpdate() {
    if (this.context.updateFieldMeta) {
      this.context.updateFieldMeta(this);
    }
  }

  updateConfig = (countryValue: any, isRequired: boolean) => {
    // setting regExp
    const addressConfigObj: IAddressConfig = new AddressConfig();
    const countryJson = (countryValue && countryValue.id && this.meta.countries[countryValue.id.toUpperCase()]) ? this.meta.countries[countryValue.id.toUpperCase()] : this.meta.countries['DEFAULT'];

    if (countryJson && countryJson.fields) {
      for (const field of countryJson.fields) {
        if (field) {
          switch (field.id) {
            case 'postalCode': {
              let postalCodeFormat = field.format;
              let postalCodeHelpMessage = FormatHelper.formatMessage(field.help) + ': ' + field.postalCodeExample;

              if (postalCodeFormat) {
                postalCodeFormat = postalCodeFormat.split('(').join('^(');
                postalCodeFormat = postalCodeFormat.split(')').join(')$');
                addressConfigObj.postalCodeFormat = postalCodeFormat;
              }

              if (field.help && field.postalCodeExample) {
                postalCodeHelpMessage = postalCodeHelpMessage.split('|').join(FormatHelper.formatMessage('@@or'));
              }
              else {
                postalCodeHelpMessage = null;
              }

              addressConfigObj.postalCodeHelpMessage = postalCodeHelpMessage;
              addressConfigObj.showPostalCode = true;
              addressConfigObj.invalidPostalCodes = field.invalidValues;

              break;
            }

            case 'state':
              addressConfigObj.isStateCombo = (field.type === 'combo');
              addressConfigObj.showState = true;
              break;

            case 'county':
              addressConfigObj.showCounty = !this.props.isAutoPay;
              break;

            case 'city':
              addressConfigObj.showCity = true;
              break;

            case 'addressLine3':
              addressConfigObj.showAddress3 = !this.props.isAutoPay;
              break;
          }

          addressConfigObj[field.id] = FormatHelper.formatMessage(field.labelId);
        }
      }

      if (this.props.isCityRequired && countryValue && countryValue.id && (countryValue.id.toUpperCase() === 'US' || countryValue.id.toUpperCase() === 'CA')) {
        addressConfigObj.isCityRequired = this.props.isCityRequired;
      }
      else if (addressConfigObj.showCity) {
        addressConfigObj.isCityRequired = isRequired;
      }

      if (this.props.isStateRequired && countryValue && countryValue.id && (countryValue.id.toUpperCase() === 'US' || countryValue.id.toUpperCase() === 'CA')) {
        addressConfigObj.isStateRequired = this.props.isStateRequired;
      }
      else if (addressConfigObj.showState) {
        addressConfigObj.isStateRequired = isRequired;
      }

      if (this.props.isPostalCodeRequired && countryValue && countryValue.id && (countryValue.id.toUpperCase() === 'US' || countryValue.id.toUpperCase() === 'CA')) {
        addressConfigObj.isPostalCodeRequired = this.props.isPostalCodeRequired;
      }
      else if (addressConfigObj.showPostalCode) {
        addressConfigObj.isPostalCodeRequired = isRequired;
      }

      addressConfigObj.isAddressRequired = isRequired;
      return addressConfigObj;
    }
  };

  onCountryChange = (value: any) => {
    const newState = { ...this.state };
    const newValue = newState.value;

    newValue.stateList = getStateList(value.value);
    newValue.addressConfig = this.updateConfig({ id: value.value }, newState.isRequired);
    newValue.postalCode = this.state.value.addressConfig.postalCode;
    newValue.addressDetails.countryCode = value.value;
    newValue.addressDetails.countryName = value.label;
    newValue.addressDetails.address1 = '';
    newValue.addressDetails.address2 = '';
    newValue.addressDetails.address3 = '';

    // When the countries are American Samoa, Guam, Northern Mariana Islands, Puerto Rico, US Minor Outlying Islands, the states are also always American Samoa, Guam, Northern Mariana Islands, Puerto Rico, US Minor Outlying Islands respectively.
    // When the country is Virgin Islands, U.S, the state gets changed to district.
    if (value.value === 'AS' || value.value === 'GU' || value.value === 'MP' || value.value === 'PR' || value.value === 'UM' || value.value === 'VI') {
      // American Samoa, Guam, Northern Mariana Islands, Puerto Rico, US Minor Outlying Islands have only 1 state in their state list.
      // Virgin Islands, U.S has it's appropriate districts.
      newValue.addressDetails.provinceCode = newValue.stateList[0].id;
      newValue.addressDetails.provinceName = newValue.stateList[0].name;
    }
    else {
      newValue.addressDetails.provinceCode = '';
      newValue.addressDetails.provinceName = '';
    }

    newValue.addressDetails.city = '';
    delete newValue.addressDetails.postalCode;
    delete newValue.addressDetails.county;
    newValue.postalCode = newValue.addressConfig.postalCode;

    this.validateAddressComponent(newState, true);
    this.validateAddress(LABEL_KEYS.COUNTRY, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState, true);
  };

  updateState = (newState: any, providedByGoogle?: boolean) => {
    const { isValid, validationMessages, ...info } = newState;

    if (this.context.updateFieldState) {
      this.context.updateFieldState(this.props.name, { isValid, validationMessages, value: { ...info, providedByGoogle } });
    }

    if (this.props.onAddressChange) {
      this.props.onAddressChange({ isValid, validationMessages, ...info.value }, providedByGoogle);
    }

    this.setState(newState);
  };

  getStateCode = (stateCode: string, stateLabel: string) => {
    const stateList = this.state.value.stateList;

    if (stateList) {
      const count: number = stateList.length;

      for (let i = 0; i < count; i++) {
        const option = stateList[i];

        if (option && ((stateCode && option.id.toLowerCase() === stateCode.toLocaleLowerCase()) || (stateLabel && option.id.toLowerCase() === stateLabel.toLowerCase()))) {
          return option;
        }
      }
    }

    return { id: stateCode, name: stateLabel };
  };

  onAddressSelect = (value: IAutoCompleteAddress, providedByGoogle?: boolean) => {
    const newState = { ...this.state };
    const newValue = newState.value;

    this.isSelectedAddress = true;

    value.address1 = GenericValidator.isNotNullOrEmpty(value.address1) ? value.address1 : this.state.value.addressDetails.address1;
    value.postalCode = GenericValidator.isNotNullOrEmpty(value.postalCode) ? value.postalCode : '';
    newValue.addressDetails = value;

    const showDetails = this.getShowAddressDetails(value);
    newValue.showExpandAddress = showDetails.showExpandAddress;
    newValue.showAddressField2 = showDetails.showAddressField2;
    newValue.showAddAddress3 = showDetails.showAddAddress3;
    newValue.showAddressField3 = showDetails.showAddressField3;
    newValue.showCloseAddress2 = showDetails.showCloseAddress2;

    const province = this.getStateCode(value.provinceCode, value.provinceName);
    newValue.addressDetails.provinceCode = province.id;
    newValue.addressDetails.provinceName = province.name;

    this.validateAddressComponent(newState, true);
    this.validateAddress(LABEL_KEYS.ADDRESS_LINE_1, false, newState);
    this.updateState(newState, providedByGoogle);

    // Store previous AddressDetails.
    this.previousAddressDetails = newState;

    this.isGoogleSuggestedAddress = providedByGoogle;
    this.showAlertMessage(newState, true);
  };

  onStateBlur = () => {
    this.validateAddress(LABEL_KEYS.STATE, false, this.state);
    this.showAlertMessage(this.state);
  };

  onStateChange = (newValue: any) => {
    const newState = { ...this.state };

    if (typeof newValue === 'object') {
      newState.value.addressDetails.provinceCode = newValue.id;
      newState.value.addressDetails.provinceName = newValue.name;
    }
    else {
      newState.value.addressDetails.provinceCode = newValue;
      newState.value.addressDetails.provinceName = newValue;
    }

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.STATE, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  onAddressLine1Change = (newValue: any) => {
    // Need to deal with the fact that line 1 is connected to the Google
    // Places API via the AutoCompleteAddress component, and that component
    // has both the normal input text change event and the addressSelect event
    // from the Google component. The setTimeout allows this component handle
    // the addressSelect event first and validate that input, and then either
    // skip this event (if an address was selected) or use the text entered by
    // the user as line 1 of a manually entered address.
    setTimeout(() => {
      if (this.isSelectedAddress) {
        this.isSelectedAddress = false;
      }
    }, 10);

    const newState = { ...this.state };

    newState.value.addressDetails.address1 = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.ADDRESS_LINE_1, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState, true);
  };

  onAddressLine2Change = (newValue: any) => {
    const newState = { ...this.state };

    newState.value.addressDetails.address2 = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.ADDRESS_LINE_2, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  onAddressLine3Change = (newValue: any) => {
    const newState = { ...this.state };

    newState.value.addressDetails.address3 = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.ADDRESS_LINE_3, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  onCityChange = (newValue: any) => {
    const newState = { ...this.state };

    newState.value.addressDetails.city = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.CITY, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  onCountyChange = (newValue: any) => {
    const newState = { ...this.state };

    newState.value.addressDetails.county = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.COUNTY, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  onPostalCodeChange = (newValue: any) => {
    const newState = { ...this.state };

    newState.value.addressDetails.postalCode = newValue;

    this.validateAddressComponent(newState);
    this.validateAddress(LABEL_KEYS.POSTAL_CODE, false, newState);
    this.updateState(newState);
    this.showAlertMessage(newState);
  };

  checkRequiredness = (labelKey, messageKey, fieldValue, newState) => {
    if (!GenericValidator.isNotNullOrEmpty(fieldValue)) {
      newState.validationMessages[labelKey] = FormatHelper.formatMessage(messageKey);
      return false;
    }

    return true;
  };

  showAddress2 = () => {
    this.setState({
      value: {
        ...this.state.value,
        showAddressField2: true,
        showAddressField3: false,
        showCloseAddress2: false,
        showAddAddress3: true,
        showExpandAddress: false
      }
    });
  };

  hideAddress2 = () => {
    this.setState({
      value: {
        ...this.state.value,
        showAddressField2: false,
        showCloseAddress2: false,
        showAddAddress3: true,
        showExpandAddress: true
      }
    });
  };

  showAddress3 = () => {
    this.setState({
      value: {
        ...this.state.value,
        showAddressField3: true,
        showCloseAddress2: true,
        showAddAddress3: false,
        showExpandAddress: false
      }
    });
  };

  hideAddress3 = () => {
    this.setState({
      value: {
        ...this.state.value,
        showAddressField3: false,
        showExpandAddress: true
      }
    });
  };

  // 1. Show Alert : Address2, County, country, zipcode value got changed from what was updated by google
  // 2. Hide Alert: When country, Address1 (suggested Google) field is updated, when field is undone to reset to the old value suggested by google and the user manually enters address1 field
  showAlertMessage = (newState, removeAlert?: boolean) => {
    const hideAlert = removeAlert || false;

    if (this.previousAddressDetails && !equals(this.previousAddressDetails.value.addressDetails, newState.value.addressDetails) && !hideAlert && this.isGoogleSuggestedAddress) {
      this.setState({ showAlert: true });
    }
    else {
      this.setState({ showAlert: false });
    }
  };

  validateAddress = (fieldName, checkAll, newState, clearMessages?, checkValid?) => {
    const newValue = newState.value;
    let isValid = true;
    let isFieldRequiredValid = true;
    const previousValidationMessages = { ...newState.validationMessages };

    switch (fieldName) {
      case LABEL_KEYS.ADDRESS_LINE_1: {
        newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_1] = '';

        if (newValue.addressConfig.isAddressRequired) {
          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.ADDRESS_LINE_1, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.address1, newState);

          if (clearMessages && !previousValidationMessages[LABEL_KEYS.ADDRESS_LINE_1]) {
            newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_1] = '';
          }
        }

        if (this.props.useLengthLimits && !GenericValidator.isNotMoreThanMaxLength(newValue.addressDetails.address1, MAX_LENGTH.ADDRESS_LINE_2)) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_1] = ' ' + FormatHelper.formatMessage('@@mdfMaxLength', { maxLength: MAX_LENGTH.ADDRESS_LINE_2 });
        }

        if (!GenericValidator.isValidPattern(newValue.addressDetails.address1, new RegExp(this.props.address1RegExp ? this.props.address1RegExp : REG_EXP.ADDRESS_LINE_1))) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_1] = ' ' + (this.props.address1RegExpInvalidMessage ? this.props.address1RegExpInvalidMessage : FormatHelper.formatMessage('@@Rec_Ext_Cand_ValidValsForAddrss'));
        }

        if (newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_1] !== '' || !isFieldRequiredValid) {
          isValid = false;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.ADDRESS_LINE_2: {
        newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_2] = '';

        if (this.props.useLengthLimits && !GenericValidator.isNotMoreThanMaxLength(newValue.addressDetails.address2, MAX_LENGTH.ADDRESS_LINE_2)) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_2] = ' ' + FormatHelper.formatMessage('@@mdfMaxLength', { maxLength: MAX_LENGTH.ADDRESS_LINE_2 });
        }

        if (!GenericValidator.isValidPattern(newValue.addressDetails.address2, new RegExp(this.props.address2RegExp ? this.props.address2RegExp : REG_EXP.ADDRESS_LINE_2))) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_2] = ' ' + (this.props.address2RegExpInvalidMessage ? this.props.address2RegExpInvalidMessage : FormatHelper.formatMessage('@@Rec_Ext_Cand_ValidValsForAddrss'));
        }

        if (newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_2] !== '') {
          isValid = false;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.ADDRESS_LINE_3: {
        newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_3] = '';

        if (this.props.useLengthLimits && !GenericValidator.isNotMoreThanMaxLength(newValue.addressDetails.address3, MAX_LENGTH.ADDRESS_LINE_2)) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_3] = ' ' + FormatHelper.formatMessage('@@mdfMaxLength', { maxLength: MAX_LENGTH.ADDRESS_LINE_2 });
        }

        if (!GenericValidator.isValidPattern(newValue.addressDetails.address3, new RegExp(this.props.address2RegExp ? this.props.address2RegExp : REG_EXP.ADDRESS_LINE_2))) {
          newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_3] = ' ' + (this.props.address2RegExpInvalidMessage ? this.props.address2RegExpInvalidMessage : FormatHelper.formatMessage('@@Rec_Ext_Cand_ValidValsForAddrss'));
        }

        if (newState.validationMessages[LABEL_KEYS.ADDRESS_LINE_3] !== '') {
          isValid = false;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.CITY: {
        newState.validationMessages[LABEL_KEYS.CITY] = '';

        if (newValue.addressConfig.isCityRequired) {
          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.CITY, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.city, newState);

          if (clearMessages && !previousValidationMessages[LABEL_KEYS.CITY]) {
            newState.validationMessages[LABEL_KEYS.CITY] = '';
          }
        }

        if (this.props.useLengthLimits && !GenericValidator.isNotMoreThanMaxLength(newValue.addressDetails.city, this.props.nosExpandedFields ? MAX_LENGTH.CITY_30 : MAX_LENGTH.CITY)) {
          newState.validationMessages[LABEL_KEYS.CITY] = ' ' + FormatHelper.formatMessage('@@mdfMaxLength', { maxLength: this.props.nosExpandedFields ? MAX_LENGTH.CITY_30 : MAX_LENGTH.CITY });
        }

        if (!GenericValidator.isValidPattern(newValue.addressDetails.city, new RegExp(this.props.cityRegExp ? this.props.cityRegExp : REG_EXP.CITY))) {
          newState.validationMessages[LABEL_KEYS.CITY] = ' ' + (this.props.cityRegExpInvalidMessage ? this.props.cityRegExpInvalidMessage : FormatHelper.formatMessage('@@Rec_Ext_Cand_ValidValsForCity'));
        }

        if (GenericValidator.hasConsecutiveSpecialChars(newValue.addressDetails.city)) {
          newState.validationMessages[LABEL_KEYS.CITY] = ' ' + FormatHelper.formatMessage('@@Rec_Ext_Cand_ValidValsForCityConsecutive');
        }

        if (newState.validationMessages[LABEL_KEYS.CITY] !== '' || !isFieldRequiredValid) {
          isValid = false;
          isFieldRequiredValid = true;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.COUNTRY: {
        newState.validationMessages[LABEL_KEYS.COUNTRY] = '';

        if (newValue.addressConfig.isAddressRequired) {
          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.COUNTRY, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.countryCode, newState);

          if (clearMessages && !previousValidationMessages[LABEL_KEYS.COUNTRY]) {
            newState.validationMessages[LABEL_KEYS.COUNTRY] = '';
          }
        }

        if (!isFieldRequiredValid) {
          isValid = false;
          isFieldRequiredValid = true;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.COUNTY: {
        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.POSTAL_CODE: {
        newState.validationMessages[LABEL_KEYS.POSTAL_CODE] = '';

        if (newValue.addressConfig.isPostalCodeRequired) {
          if (document.getElementById('rcZipSpan')) {
            document.getElementById('rcZipSpan').remove();
            document.getElementById('rcZipInput').style.border = '';
          }

          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.POSTAL_CODE, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.postalCode, newState);

          if (clearMessages && !previousValidationMessages[LABEL_KEYS.POSTAL_CODE]) {
            newState.validationMessages[LABEL_KEYS.POSTAL_CODE] = '';
          }
        }

        // Handle special Ukraine rules:
        // Postal code is always required and must not be in the list of invalid postal codes.
        if (newValue.addressDetails.countryCode?.toUpperCase() === 'UA') {
          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.POSTAL_CODE, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.postalCode, newState);

          if (isFieldRequiredValid) {
            isValid = !(newValue.addressConfig.invalidPostalCodes?.includes(newValue.addressDetails.postalCode));

            if (!isValid) {
              newState.validationMessages[LABEL_KEYS.POSTAL_CODE] = ' ' + FormatHelper.formatMessage('@@mdfInvalidData');
            }
          }
        }

        if (!GenericValidator.isValidPattern(newValue.addressDetails.postalCode, new RegExp(newValue.addressConfig.postalCodeFormat))) {
          newState.validationMessages[LABEL_KEYS.POSTAL_CODE] = ' ' + FormatHelper.formatMessage('@@mdfInvalidData');
        }

        if (newState.validationMessages[LABEL_KEYS.POSTAL_CODE] !== '' || !isFieldRequiredValid) {
          isValid = false;
          isFieldRequiredValid = true;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
      // Fall through

      case LABEL_KEYS.STATE: {
        newState.validationMessages[LABEL_KEYS.STATE] = '';

        if (newValue.addressConfig.isStateRequired) {
          isFieldRequiredValid = this.checkRequiredness(LABEL_KEYS.STATE, ' ' + FormatHelper.formatMessage('@@mdfRequiredField'), newValue.addressDetails.provinceCode, newState);

          if (clearMessages && !previousValidationMessages[LABEL_KEYS.STATE]) {
            newState.validationMessages[LABEL_KEYS.STATE] = '';
          }
        }

        if (newState.validationMessages[LABEL_KEYS.STATE] !== '' || !isFieldRequiredValid) {
          isValid = false;
          isFieldRequiredValid = true;
        }

        if (!(checkAll || (checkValid && (isValid || !clearMessages)))) {
          break;
        }
      }
    }

    return isValid;
  };

  // Turn off the google tied variable anytime the user starts typing to hide the alert.
  addressLine1KeyDown = () => {
    this.isGoogleSuggestedAddress = false;
  };

  render() {
    const { addressConfig, addressDetails, stateList, ...showDetails } = this.state.value;
    const { validationMessages } = this.state;
    const { adaptive, readOnly } = this.props;
    const autoCompleteClassName = this.props.numberOfColumns === 1 ? 'autocomplete-address-container' : '';
    // The MDFIcon will handle upgrading the icon based on the environment.
    const address2ExpandIcon = showDetails.showCloseAddress2 ? 'fa fa-times-circle' : 'fa fa-plus-circle';
    const areFieldsDisabled = this.props.disabled;

    // is Readonly
    const isReadOnly = readOnly || false;
    // generate new id for label and this will be passed for MDFLabeledDropdownList
    const labelId = generateId('mdfstatelabel');

    const handleEvent = (event, action) => {
      if (event.key === 'Enter' || event.key === ' ') {
        action();
        event.preventDefault();
      }
    };

    const countryField = (
      <div className={'address-item vdl-dropdown-list-nowrap'}>
        {this.renderAlert()}
        <MDFLabeledCountry
          adaptive={adaptive}
          autoFocus={this.props.autoFocus}
          labelText={addressConfig.country}
          aria-label={addressConfig.country}
          isValid={!validationMessages[LABEL_KEYS.COUNTRY]}
          validationMessage={validationMessages[LABEL_KEYS.COUNTRY]}
          onChange={this.onCountryChange}
          value={addressDetails.countryCode?.toUpperCase()}
          required={addressConfig.isAddressRequired}
          disabled={this.props.disabled}
          isClearable={false}
        />
      </div>
    );
    const addressField1 = (
      <div className={'address-item'}>
        <div className={autoCompleteClassName}>
          <MDFLabeledAutoCompleteAddress
            id={this.props.id ? `${this.props.id}_address_line1` : undefined}
            labelText={addressConfig.addressLine1}
            isValid={!validationMessages[LABEL_KEYS.ADDRESS_LINE_1]}
            validationMessage={validationMessages[LABEL_KEYS.ADDRESS_LINE_1]}
            onAddressSelect={this.onAddressSelect}
            required={addressConfig.isAddressRequired}
            alwaysShowValidation={true}
            country={addressDetails.countryCode}
            value={addressDetails.address1}
            onKeyDown={this.addressLine1KeyDown}
            onChange={this.onAddressLine1Change}
            disabled={areFieldsDisabled}
            className={this.props.numberOfColumns === 1 && showDetails.showExpandAddress ? 'address-item-expand-textbox' : ''}
          />
          {this.props.numberOfColumns === 1 && showDetails.showExpandAddress ? <span className="autocomplete-address-expand" role="button" tabIndex={0} aria-label={FormatHelper.formatMessage('@@ADD_ADDRESS_LINE2')} onKeyDown={(event) => handleEvent(event, this.showAddress2)} onClick={this.showAddress2}><MDFIcon className="fa fa-plus-circle"/></span> : false}
        </div>
      </div>
    );
    const addressField2 = (
      <div className={'address-item'}>
        <div className={autoCompleteClassName}>
          <MDFLabeledTextbox
            id={this.props.id ? `${this.props.id}_address_line2` : undefined}
            labelText={addressConfig.addressLine2}
            isValid={!validationMessages[LABEL_KEYS.ADDRESS_LINE_2]}
            validationMessage={validationMessages[LABEL_KEYS.ADDRESS_LINE_2]}
            value={addressDetails.address2}
            onChange={this.onAddressLine2Change}
            disabled={areFieldsDisabled}
            className={this.props.numberOfColumns === 1 ? 'address-item-expand-textbox' : ''}
            alwaysShowValidation={true}
          />
          {this.props.numberOfColumns === 1 ? <span className="autocomplete-address-expand" role="button" tabIndex={0} aria-label={(showDetails.showAddAddress3 ? FormatHelper.formatMessage('@@ADD_ADDRESS_LINE3') : FormatHelper.formatMessage('@@REMOVE_ADDRESS_LINE2'))} onKeyDown={(event) => handleEvent(event, showDetails.showAddAddress3 ? this.showAddress3 : this.hideAddress2)} onClick={showDetails.showAddAddress3 ? this.showAddress3 : this.hideAddress2}><MDFIcon className={address2ExpandIcon}/></span> : false}
        </div>
      </div>
    );
    const addressField3 = (
      <div className={'address-item'}>
        <div className={autoCompleteClassName}>
          <MDFLabeledTextbox
            id={this.props.id ? `${this.props.id}_address_line3` : undefined}
            labelText={addressConfig.addressLine3}
            isValid={!validationMessages[LABEL_KEYS.ADDRESS_LINE_3]}
            validationMessage={validationMessages[LABEL_KEYS.ADDRESS_LINE_3]}
            value={addressDetails.address3}
            onChange={this.onAddressLine3Change}
            disabled={areFieldsDisabled}
            className={this.props.numberOfColumns === 1 ? 'address-item-expand-textbox' : ''}
            alwaysShowValidation={true}
          />
          {this.props.numberOfColumns === 1 ? <span className={'autocomplete-address-expand'} role="button" tabIndex={0} aria-label={FormatHelper.formatMessage('@@REMOVE_ADDRESS_LINE3')} onKeyDown={(event) => handleEvent(event, this.hideAddress3)} onClick={this.hideAddress3}><MDFIcon className="fa fa-times-circle"/></span> : false}
        </div>
      </div>
    );
    const cityField = addressConfig.showCity && (
      <div className={'address-item'}>
        <MDFLabeledTextbox
          labelText={addressConfig.city}
          value={addressDetails.city}
          isValid={!validationMessages[LABEL_KEYS.CITY]}
          validationMessage={validationMessages[LABEL_KEYS.CITY]}
          required={addressConfig.isCityRequired}
          alwaysShowValidation={true}
          onChange={this.onCityChange}
          disabled={areFieldsDisabled}
        />
      </div>
    );
    const stateOrProvinceDropdown = addressConfig.showState && (stateList && stateList.length !== 0) && (
      <div className={(this.props.numberOfColumns === 1 && addressConfig.showPostalCode) ? 'address-item-state' : 'address-item'}>
        <MDFLabeledDropdownList
          adaptive={adaptive}
          labelText={addressConfig.state}
          aria-label={addressConfig.state}
          data={stateList}
          isValid={!validationMessages[LABEL_KEYS.STATE]}
          validationMessage={validationMessages[LABEL_KEYS.STATE]}
          valueField={'id'}
          textField={'name'}
          filter={adaptive ? false : 'contains'}
          groupBy={adaptive ? false : undefined}
          value={addressDetails.provinceCode}
          onBlur={this.onStateBlur}
          onChange={this.onStateChange}
          disabled={areFieldsDisabled}
          className={this.props.numberOfColumns === 1 ? 'address-item-textbox' : ''}
          required={addressConfig.isStateRequired}
          alwaysShowValidation={true}
          aria-labelledby={labelId}
        />
      </div>
    );
    const stateOrProvinceField = addressConfig.showState && !(stateList && (stateList.length !== 0)) && (
      <div className={(this.props.numberOfColumns === 1 && addressConfig.showPostalCode) ? 'address-item-state' : 'address-item'}>
        <MDFLabeledTextbox
          labelText={addressConfig.state}
          value={addressDetails.provinceCode}
          isValid={!validationMessages[LABEL_KEYS.STATE]}
          validationMessage={validationMessages[LABEL_KEYS.STATE]}
          onChange={this.onStateChange}
          disabled={areFieldsDisabled}
          required={addressConfig.isStateRequired}
          alwaysShowValidation={true}
          className={this.props.numberOfColumns === 1 ? 'address-item-textbox' : ''}
        />
      </div>
    );
    const zipOrPostalCodeField = addressConfig.showPostalCode && (
      <div className={(this.props.numberOfColumns === 1 && addressConfig.showState) ? 'address-item-postalcode' : 'address-item'}>
        <MDFLabeledTextbox
          labelText={addressConfig.postalCode}
          value={addressDetails.postalCode}
          isValid={!validationMessages[LABEL_KEYS.POSTAL_CODE]}
          validationMessage={validationMessages[LABEL_KEYS.POSTAL_CODE]}
          required={addressConfig.isPostalCodeRequired}
          alwaysShowValidation={true}
          onChange={this.onPostalCodeChange}
          disabled={areFieldsDisabled}
          helpMessage={(addressConfig.postalCodeHelpMessage ? addressConfig.postalCodeHelpMessage : '')}
          className={this.props.numberOfColumns === 1 ? 'address-item-textbox' : ''}
        />
      </div>
    );
    const countyField = addressConfig.showCounty && (
      <div className={'address-item'}>
        <MDFLabeledTextbox
          labelText={addressConfig.county}
          value={addressDetails.county}
          isValid={!validationMessages[LABEL_KEYS.COUNTY]}
          validationMessage={validationMessages[LABEL_KEYS.COUNTY]}
          onChange={this.onCountyChange}
          disabled={areFieldsDisabled}
        />
      </div>
    );

    if (isReadOnly) {
      return (<div>
        {addressDetails.address1 && <span>{addressDetails.address1}<br /></span>}
        {addressDetails.address2 && <span>{addressDetails.address2}<br /></span>}
        {(addressDetails.address3 && addressConfig.showAddress3) && <span>{addressDetails.address3}<br /></span>}
        <span>{addressDetails.city}</span><span>{(addressDetails.provinceCode && (addressDetails.provinceCode !== addressDetails.city)) ? ', ' + addressDetails.provinceCode : ''}</span> <span>{addressDetails.postalCode}</span><br />
        <span>{this.getCountryName(addressDetails.countryCode)}</span>
      </div>);
    }
    else if (this.props.isCompactStatic) {
      return (<div>
        <span>{addressDetails.address1}</span><br />
        {addressDetails.address2 && <span>{addressDetails.address2}<br /></span>}
        {(addressDetails.address3 && addressConfig.showAddress3) && <span>{addressDetails.address3}<br /></span>}
        <span>{addressDetails.city}</span><span>{(addressDetails.provinceCode && (addressDetails.provinceCode !== addressDetails.city)) ? ', ' + addressDetails.provinceCode : ''}</span> <span>{addressDetails.postalCode}</span><br />
        <span>{addressDetails.countryCode?.toUpperCase()}</span>
      </div>);
    }
    else if (this.props.numberOfColumns === 1) {
      return (
        <div className={this.props.className}>
          <div>
            {countryField}
            {addressField1}
            {showDetails.showAddressField2 ? addressField2 : false}
            {showDetails.showAddressField3 ? addressConfig.showAddress3 && addressField3 : false}
            {cityField}
            {stateOrProvinceDropdown}
            {stateOrProvinceField}
            {zipOrPostalCodeField}
            {countyField}
          </div>
        </div>
      );
    }
    else if (this.props.numberOfColumns === 3) {
      return (
        <div className={this.props.className}>
          <div className={'vdl-row address-row'}>
            {countryField}
          </div>
          <div className={'vdl-row address-row'}>
            {addressField1}
            {addressField2}
            {addressConfig.showAddress3 && addressField3}
          </div>
          <div className={'vdl-row address-row'}>
            {cityField}
            {stateOrProvinceDropdown}
            {stateOrProvinceField}
            {zipOrPostalCodeField}
          </div>
          <div className={'vdl-row address-row'}>
            {countyField}
          </div>
        </div>
      );
    }
    else {
      return (
        <div className={this.props.className}>
          <div className={'vdl-row address-row'}>
            {countryField}
          </div>
          <div className={'vdl-row address-row'}>
            {addressField1}
            {addressField2}
          </div>
          <div className={'vdl-row address-row'}>
            {addressConfig.showAddress3 && addressField3}
            {cityField}
          </div>
          <div className={'vdl-row address-row'}>
            {stateOrProvinceDropdown}
            {stateOrProvinceField}
            {zipOrPostalCodeField}
          </div>
          <div className={'vdl-row address-row'}>
            {countyField}
          </div>
        </div>
      );
    }
  }
}
