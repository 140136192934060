import React from 'react';
import PropTypes from 'prop-types';
import { createBrowserHistory, createHashHistory } from 'history';
import { connect } from 'react-redux';
import { ComponentManager } from '@adp-wfn/mdf-core';
import { match } from 'path-to-regexp';

// Interface for each route configuration
interface IRoute {
  // route path. It can be simple url or url with regex to match runtime path
  path: string;
  // MDF View name to appear
  view: string;
}

// Interface for the router component properties
interface IRouterComponentProps {
  // list of routes to handle
  routes: IRoute[];

  // use this option to use browser history. By default router use hash history.
  useBrowserHistory?: boolean;

  // default route
  currentRoute?: any;

  // function to handle when the router change the location. pathname => url of the new page. params=> runtime values of the url
  onChange?: (pathname: string, params: any) => void;

  // name of the redux store to get router data
  storeName?: string;
}

// Interface for the router component state
interface IRouterComponentState {
  // current active route used by the router
  currentRoute: string;
}

export class RouterComponent extends React.Component<IRouterComponentProps, IRouterComponentState> {
  // history reference
  private history: any = null;

  // history listener reference, this is used to un-listen when this component is unmounted
  private readonly unListenHistoryKey = null;

  // this variable holds the view to be rendered
  private currentRoute;

  // this variable holds the view's runtime params
  private currentRouteParams;

  // this variable suppress history listener on address change
  private isChangingLocally = false;

  static propTypes = {
    routes: PropTypes.any,
    currentRoute: PropTypes.any,
    useBrowserHistory: PropTypes.bool,
    onChange: PropTypes.func,
    storeName: PropTypes.string
  };

  constructor(props: IRouterComponentProps) {
    super(props);

    // initialize the history object
    this.history = props.useBrowserHistory ? createBrowserHistory() : createHashHistory();

    // set default state
    let isUsingBrowserLocation = false;

    if (props.currentRoute) {
      this.currentRoute = props.currentRoute;
    }
    else {
      this.currentRoute = this.history.location.pathname;
      isUsingBrowserLocation = true;
    }

    this.state = { currentRoute: this.currentRoute };

    // // Listen for any changes to the browser location.
    this.unListenHistoryKey = this.history.listen(({ location }) => {
      if (!this.isChangingLocally) {
        console.log('Router.unListenHistoryKey(): new route ', location.pathname);

        if (location.pathname !== this.currentRoute) {
          this.setRouteState(location.pathname);
          this.props.onChange(location.pathname, this.currentRouteParams);
        }
      }

      this.isChangingLocally = false;
    });

    // if using browser location as initial route, then update inform the app to update the state. This should helps when the user refresh/reload the page.
    if (isUsingBrowserLocation && this.props.onChange) {
      this.setRouteState(this.state.currentRoute);
      props.onChange(this.state.currentRoute, this.currentRouteParams);
    }
  }

  componentDidUpdate() {
    if (this.props.currentRoute) {
      const isHistoryNavigation = this.props.currentRoute.method === 'goBack' || this.props.currentRoute.method === 'goForward';

      if (isHistoryNavigation) {
        // if navigation to back or prev, we have to update the route
        this.updateLocation(this.props.currentRoute, false);
      }
      else if (this.props.currentRoute.path !== this.state.currentRoute) {
        // if the new route is different from current route, update the state
        // set the new route in state
        this.setRouteState(this.props.currentRoute.path);
        // update the browser location
        this.updateLocation(this.props.currentRoute, true);
      }
    }
  }

  // unlisten from browser history
  componentWillUnmount() {
    if (this.unListenHistoryKey) {
      this.unListenHistoryKey();
    }
  }

  // this method search the given route and update the state
  private setRouteState(newRoutePath) {
    // search the new route from configured routes
    const route = this.searchRoute(newRoutePath);

    // if route found, then update the state and trigger onRouteChange with new route
    if (route) {
      this.currentRoute = newRoutePath;
      this.currentRouteParams = route.params;
      this.setState({ currentRoute: newRoutePath });
    }
  }

  // this method update the browser history based on the new route information
  private updateLocation(newroute, shouldUnlistenChange) {
    // clear the listener
    this.isChangingLocally = shouldUnlistenChange;

    // updating the browser location
    switch (newroute.method) {
      case 'push':
        this.history.push({ pathname: newroute.path });
        break;
      case 'replace':
        this.history.replace({ pathname: newroute.path });
        break;
      case 'goBack':
        this.history.back();
        break;
      case 'goForward':
        this.history.forward();
        break;
    }
  }

  // this method search the given path in the configured route
  private searchRoute(pathname: string): { route: IRoute; params: any } {
    // searching the route
    let params = null;
    let searchedRoute: IRoute = null;

    const hasFound = this.props.routes.some((route) => {
      // find the matching pathname using match function from path-to-regexp
      const matcher = match(route.path, { decode: decodeURIComponent });
      const result = matcher(pathname);

      if (result) {
        searchedRoute = route;
        params = result.params;
      }

      return result;
    });

    // if route not found, then search for the default route. This option should be used to shows 404.
    if (!hasFound) {
      searchedRoute = this.props.routes.find((route) => route.path === '*');
    }

    if (!searchedRoute) {
      console.error('Unable to find matching view for the route', pathname);
    }

    return { route: searchedRoute, params };
  }

  render() {
    // get the view
    const result = this.searchRoute(this.state.currentRoute);

    // render the view using ComponentManager
    if (result.route.view) {
      return <div>{React.createElement(ComponentManager.getComponent(result.route.view))}</div>;
    }
    else {
      return <div />;
    }
  }
}

// redux connection function to return the props from state
const mapStateToProps = (state, ownProps) => {
  const storeName = ownProps.storeName || 'router';
  return { ...state[storeName] };
};

// redux connection function to return the props from dispatcher
/*
const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onChange: (route: string) => {
      console.log('mapDispatchToProps.onChange(): route ', route);

      if (ownProps.onChange) {
        dispatch(ownProps.onChange);
      }
    }
  };
};
*/

export const Router = connect(mapStateToProps)(RouterComponent);

// List of MDFRoute actions
export const LOCATION_CHANGE = 'MDF-ROUTER/LOCATION_CHANGE';

function updateLocation(method) {
  return (path, params?) => ({
    type: LOCATION_CHANGE,
    payload: { method, path, params }
  });
}

// to navigate to new route
const push = updateLocation('push');

// used by router to reset the route
const go = updateLocation('go');

// to navigate back to previous history
const goBack = () => ({
  type: LOCATION_CHANGE,
  payload: { method: 'goBack', path: '/' }
});

// to navigate forward in the history (if forward available)
const goForward = () => ({
  type: LOCATION_CHANGE,
  payload: { method: 'goForward', path: '/' }
});

// exporting the actions
export const RouterActions = { push, go, goBack, goForward };

// Reducer method to update the history based on the store
export function RouterReducer(state, action: any) {
  if (action.type === LOCATION_CHANGE) {
    return { ...state, currentRoute: action.payload };
  }

  return state;
}
