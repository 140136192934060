import { IconName } from '@waypoint/ui-framework/dist/typescript/icon-map';

// These are currently listed in popularity order
const iconConverter = {
  'fa-info-circle': 'alert-info',
  'fa-plus-circle': 'action-add',
  'fa-pencil': 'action-edit',
  'fa-question-circle': 'action-help',
  'fa-exclamation-triangle': 'alert-warning',
  'fa-times': 'action-close',
  'fa-trash': 'action-delete',
  'fa-check': 'action-confirm',
  'fa-check-circle': 'alert-success',
  'fa-clock-o': 'action-view-time',
  'fa-exclamation-circle': 'alert-error',
  'fa-plus': 'plus',
  'fa-trash-o': 'action-delete',
  'fa-minus-circle': 'action-subtract',
  'fa-chevron-down': 'action-expand',
  'fa-download': 'action-download',
  'fa-chevron-right': 'nav-page-next',
  'fa-eye': 'action-show',
  'fa-warning': 'alert-warning',
  'fa-file-text-o': 'media-file',
  'fa-star': 'feedback-rate-empty',
  'fa-times-circle': 'action-close',
  'fa-caret-down': 'action-menu-open',
  'fa-caret-up': 'action-menu-close',
  'fa-circle': 'ext-mdi-circle',
  'fa-arrow-down': 'action-sort-desc',
  'fa-money': 'brand-payments',
  'fa-filter': 'action-filter',
  // 'fa-hand-o-right': 'none', No replacement yet
  'fa-history': 'action-history',
  'fa-user': 'user',
  'fa-calendar': 'ext-mdi-calendar-month',
  'fa-external-link': 'external-link',
  'fa-angle-double-right': 'nav-page-next',
  'fa-refresh': 'action-refresh',
  'fa-envelope': 'action-email',
  'fa-sort': 'action-sort',
  // 'fa-spinner': 'none', No replacement yet
  'fa-chevron-left': 'nav-page-back',
  'fa-users': 'user-group',
  'fa-chevron-circle-right': 'nav-page-next',
  'fa-file-pdf-o': 'media-file-pdf',
  'fa-angle-down': 'nav-page-up',
  'fa-arrow-left': 'nav-move-left',
  'fa-search': 'action-search',
  'fa-flag': 'status-flagged',
  'fa-angle-right': 'nav-page-next',
  'fa-save': 'action-save',
  'fa-close': 'action-close',
  'icon-block': 'ext-mdi-block',
  'fa-arrow-up': 'action-sort-asc',
  'fa-star-o': 'feedback-rate-empty',
  'fa-ban': 'ext-mdi-block',
  'fa-sort-asc': 'action-sort-asc',
  'fa-check-circle-o': 'alert-success',
  'fa-caret-right': 'action-tree-expand',
  'fa-map-marker': 'location',
  'fa-upload': 'action-upload',
  'icon-vacation-timeoff': 'pto-vacation',
  'fa-print': 'action-print',
  'fa-chevron-up': 'action-collapse',
  'fa-phone': 'phone',
  // 'fa-table': 'none', No replacement yet
  // 'fa-calculator': 'none', No replacement yet
  'fa-arrow-right': 'nav-move-right',
  'fa-home': 'home',
  'fa-exchange': 'action-swap',
  // 'fa-sticky-note': 'none', No replacement yet
  'fa-arrow-circle-right': 'nav-move-right',
  // 'fa-bolt': 'none', No replacement yet
  'icon-close-thin': 'action-close',
  'fa-sort-desc': 'action-sort-desc',
  'fa-cog': 'action-view-settings',
  // 'fa-flag-checkered': 'none', No replacement yet
  'fa-lock': 'status-locked',
  'fa-university': 'institution-bank',
  'fa-ellipsis-v': 'action-more-horiz',
  'fa-file': 'media-file',
  'fa-file-o': 'media-file',
  'fa-mobile': 'ext-mdi-smartphone',
  'fa-times-circle-o': 'ext-mdi-dangerous',
  'fa-list-alt': 'action-view-list',
  'icon-reg-pay': 'brand-payments',
  'icon-retirement': 'retirement',
  'fa-sitemap': 'ext-mdi-ian',
  'fa-stethoscope': 'brand-medical',
  'fa-angle-up': 'action-collapse',
  'fa-list': 'ext-mdi-format-list-bulleted',
  'icon-payroll': 'brand-payroll',
  'fa-cloud-upload': 'action-upload-cloud',
  'fa-comment': 'action-view-comment',
  'fa-file-excel-o': 'media-file-excel',
  'fa-paper-plane-o': 'action-send',
  'icon-overtime-shift': 'ext-mdi-more-time',
  'fa-cogs': 'action-view-settings',
  'icon-health-benefits': 'brand-benefits',
  'fa-globe': 'status-public',
  'icon-position': 'location',
  // 'fa-circle-o-notch': 'none', No replacement yet
  'fa-file-text': 'media-file',
  'fa-file-word-o': 'media-file-word',
  'fa-lightbulb-o': 'lightbulb',
  'fa-plane': 'action-send',
  // 'icon-life-insurance'	'none', No replacement yet
  'fa-dollar': 'brand-payments',
  'fa-files-o': 'media-file',
  'fa-minus': 'minus',
  'fa-certificate': 'event-award',
  'fa-copy': 'action-copy',
  'fa-graduation-cap': 'event-graduation',
  'fa-step-forward': 'media-next',
  // 'icon-missing-punch': 'none', No replacement yet
  'fa-asterisk': 'ext-mdi-emergency',
  'fa-briefcase': 'ext-mdi-business-center',
  'fa-chevron-circle-left': 'nav-page-back',
  'fa-angle-left': 'nav-page-back',
  'fa-comment-o': 'action-view-comment',
  'fa-exclamation': 'alert-error',
  'fa-rocket': 'action-launch',
  'fa-address-card-o': 'contact-card',
  'fa-bank': 'institution-bank',
  'fa-gear': 'action-view-settings',
  'fa-bell': 'alert-notification',
  'fa-edit': 'action-edit',
  'fa-moon-o': 'action-darken',
  'fa-paperclip': 'action-attach',
  'fa-sort-down': 'action-sort-desc',
  // 'icon-mgr-note': 'none', No replacement yet
  'fa-arrow-circle-down': 'action-sort-desc',
  'fa-arrow-circle-left': 'nav-move-left',
  'fa-calendar-check-o': 'ext-mdi-event-available',
  'fa-cutlery': 'event-meal-started',
  // 'fa-pencil-square-o': 'none', No replacement yet
  'fa-share': 'action-share',
  'fa-square': 'media-stop',
  // 'fa-user-times': 'none', No replacement yet
  // 'icon-note': 'none', No replacement yet
  // 'icon-scheduled-earning-deductions': 'none', No replacement yet
  'icon-workers-comp': 'brand-work-comp',
  // 'fa-bus': 'none', No replacement yet
  'fa-eye-slash': 'action-hide',
  'icon-clients-info': 'contact-card',
  // 'fa-angle-double-down': 'none', No replacement yet
  // 'fa-bullhorn': 'none', No replacement yet
  'fa-envelope-o': 'action-email',
  'fa-star-half-o': 'feedback-rate-half',
  'icon-client-info': 'contact-card',
  'icon-insurance': 'brand-insurance',
  'icon-manual-check': 'ext-mdi-inventory',
  'fa-book': 'handbook',
  'fa-gift': 'gift',
  'fa-sort-amount-asc': 'action-sort-asc',
  'fa-sort-amount-desc': 'action-sort-desc',
  'fa-usd': 'ext-mdi-attach-money',
  'icon-view-report': 'action-view-reports',
  'fa-building-o': 'institution-business',
  // 'fa-check-square-o': 'none', No replacement yet
  'fa-file-image-o': 'media-image',
  // 'fa-sticky-note-o': 'none', No replacement yet
  'fa-tags': 'ext-mdi-more',
  'fa-user-plus': 'user-add',
  'icon-chart-tree': 'ext-mdi-ian',
  'fa-sign-out': 'action-logout',
  'fa-sort-up': 'action-sort-asc',
  // 'fa-angle-double-up': 'none', No replacement yet
  'fa-group': 'ext-mdi-groups',
  'fa-chevron-circle-down': 'action-expand',
  'fa-medkit': 'brand-medical',
  // 'fa-pencil-square': 'none', No replacement yet
  'fa-undo': 'action-undo',
  'icon-dental': 'brand-dental',
  'icon-esignature-signed': 'action-e-sign',
  'fa-circle-thin': 'ext-mdi-radio-button-unchecked',
  'fa-heartbeat': 'action-unfavorite',
  // 'fa-hourglass-start': 'none', No replacement yet
  'fa-leaf': 'media-paperless',
  // 'fa-pulse': 'none', No replacement yet
  // 'fa-search-plus': 'none', No replacement yet
  'icon-archive': 'media-folders',
  'icon-pad': 'ext-mdi-assignment',
  'fa-address-card': 'contact-card',
  'fa-floppy-o': 'action-save',
  'fa-paper-plane': 'action-send',
  'fa-send': 'action-send',
  'fa-sign-in': 'action-login',
  'fa-thumbs-o-up': 'feedback-like',
  'fa-trophy': 'ext-mdi-emoji-events',
  // 'icon-grip2': 'none', No replacement yet
  // 'icon-reset': 'none', No replacement yet
  // 'fa-align-justify': 'none', No replacement yet
  'fa-bar-chart': 'ext-mdi-bar-chart',
  // 'fa-child': 'none', No replacement yet
  'fa-youtube-play': 'media-play',
  'icon-manager': 'ext-mdi-supervisor',
  // 'fa-balance-scale': 'none', No replacement yet
  // 'fa-calendar-plus-o': 'none', No replacement yet
  // 'fa-eraser': 'none', No replacement yet
  // 'fa-gavel': 'none', No replacement yet
  'fa-laptop': 'ext-mdi-laptop',
  // 'fa-legal': 'none', No replacement yet
  'fa-line-chart': 'ext-mdi-insights',
  'fa-question': 'action-help',
  'fa-status': 'status-in-progress',
  // 'fa-tachometer': 'none', No replacement yet
  'fa-thumbs-up': 'feedback-like',
  'fa-wrench': 'ext-mdi-build',
  'icon-add-new-hire': 'user-add',
  'icon-attach-document1': 'action-attach',
  'icon-company-holiday': 'pto-vacation',
  'icon-id': 'contact-card'
  // 'icon-performance-toolkit': 'none', No replacement yet
  // 'icon-support': 'none', No replacement yet
};

export const iconMapper = (className: string): IconName | undefined => {
  const isADPUnified = !window['isLegacyAppShell'];

  // Don't convert icons that are in the legacy app shell because they won't appear.
  if (!isADPUnified) {
    return undefined;
  }

  const iconClasses = className?.split(' ')
    .filter((name) => (name === 'fa' || name.includes('fa-') || name.includes('icon-')));

  if (iconClasses?.length === 0 && className) {
    return className as IconName;
  }

  const mappedIcon = iconClasses?.map((icon: string) => iconConverter[icon]).filter((icon: string) => !!icon);

  if (mappedIcon?.length > 1) {
    console.error(`iconMapper(): className "${className}" contains more than 1 icon`);
  }

  return mappedIcon?.[0];
};
