import React from 'react';
import ReactDOM from 'react-dom';
import { Calendar, ICalendarProps } from './Calendar';
import { DeviceHelper, FormatHelper, generateId, LocaleHelper } from '@adp-wfn/mdf-core';
import { Button, Popup } from '@synerg/vdl-react-components';
import PropTypes from 'prop-types';
import CalendarIcon from 'adp-react-icons/lib/fa/calendar';
import { SdfIcon } from '@waypoint/react-components';
import { DateHelper } from './DateHelper';
import { TimeoutManager } from '@synerg/vdl-react-components/lib/util/timeout-manager';
import { handleBlur, handleFocus, IFocusMixinProps } from '@synerg/vdl-react-components/lib/util/focus-mixin';
import { activeElement } from 'dom-helpers';
import { isEqual } from 'lodash';
import resolveAriaProperty from '@synerg/vdl-react-components/lib/util/resolveAriaProperty';

export type IDatePickerSize = 'sm' | 'lg';
export interface IDatePickerProps extends ICalendarProps {
  // Accessibility message for component
  'aria-label'?: any;
  // ariaLabel deprecated and will be removed soon
  ariaLabel?: any;
  // Accessibility message for when component is invalid.
  ariaInvalid?: any;
  'aria-invalid'?: any;
  // Accessibility: links component to the corresponding popover message.
  ariaDescribedby?: string;
  'aria-describedby'?: string;
  // A caption for screen readers to read while entering the date picker
  caption?: string;
  // to enable the required property
  required?: boolean;
  // Accessibility feature manages focus.
  id?: string;
  // to override default locale
  locale?: string;
  // to enable/disable
  readOnly?: boolean;
  // to set the tab index
  tabIndex?: number;
  // input placeholder
  placeholder?: string;
  // input name
  name?: string;
  // Intl format object or predefined format string which are supported by FormatHelper.
  format?: string | {};
  // To use mobile date picker if supported.
  adaptive?: boolean;
  // To allow input as editable.
  disableEdit?: boolean;
  // to allow size to be either small or large.
  size?: IDatePickerSize;
  // to Allow autoFocus on page load.
  autoFocus?: boolean;
  // message to display when min validation fails.
  minValidationMessage?: string;
  // message to display when max validation fails.
  maxValidationMessage?: string;
  // have the DatePicker Popup to open down or up
  dropUp?: boolean;
  // Accessibility message for component
  ariaLabelledby?: string;
  'aria-labelledby'?: string;
}

// Getting default values
const defaultYear = DateHelper.today.getFullYear().toString();
const defaultMonth = (DateHelper.today.getMonth() + 1).toString().padStart(2, '0');
const defaultDate = DateHelper.today.getDate().toString().padStart(2, '0');

const parseYear = (year) => year >= 50 && year <= 100 ? year.padStart(4, '19') : year.padStart(4, '20');

// List of all supported date formats.
export const dateFormats = {
  // Listing default US (en-us, es-us) input formats which are MM DD YYYY or Mmm DD YYYY.
  us: [
    {
      /*
        Regex to match MMDDYYYY format
        (0[1-9]|1[0-2]) => matches month between 1 to 12.
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (19\d{2}|20\d{2}|[0-4]\d{1}) => matches year between 1900 and 2099. Optionally it accept 2 digit year between 01 and 99.
        ISOFormat returns regex replace function to get ISO value of the date.
        If year is <= 50 and >=100 set the padStart to 19 else set it to start with 20th century
      */
      regEx: /^(0[1-9]|1[0-2])(0?[1-9]|1\d|2\d|3[01])(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, month, date, year) => `${value && year ? parseYear(year) : defaultYear}-${month}-${date || defaultDate}`
    },
    {
      /*
        Regex to match MmmDDYYYY format
        ([a-zA-ZÀ-ÿ]{3}) => matches month names which is validated against the locale month.
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (19\d{2}|20\d{2}|[0-4]\d{1}) => matches year between 1900 and 2099. Optionally it accept 2 digit year between 01 and 49.
        ISOFormat returns regex replace function to get ISO value of the date.
      */
      regEx: /^([a-zA-ZÀ-ÿ]{3})(0?[1-9]|1\d|2\d|3[01])?(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, monthName, date, year) => `${value && year ? parseYear(year) : defaultYear}-${FormatHelper.getMonthIndex(monthName)}-${date || defaultDate}`
    }
  ],
  // List of default non US input formats.
  default: [
    {
      /*
       Regex to match DD (date only) format
       (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
       ISOFormat returns regex replace function to get ISO value of the date.
     */
      regEx: /^(0?[1-9]|1\d|2\d|3[01])$/,
      ISOFormat: (value, date) => `${value && defaultYear}-${defaultMonth}-${date}`
    },
    {
      /*
       Regex to match DDMmmYYYY format
       (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
       ([a-zA-ZÀ-ÿ]{3}) => matches month names which is validated against the locale month.
       (19\d{2}|20\d{2}|[0-9]\d{1})? => matches year between 1900 to 2099. Optionally it accept 2 digit year beween 00 to 99.
       ISOFormat returns regex replace function to get ISO value of the date.
       intl returns shortMonthName for September as Sept on en-ca locale, updated regEx to support 3 or 4 letters for monthName
     */
      regEx: /^(0?[1-9]|1\d|2\d|3[01])([a-zA-ZÀ-ÿ]{3,4})(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, date, monthName, year) => `${value && year ? parseYear(year) : defaultYear}-${FormatHelper.getMonthIndex(monthName) || defaultMonth}-${date}`
    },
    {
      /* regex to match DDMMYYYY format
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (0?[1-9]|1[0-2]) => matches month between 1 to 12.
        (19\d{2}|20\d{2}|[0-9]\d{1})? => matches year between 1900 to 2099. Optionally it accept 2 digit year beween 00 to 99.
        ISOFormat returns regex replace function to get ISO value of the date.
      */
      regEx: /^(0[1-9]|1\d|2\d|3[01])(0?[1-9]|1[0-2])(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, date, month, year) => `${value && year ? parseYear(year) : defaultYear}-${month}-${date}`
    },
    {
      /* regex to match MmmDDYYYY format
        ([a-zA-ZÀ-ÿ]{3}) => matches month names which is validated against the locale month.
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (19\d{2}|20\d{2}|[0-9]\d{1}) => matches year between 1900 and 2099. Optionally it accept 2 digit year between 00 and 99.
        ISOFormat returns regex replace function to get ISO value of the date.
      */
      regEx: /^([a-zA-ZÀ-ÿ]{3})(0?[1-9]|1\d|2\d|3[01])?(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, monthName, date, year) => `${value && year ? parseYear(year) : defaultYear}-${FormatHelper.getMonthIndex(monthName)}-${date || defaultDate}`
    },
    {
      /* regex to match MMDDYYYY format
        (0[1-9]|1[0-2]) => matches month between 1 to 12.
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (19\d{2}|20\d{2}|[0-9]\d{1}) => matches year between 1900 and 2099. Optionally it accept 2 digit year between 00 and 99.
        ISOFormat returns regex replace function to get ISO value of the date.
      */
      regEx: /^(0[1-9]|1[0-2])(0?[1-9]|1\d|2\d|3[01])(19\d{2}|20\d{2}|[0-9]\d{1})?$/,
      ISOFormat: (value, month, date, year) => `${value && year ? parseYear(year) : defaultYear}-${month}-${date || defaultDate}`
    },
    {
      /* regex to match YYYYMMDD format
        (0?[1-9]|1\d|2\d|3[01]) => matches dates between 1 to 31.
        (0?[1-9]|1[0-2]) => matches month between 1 to 12.
        (19\d{2}|20\d{2}|[0-9]\d{1})? => matches year between 1900 and 2099. Optionally it accept 2 digit year between 00 and 99.
        ISOFormat returns regex replace function to get ISO value of the date.
      */
      regEx: /^(19\d{2}|20\d{2}|[0-9]\d{1})(0?[1-9]|1[0-2])?(0?[1-9]|1\d|2\d|3[01])?$/,
      ISOFormat: (value, year, month, date) => `${value && year}-${month || defaultMonth}-${date || defaultDate}`
    }
  ]
};

interface IDatePickerState {
  // state to identify the datepicker popup is open or not
  isOpen?: boolean;
  // value of the date picker
  selectedDate?: Date;
  isValid?: boolean;
  validationMessage?: string;
  isoDate?: string;
  formattedValue?: string;
  focused?: boolean;
}

const minDate: string = LocaleHelper.dateAndTime('1900-01-01').toISOString();
const maxDate: string = LocaleHelper.dateAndTime('2099-12-31').toISOString();

export class DatePicker extends React.Component<IDatePickerProps, IDatePickerState> {
  static propTypes = Object.assign(
    {},
    Calendar.propTypes,
    {
      // DatePicker Props
      id: PropTypes.string,
      locale: PropTypes.string,
      readOnly: PropTypes.bool,
      tabIndex: PropTypes.number,
      placeholder: PropTypes.string,
      format: PropTypes.any,
      adaptive: PropTypes.bool,
      disableEdit: PropTypes.bool,
      size: PropTypes.oneOf(['sm', 'lg']),
      autoFocus: PropTypes.oneOf([true, false]),
      required: PropTypes.bool,
      dropUp: PropTypes.bool
    }
  );

  disableEdit = true;
  useDevicePicker = false;
  isValid = true;
  validationMessage = '';
  divDateInput = null;
  dateInput = null;
  calendarButton = null;
  min?: string = minDate;
  max?: string = maxDate;
  timeoutManager = new TimeoutManager();
  // This name comes from the ADP React Component library, so we must use it as-is.
  private _isMounted = false;

  constructor(props: IDatePickerProps) {
    super(props);

    this.useDevicePicker = props.adaptive && DeviceHelper.isMobileDevice();
    this.disableEdit = props.disableEdit;

    let state: IDatePickerState = { isOpen: false, formattedValue: '', isoDate: '' };

    if (props.selectedDate) {
      const selectedDate = this.initializeSelectedState(props);
      state = this.getDateState(selectedDate, false, props);
    }

    this.setMinAndMax(props);
    this.state = state;
  }

  static defaultProps = {
    size: 'lg',
    autoFocus: false
  };

  static displayName = 'DatePicker';

  private setMinAndMax = (props) => {
    if (props.min) {
      if (props.min instanceof Date) {
        this.min = props.min.toISOString();
      }
      else {
        this.min = props.min;
      }
    }
    else {
      this.min = minDate;
    }

    if (props.max) {
      const moment = LocaleHelper.dateAndTime;

      // setting the max value allowed as per dateFormats regex
      const maxDateAllowed = moment(maxDate);

      if (props.max instanceof Date) {
        this.max = props.max > maxDateAllowed.toDate() ? maxDateAllowed.toISOString() : props.max.toISOString();
      }
      else {
        const maxDateProp = moment(props.max);
        this.max = maxDateProp.toDate() > maxDateAllowed.toDate() ? maxDateAllowed.toISOString() : props.max;
      }
    }
    else {
      this.max = maxDate;
    }
  };

  private initializeSelectedState = (props) => {
    let selectedDate = null;

    if (props.selectedDate) {
      selectedDate = DateHelper.getDate(props.selectedDate);
    }

    return selectedDate;
  };

  private onPickerClick = (event) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();

    if (this.useDevicePicker) {
      if (DeviceHelper.isIOS()) {
        this.dateInput.focus();
      }
      else {
        this.dateInput.click();
      }
    }
    else {
      this.setState({ isOpen: !this.state.isOpen });
    }
  };

  close = () => {
    this.setState({ isOpen: false });
  };

  // When new props are received, compare the value and render the component if required.
  componentWillReceiveProps(nextProps: IDatePickerProps) {
    const selectedDate = nextProps.selectedDate ? DateHelper.getDate(nextProps.selectedDate) : null;
    const maxValue = this.max;
    const minValue = this.min;

    this.setMinAndMax(nextProps);

    if (DateHelper.isDateChange(selectedDate, this.state.selectedDate) || maxValue !== this.max || minValue !== this.min) {
      this.setState(this.getDateState(nextProps.selectedDate, false, nextProps));
    }
  }

  componentDidMount() {
    this.dateInput.value = (this.useDevicePicker ? this.state.isoDate : this.state.formattedValue);
    this._isMounted = true;
  }

  componentWillUnmount() {
    this._isMounted = false;
    this.timeoutManager.clearAllTimeouts();
  }

  // This method is used by handleBlur and handleFocus from the @synerg/vdl-react-components, so do not delete this method.
  setTimeout = (key, callback, duration) => {
    if (this._isMounted) {
      return this.timeoutManager.setTimeout(key, callback, duration);
    }
  };

  private dateRangeValidation = (parsedDate, props) => {
    const min = props.min ? DateHelper.getDate(props.min) : null;
    const max = props.max ? DateHelper.getDate(props.max) : null;

    // Set isValid to false when the date entered is not in min/max range.
    this.isValid = DateHelper.isInRange(parsedDate, (min || parsedDate), (max || parsedDate));

    // when isValid is false update validation message to appropriate message or "out of Range"
    if (!this.isValid) {
      this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfOutOfRange')}`;

      if (min && (parsedDate.getTime() < min.getTime()) && this.props.minValidationMessage) {
        this.validationMessage = ` ${this.props.minValidationMessage}`;
      }

      if (max && (parsedDate.getTime() > max.getTime()) && this.props.maxValidationMessage) {
        this.validationMessage = ` ${this.props.maxValidationMessage}`;
      }
    }
    else {
      this.validationMessage = '';
    }

    return {
      isValid: this.isValid,
      validationMessage: this.validationMessage
    };
  };

  private parseStringToDate = (selectedDate: string) => {
    // If selectedDate is empty or null, return null.
    if (!selectedDate) {
      return null;
    }

    // replacing all possible delimiters (like -, /,) in the string and making sure the date will parse after delimiters are removed
    // make sure English and French characters are also parsed
    const dateParts = selectedDate.match(/(\d+)|([a-zA-ZÀ-ÿ])+/g);
    let formattedInput = selectedDate;

    switch (dateParts && dateParts.length) {
      case 2:
      case 3:
        formattedInput = dateParts.reduce((reducedValue, value) => {
          if (value.length === 1) {
            return reducedValue + '0' + value;
          }
          else {
            return reducedValue + value;
          }
        }, '');

        break;

      default:
        formattedInput = selectedDate;
    }

    const userLocale = this.props.locale || LocaleHelper.getUserLocale().toLowerCase();
    let inputFormats = [];

    // Arrange the inputFormats based on the user locale. US locale users will be parsed with US formats along with all default formats, non-US users will be parsed with default formats along with US formats.
    if (userLocale.includes('us')) {
      inputFormats = dateFormats.us.concat(dateFormats.default);
    }
    else {
      inputFormats = dateFormats.default.concat(dateFormats.us);
    }

    // Finding matching format by evaluating all input regex formats.
    const matchingFormat = inputFormats.find((format) => format.regEx.test(formattedInput));

    // if the selectedDate matches any of the pattern, use its replace function to extract ISO date string.
    if (matchingFormat) {
      const selectedISODate = formattedInput.replace(matchingFormat.regEx, matchingFormat.ISOFormat);
      const parsedDate = DateHelper.getDate(selectedISODate);

      // return the parsed date only when the value is valid
      return parsedDate && !isNaN(parsedDate.getTime()) ? parsedDate : null;
    }

    return null;
  };

  private getDateState(selectedDate: Date | string, isOpen: boolean, props) {
    let parsedDate: Date;
    let formattedDate: string = null;
    let isoDate: string;
    let updatedVal;
    let timelessDateValue;

    if (selectedDate) {
      try {
        parsedDate = DateHelper.getDate(selectedDate);
        timelessDateValue = DateHelper.getIsoDate(selectedDate);

        // If the user entered any invalid date format, the parsedDate will be null.
        // If the parsedDate is not in Range of min/max then the parsedDate will be null.
        updatedVal = this.dateRangeValidation(parsedDate, props);

        if (parsedDate) {
          formattedDate = FormatHelper.formatDate(parsedDate, props.format, props.locale);
          isoDate = timelessDateValue.split('T')[0];
        }
      }
      catch (error) {
        console.error(` Input value: ${selectedDate} ${error} `);
        parsedDate = null;
        this.validationMessage = ` ${FormatHelper.formatMessage('@@mdfInvalidDate')}`;
        this.isValid = false;
      }
    }
    else {
      // If date field is removed after the out of range error, update the form state accordingly.
      this.isValid = true;
      this.validationMessage = '';
    }

    // Updating uncontrolled input value
    if (this.dateInput) {
      this.dateInput.value = (this.useDevicePicker ? isoDate : formattedDate);
    }

    return {
      isOpen,
      selectedDate: parsedDate,
      isValid: updatedVal ? updatedVal.isValid : this.isValid,
      validationMessage: updatedVal ? updatedVal.validationMessage : this.validationMessage,
      formattedValue: formattedDate,
      isoDate,
      focused: this.state?.focused || false
    };
  }

  private getPlaceholder(props) {
    // parse a dummy date(Nov 22, 3333) and replace with placeholders to get date pattern
    const dummyDate = new Date(Date.UTC(3333, 10, 22, 6, 44, 55));
    const formattedDate = FormatHelper.formatDate(dummyDate, props.format, props.locale);
    const userLocale = this.props.locale || LocaleHelper.getUserLocale().toLowerCase();
    const yearLocale = {
      'es-us': 'A',
      'fr-ca': 'A',
      'en-us': 'Y',
      'en-ca': 'Y'
    };
    const dayLocale = {
      'es-us': 'D',
      'fr-ca': 'J',
      'en-us': 'D',
      'en-ca': 'D'
    };
    // replace numbers with respective placeholder 3333 with 'YYYY', 11 with 'M' 22 with 'D'
    let datePattern = formattedDate.replace(new RegExp('1', 'g'), 'M')
      .replace(new RegExp('2', 'g'), (dayLocale[userLocale] || 'D'))
      .replace(new RegExp('3', 'g'), (yearLocale[userLocale] || 'Y'))
      .replace(new RegExp('4', 'g'), 'm')
      .replace(new RegExp('5', 'g'), 's')
      .replace(new RegExp('0|6', 'g'), 'h')
      .replace(new RegExp('AM|PM', 'g'), 'tt');

    // replace weekdays or months with respective placeholder Sun with 'ddd', 'Nov' with 'MMM' // this is used when format = long|short-date-time|
    const cultureFormat = FormatHelper.getLocaleFormats();

    cultureFormat.dateFormat.weeks.forEach((week) => {
      if (formattedDate.includes(week.short)) {
        datePattern = datePattern.replace(week.short, 'ddd');
      }

      if (formattedDate.includes(week.long)) {
        datePattern = datePattern.replace(week.long, 'dddd');
      }
    });

    cultureFormat.dateFormat.months.forEach((month) => {
      if (formattedDate.includes(month.short)) {
        datePattern = datePattern.replace(month.short, 'MMM');
      }

      if (formattedDate.includes(month.long)) {
        datePattern = datePattern.replace(month.long, 'MMMM');
      }
    });

    return datePattern;
  }

  private onCalendarSelect = (selectedDate: Date, autoClosePicker = false) => {
    const newState = this.getDateState(selectedDate, autoClosePicker, this.props);

    if (isEqual(newState, this.state)) {
      return;
    }

    this.setState(newState);

    // After selecting the Date set the focus back to the input.
    // This is missing in current DatePicker.
    if (this.state.isOpen && !newState.isOpen) {
      this.dateInput?.focus();
    }

    // Pass isValid and validationMessage to notify when the date entered is not in range.
    if (this.props.onChange) {
      this.props.onChange(newState.selectedDate, this.isValid, this.validationMessage, newState.isoDate);
    }
  };

  private handleDateInputFocus = (_event) => {
    this.setState({ isOpen: false });
  };

  private handleDatePickerBlur = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleBlur(focusMixinProps, event, (_focused) => {
      this.setState({ isOpen: false });
    });
  };

  // When the popup is opened navigate to month/year onTabbing set the focus back to the input
  navigateFocus = (event) => {
    if (this.state.isOpen && activeElement() !== ReactDOM.findDOMNode(this.dateInput) && event.key === 'Tab') {
      this.dateInput?.focus();
    }
  };

  isValidComponent = () => (
    {
      isValid: this.state.isValid,
      validationMessage: this.state.validationMessage,
      value: this.state.selectedDate
    }
  );

  onVisibleMonthChange = (date) => {
    this.calendarButton?.setActive?.();

    if (this.props.onVisibleMonthChange) {
      this.props.onVisibleMonthChange(date);
    }
  };

  private handleDatePickerFocus = (event) => {
    const focusMixinProps: IFocusMixinProps = {
      props: this.props,
      setTimeout: this.setTimeout,
      focused: this.state.focused,
      setFocused: (focused) => this.setState({ focused }),
      isMounted: this._isMounted
    };

    handleFocus(focusMixinProps, event);
  };

  private onDatePickerChange = (_value) => {
    const changeDate = DateHelper.getDate(this.dateInput.value);
    this.onCalendarSelect(changeDate);
  };

  private onDateChange = (e) => {
    const dateInput = this.parseStringToDate(e.target.value);
    this.onCalendarSelect(dateInput, this.state.isOpen);
  };

  /**
   * Handles the 'Clear' button above the iOS Date Picker.
   */
  private handleMobileOnChange = () => {
    this.setState({
      isoDate: '',
      formattedValue: ''
    });
  };

  // Added for new popup
  handleHide = () => {
    if (!this.state.isOpen) {
      return;
    }
    this.setState({
      isOpen: false
    });
  };

  handleClose = () => {
    this.handleHide();
    this.calendarButton.focus();
  };

  render() {
    const { required, id, disabled, readOnly, tabIndex, name, placeholder, onChange, size, autoFocus, ...passThroughProps } = this.props;
    const ariaLabel = resolveAriaProperty('DatePicker', 'aria-label', 'ariaLabel', this.props);
    const ariaInvalid = resolveAriaProperty('DatePicker', 'aria-invalid', 'ariaInvalid', this.props);
    const ariaLabelledby = resolveAriaProperty('DatePicker', 'aria-labelledby', 'ariaLabelledby', this.props);
    const ariaDescribedby = resolveAriaProperty('DatePicker', 'aria-describedby', 'ariaDescribedby', this.props);
    const disabledOrReadonly = disabled || readOnly;
    const placeholderText = placeholder ? placeholder : this.getPlaceholder(this.props);
    const classname = this.props.disabled ? 'vdl-date-time-picker-disabled' : '';
    const caption = this.props.caption ? this.props.caption : FormatHelper.formatMessage('@@ENTER_DATE_PICKER');

    let nodeId = id;

    if (!nodeId) {
      nodeId = generateId('datePicker');
    }

    const isADPUnified = !window['isLegacyAppShell'];

    // Date Picker select icon is marked aria-hidden so that AT users can only type input into the component to avoid confusion
    return (
      <div className={`vdl-date-time-picker vdl-date-time-picker--${size} dateTimePicker ${this.props.className} ${classname}`} onFocus={this.handleDatePickerFocus}
        ref={(divInput) => this.divDateInput = divInput} onBlur={this.handleDatePickerBlur}>
        {this.useDevicePicker &&
          <input
            aria-label={ariaLabel}
            aria-invalid={ariaInvalid}
            aria-required={required}
            aria-describedby={ariaDescribedby}
            aria-labelledby={ariaLabelledby}
            id={nodeId}
            placeholder={placeholderText}
            name={name}
            type="date"
            tabIndex={tabIndex}
            className={'mdf-mobile-picker__input'}
            data-date={this.state.formattedValue}
            aria-disabled={this.props.disabled}
            aria-readonly={this.props.readOnly}
            disabled={this.props.disabled}
            readOnly={this.props.readOnly}
            onFocus={this.handleDateInputFocus}
            min={this.min}
            max={this.max}
            ref={(input) => this.dateInput = input}
            // if the device isnt iOS, then use onChange event to get the new date
            onChange={!DeviceHelper.isIOS() ? this.onDatePickerChange : this.handleMobileOnChange}
            // if the device is iOS, then use onBlur event to get the new date. OnBlur is executed on the iOS date spinner control
            onBlur={DeviceHelper.isIOS() ? this.onDatePickerChange : null}
          />
        }
        {!this.useDevicePicker &&
          <input
            aria-invalid={ariaInvalid}
            aria-required={required}
            aria-describedby={ariaDescribedby}
            aria-labelledby={ariaLabelledby}
            id={nodeId}
            placeholder={placeholderText}
            name={name}
            type="text"
            tabIndex={tabIndex}
            className={`vdl-date-time-picker__input vdl-date-time-picker__input--${size}`}
            aria-disabled={this.props.disabled}
            aria-readonly={this.props.readOnly}
            disabled={this.props.disabled}
            autoFocus={!!autoFocus}
            readOnly={this.props.readOnly || this.props.disableEdit}
            onFocus={this.handleDateInputFocus}
            ref={(input) => this.dateInput = input}
            onBlur={this.onDateChange}
          />
        }
        <span className={'vdl-date-time-picker__select'}>
          <button
            tabIndex={0}
            type="button"
            className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
            disabled={disabledOrReadonly}
            aria-label={ariaLabel || FormatHelper.formatMessage('@@OPEN_CALENDAR_SELECTOR')}
            onClick={this.onPickerClick}
            ref={(button) => this.calendarButton = button}
          >
            {isADPUnified ? <SdfIcon icon="date" /> : <CalendarIcon />}
          </button>
        </span>
        {!this.useDevicePicker &&
          <Popup
            id={nodeId + '_cal'}
            rootClose={true}
            className="vdl-calendar-popup"
            onHide={this.handleHide}
            show={this.state.isOpen}
            placement={this.props.dropUp ? 'top-start' : 'bottom-start'}
            target={() => this.divDateInput}
            flip={true}
            isAriaExpanded={false}
            caption={caption}
          >
            <>
              <Calendar onVisibleMonthChange={this.onVisibleMonthChange} onChange={this.onCalendarSelect} {...passThroughProps} reset={this.state.isOpen} max={DateHelper.getDate(this.max)} min={DateHelper.getDate(this.min)} />
              <div className="vdl-date-picker-footer">
                <Button
                  buttonStyle="link"
                  className={`vdl-date-time-picker__select__picker vdl-date-time-picker__select__picker--${size}`}
                  onClick={this.handleClose}
                >
                  {FormatHelper.formatMessage('@@Close')}
                </Button>
              </div>
            </>

          </Popup>
        }
      </div>

    );
  }
}
