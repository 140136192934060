import { ComponentManager, FormatHelper, generateId, RenderHelper } from '@adp-wfn/mdf-core';
import classNames from 'classnames';
import { Cell, CellContent, CompoundCellContent, CompoundCellHeader } from './Cell';
import { IMDFSpreadSheetSortParams } from './IMDFSpreadSheetSortParams';
import React, { forwardRef, useEffect, useImperativeHandle, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { ResizeHandle } from './ResizeHandler';
import { Row } from './Row';
import { UseSingleTon } from './UseSingleTon';
import { TableUtils } from './TableUtils';
import { isEventBubbledThroughOverlay } from '../../util/DOMHelper';

// Notes
// - 1) Locked columns have to be at the beginning and adjacent to each other in the columns array.<br/>
// - 2) All columns must specify a width and in the case of compound columns each child must each specify a width. Child widths must add up to the parents width.<br/>
// - 3) Users need to wrap the adp components in a div in their view definition to avoid potential performance hit on Spreadsheet when rendering them.<br/>
// - 4) Keyboard navigation support is added for headers & body. Footers are considered static columns of text for now so will add tabbing through footer cells in future to support accessibility as well.<br/>
// - 5) Parameters that are send to any view in the column object are groupIndex (subheader group number)[passes -1 when no subheaders exist], index (row number), column (key of the column) and item object (represents each row in the data)
// - 6) Row Selection: 'selectRow' is the callback function which is required to enable row selection on MDFSpreadSheet. When selectRow is configured MDFSpreadSheet supports multiple row selection by default. If users want to limit the selection to single row then they need to set enableSingleRowSelect property on the MDFSpreadSheet.
//      When each row is selected components add isSelected key on particular data object for the row and assigns it with a true value. If the row is already selected and you are deselecting the row then isSelected key on data/row object will be set to false.
//      selectRow call back function passes the row index parameter back to application. Selected rows are highlighted with bluish background color for entire selected row as per UX.

export interface IMDFSpreadSheetColumn {
  // 'alignHeader [accepts values center, right] - to align header cell'
  alignHeader?: 'center' | 'right';

  // 'alignColumn [accepts values center, right] - to align data cells'
  alignColumn?: 'center' | 'right';

  // A string to apply custom class to required column
  className?: string;

  // Determines if the column is locked or unlocked
  locked: boolean;

  // Used to map actual data in row object to a column
  key: string;

  // Display field for header
  headerLabel: string;

  // Optional field to read the actual data from objects in case of columns with validation components as editors
  valueField?: string;

  // An optional property set on columns and accepts a json view as component definition. The cell with editor looks like a text field, click the cell to see the actual editor component. Example components could be used as editors Dropdowns, DatePicker, TimePicker, TextBox, NumberBox, Validated TextBox, Validated NumberBox etc (components which requires user input).
  editor?: object;

  // Sets width of column
  width: number;

  // Field is used for compound columns, accepts an array of column objects. It requires array of size 2. (limitation: Only two columns can be used as child columns for a compound column)
  children: IMDFSpreadSheetColumn[];

  // An optional property set on columns and accepts a json view as component definition. Example for view are button, radio button, link, icon, static customized text, checkbox, toggle button etc..(View does not need user to click on it to see, it renders whatever is configured in the view).
  view?: object;

  // An optional property set on columns of MDFGrid to resize columns by mouse handler drag. Default value is false.
  resize?: boolean;

  // An optional property set on columns of MDFGrid to enable sorting on data. Default value is false.
  sortable?: boolean;

  // Optional property can be set when sorting is enabled on column. Acceptable values for this field are "asc" / "desc".
  sortDirection?: 'asc' | 'desc';

  // Optional property can be set along with sort direction when sorting is enabled on column. This field is use ful when user tries to do multi column sorting.
  sortOrder?: number;

  // Property to hide / show a column in MDF Grid. Default is assumed to be false.
  hidden?: boolean;
}

export interface IMDFSpreadSheetRows {
  // Accepts view name (json view)
  subheader?: string;

  // Accepts a object with all the props that is expected to be applied to subheader view. Ex: class name
  subHeaderData?: object;

  // Accepts array of objects, each object contains a set of key value pairs to render Spreadsheet
  rows: object[];

  // Accepts array of objects, each object contains a set of key value pairs to render Spreadsheet. Used for rendering the tree structure with in the Grid
  children?: object[];
}

export interface IMDFSpreadSheetProps {
  // An id to assign to the MDFSpreadSheet.
  componentId?: string;

  tableStyle?: 'table' | 'list';

  // Required accessibility feature: Associates a data table caption with the data table. Captions help users to find a data table and understand what it’s about and decide if they want to read it.
  caption: string;

  // An optional property set on Spreadsheet, to let users drag and drop the rows in Spreadsheet.
  draggable?: boolean;

  // Defines the columns in the spreadsheet.
  columns: IMDFSpreadSheetColumn[];
  collectContainers?: (container: HTMLElement) => void;

  // Three and only three rows allowed as footers.
  footerRows?: any[];

  // The row number to display when the spreadsheet is initially displayed.
  initialRow?: number;

  isGrid?: boolean;

  model?: any;

  // Enables the selection of only 1 row at a time (multiple selections not allowed) when set to true.
  enableSingleRowSelect?: boolean;

  // Application defined function accepts the index of the selected row, select row function is a required property in order to highlight selected rows in the SpreadSheet(applicable for both single row selection and multi row selection).
  selectRow?: (rowIndex: number, groupNumber?: number, componentId?: string) => void;

  // Application defined function accepts the index of the selected column
  selectColumn?: (columnIndex: number, componentId?: string) => void;

  // The row number to display when the spreadsheet is initially displayed.
  noDataMessage?: string;

  // A function to call when the user scrolls to the last row, it requires two parameters startIndex and count.
  onFetchItems?: (startIndex: number, count: number, componentId?: string) => void;

  // A function to call as the user scrolls notifying the application of the row at the top of the spreadsheet to be used with initialRow  property so the application can return the user to where he left off after a navigation.
  onInitialRow?: (row: number, componentId?: string) => any;

  // A function to call when the user drags and drops the row, an object with the below properties will be sent to the applciation ('currentIndex', 'currentSubGroupIndex', 'targetSubGroupIndex', 'targetIndex')
  onChange?: (row: object, componentId?: string) => any;

  // A function to call when the user clicks on a column header. Callback recieves an array of IMDFSpreadSheetSortParams (index => index of the column, direction => asc | desc, order => sorting order (primary or secondary columns indicated with 1, 2, ...))
  onSort?: (meta: IMDFSpreadSheetSortParams[], componentId?: string) => void;

  // The event handler called when a tree item is clicked (triggers expand/collapse feature is only for MDFGrid)
  onExpand?: (item: object, rowIndex: number, isExpanded: boolean, componentId?: string) => void;

  // An array of arrays. Inner array contains an object with a cell's data
  rows: IMDFSpreadSheetRows[];

  // Identifier used to store widths of columns in local storage to reapply them when they revisit the page
  storageName?: string;

  // If application configures onFetchItems then this pageSize prop will be used as second parameter in that function i.e. count the number of records to fetch.
  pageSize?: number;

  // Optional property to set configure row height
  rowHeight?: number;

  // Optional property to set configure subheader row height
  subHeaderRowHeight?: number;

  // If true, the grid body will return to top scroll position on update
  scrollToTop: boolean;

  // Property for MDFGrid only
  enableWindowScroll?: boolean;

  // The number of rows that should be visible to the user.We use this rather than specifying a css height.
  visibleRows?: number;

  areEditorsVisible?: boolean;

  // If true, the grid body will scroll to newly added row to bring it to visiblity
  scrollToNewRow?: boolean;

  // Index of the newly added row to scroll to it when scrollToNewRow is set to true.
  newRowIndex?: { group: null, row: null };
}

interface IResizeHandlerMetaData {
  clientX: number;
  elementWidth: number;
  mousePressed: boolean;
  width: number;
  target: HTMLElement;
}

interface ISelectedCell {
  column: number;
  group: number;
  row: number;
  fakeIndex: number;
  isHeader?: boolean;
  isFooter?: boolean;
}

export const KeyboardConstants = {
  TAB_KEY: 9,
  ENTER_KEY: 13,
  ESC_KEY: 27,
  ARROW_LEFT_KEY: 37,
  ARROW_UP_KEY: 38,
  ARROW_RIGHT_KEY: 39,
  ARROW_DOWN_KEY: 40,
  SHIFT_KEY: 16,
  SPACE_KEY: 32
};

export const MDFSpreadSheet = forwardRef((props: IMDFSpreadSheetProps, ref) => {
  const component = useRef(null);
  const componentRows = props.rows ?? [];
  const { tableStyle = 'table', isGrid, draggable, selectColumn, selectRow, enableSingleRowSelect, enableWindowScroll = false, noDataMessage, storageName = '', rowHeight = 48, subHeaderRowHeight = 48, footerRows, caption, visibleRows, areEditorsVisible } = props;
  const componentClassName: string = isGrid ? 'mdf-grid-layout' : 'mdf-spreadsheet';
  const noData = componentRows.length === 0;
  const renderSubHeader = (componentRows.filter((item) => item?.subheader?.length)?.length > 0) && true;
  const renderTreeStructure = !!((componentRows.filter((item) => (item?.children && Array.isArray(item?.children))))?.length);
  const SCROLLBAR_THICKNESS = useRef(15);

  const lastScrollPosition = useRef(0);
  const sortPressed = useRef(true);
  let numberOfVisibleRows: number;
  const isLoadingMoreRows = useRef(false);
  const captionId = generateId('captionId');

  // state variables
  const [columns, setColumns] = useState(props.columns);
  const [width, setWidth] = useState(0);
  const [height, setHeight] = useState(0);
  const [editing, setEditing] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [renderTime, setRenderTime] = useState(0);
  const [gridId] = useState(props.componentId || generateId(componentClassName));

  const lockedColumns = () => props.columns.filter((item) => item.locked && !item.hidden);
  const unlockedColumns = () => props.columns.filter((item) => (!item.locked && !item.hidden));

  const lockedColumnsList = useRef([]);
  const unlockedColumnsList = useRef([]);
  lockedColumnsList.current = lockedColumns();
  unlockedColumnsList.current = unlockedColumns();
  const isCalculatedVerticalScroll = useRef(false);

  let scrollingDirection = 'down';

  let columnResizeMetaData: IResizeHandlerMetaData = {
    clientX: 0,
    elementWidth: 0,
    mousePressed: false,
    width: 0,
    target: null
  };

  const selectedCell = useRef<ISelectedCell>({
    column: -1,
    group: -1,
    row: -1,
    fakeIndex: -1,
    isFooter: false,
    isHeader: false
  });

  const setSelectedCellValue = (column, fakeIndex, row, group, isFooter, isHeader) => {
    selectedCell.current = { column: column, fakeIndex: fakeIndex, row: row, group: group, isFooter: isFooter, isHeader: isHeader };
  };

  const visibleColumnCount = useMemo(() => {
    return props.columns.filter((item) => item.hidden !== true)?.length;
  }, [props.columns]);

  props.columns.forEach((item, index) => {
    // inserting column index in column object, so that we can access columnindex even in case of not rendering few columns by setting hidden to true
    item['columnIndex'] = index;
  });

  // mimicking constructor like functionality and to do the below logic only once before rendering component
  UseSingleTon(() => {
    SCROLLBAR_THICKNESS.current = TableUtils.getScrollbarThickness();

    // Displaying error message in the developer tool console
    const sortableColumns = props.columns.filter((item) => (item.sortable && (item.sortDirection)));

    if ((sortableColumns.length > 1) && (sortableColumns.filter((item) => !item.sortOrder).length)) {
      console.error('Please set proper sort order on sortable columns to use multi column sort feature!!!');
    }

    // Print error message in case they try to do subheaders with locked columns
    if (lockedColumnsList.current?.length) {
      if (renderSubHeader) {
        console.error(`${(isGrid ? 'MDFGrid' : 'MDFSpreadSheet') + ': You cannot use subheader feature with locked columns!!!'}`);
      }
      else if (renderTreeStructure) {
        console.error(`${(isGrid ? 'MDFGrid' : 'MDFSpreadSheet') + ': You cannot use tree grid feature with locked columns!!!'}`);
      }
    }

    if (renderSubHeader && renderTreeStructure) {
      console.error(`${(isGrid ? 'MDFGrid' : 'MDFSpreadSheet') + ': You cannot use tree grid and subheader features together!!!'}`);
    }

    if (rowHeight && (rowHeight < 40)) {
      console.error(`${(isGrid ? 'MDFGrid' : 'MDFSpreadSheet') + ': Minimum value for row height is 40px'}`);
    }

    if (isGrid && props.tableStyle !== 'table' && props.tableStyle !== 'list') {
      console.error(`${'MDFGrid' + ': tableStyle accepts only either table or list. Default value is table'}`);
    }

    if (!isGrid && renderTreeStructure) {
      console.error(`${'MDFSpreadSheet' + ': Tree structure is not supported on this component, it is supported only on MDFGrid!!!'}`);
    }
  });

  const columnSortMetadata: any = useRef([]);
  // Reading the default sort props set on column and updating them to temporory local variable for further modificiations based on user activity
  if (columnSortMetadata.current.length === 0 || (columnSortMetadata.current?.length !== props.columns?.length)) {
    if (columnSortMetadata.current?.length !== props.columns?.length) {
      columnSortMetadata.current = [];
    }

    props.columns.forEach((item) => {
      const meta: IMDFSpreadSheetSortParams = {
        direction: item.sortDirection ? item.sortDirection : '',
        index: item['columnIndex'],
        order: item.sortOrder ? item.sortOrder : 0
      };

      columnSortMetadata.current.push(meta);
    });
  }

  const setEditMode = () => {
    if (!editing && !areEditorsVisible) {
      setEditing(true);
    }
    else {
      (document.activeElement as HTMLElement).blur();
      setRenderTime(Date.now());
    }
  };

  const elementByClassName = (classname: string) => {
    if (component?.current) {
      return component.current.querySelector(classname);
    }

    return undefined;
  };

  const calculateSubHeaderMetaData = () => {
    if (renderTreeStructure) {
      let treeGridRowCount = 0;

      componentRows.forEach((item) => {
        if (item['expanded']) {
          treeGridRowCount += item.children?.length;
        }

        treeGridRowCount += 1;
      });

      return treeGridRowCount;
    }
    else {
      const subHeaderMetaData: any = { renderSubHeaders: false, totalRows: 0, rowSets: [] };

      if (componentRows.filter((item) => item?.subheader?.length)?.length > 0) {
        subHeaderMetaData.renderSubHeaders = true;
      }

      if (subHeaderMetaData.renderSubHeaders) {
        componentRows.forEach((item) => {
          subHeaderMetaData.totalRows += item?.rows?.length + 1;
          subHeaderMetaData.rowSets.push(item?.rows?.length);
        });
      }

      return subHeaderMetaData;
    }
  };

  // HandleSort function gets called on click of any cell where sorting is enabled. This function does support sorting on single column / multiple columns
  // If the direction is set to desc on click it will switch to asc and on another click direction on column is set to '' indicating no sorting is being performed.
  // Single Sort - Click on any other sortable column clears sorting on previously clicked columns.
  // Multi Column Sort - Use cmnd (mac os)/ ctrl (windows) click to add sorting on additional columns.
  // Sort order & direction plays important role in multi column sorting and can be demonstrated using below example.
  // Array of IMDFTableSortParams is being passed back to application
  // Ex 1: Single column sort( Consider a spreadsheet with 5 columns for instance) : [{index: 0, direction: '', order: 0}, {index: 1, direction: '', order: 0}, {index: 2, direction: 'asc', order: 0}, {index: 3, direction: '', order: 0}, {index: 4, direction: '', order: 0}]
  // Ex 2: Multi column sort: [{index: 0, direction: 'asc', order: 1}, {index: 1, direction: 'desc', order: 2}, {index: 2, direction: 'asc', order: 0}, {index: 3, direction: 'desc', order: 3}, {index: 4, direction: '', order: 0}]
  // Sort order is displayed in the column header next to sort direction arrow when the order is not 0.
  // Sort props are not stored in state, it gets cleared when user navigates to different view.
  const handleSort = (event: React.MouseEvent<HTMLElement> | React.KeyboardEvent, index: any) => {
    // Prevent sorting function from getting executed on clik of resize div
    const element = event.target as Element;

    // this is to reset the selected cell value if user clicks on header cell
    setSelectedCellValue(-1, -1, -1, -1, false, false);

    if (element.className === 'cell-resize' || !sortPressed.current) {
      return false;
    }

    if (selectColumn) {
      selectColumn(index);
    }

    if (props.onSort && props.columns[index]?.sortable) {
      let addOrderColumns = [];

      const orderedColumns = columnSortMetadata.current.filter((item) => item.order > 0);
      const highestOrderColumn = orderedColumns.map((item) => item.order);
      const highestOrder = Math.max(...highestOrderColumn);

      // ctrl key is for windows
      // meta key serves as cmd key press in macos
      if (!event.ctrlKey && !event.metaKey) {
        columnSortMetadata.current.forEach((item) => {
          if (item.index !== index) {
            item.direction = '';
          }

          if (item.order > 0) {
            item.order = 0;
          }
        });
      }

      if (columnSortMetadata.current[index].direction === '') {
        columnSortMetadata.current[index].direction = 'desc';
      }
      else if (columnSortMetadata.current[index].direction === 'desc') {
        columnSortMetadata.current[index].direction = 'asc';
      }
      else if (columnSortMetadata.current[index].direction === 'asc') {
        columnSortMetadata.current[index].direction = '';
      }

      if (event.ctrlKey || event.metaKey) {
        if (highestOrder > 0) {
          if (columnSortMetadata.current[index].direction !== '' && columnSortMetadata.current[index].order) {
            if (columnSortMetadata.current[index].order !== highestOrder) {
              orderedColumns.filter((item) => item.order >= columnSortMetadata.current[index].order).forEach((item) => {
                if (item.index !== index) {
                  if (item.order) {
                    item.order = item.order - 1;
                  }
                }
                else {
                  item.order = highestOrder;
                }
              });
            }
          }
          else if (columnSortMetadata.current[index].direction !== '' && !columnSortMetadata.current[index].order) {
            columnSortMetadata.current[index].order = highestOrder + 1;
          }
          else if (columnSortMetadata.current[index].direction === '') {
            orderedColumns.filter((item) => item.order > columnSortMetadata.current[index].order).forEach((item) => item.order = item.order - 1);
            columnSortMetadata.current[index].order = 0;
          }
        }
        else {
          // Gets executed on first time cmnd / ctrl click
          addOrderColumns = columnSortMetadata.current.filter((item) => (item.direction !== ''));

          if (addOrderColumns.length === 2) {
            addOrderColumns.forEach((item) => {
              if (item.index !== index) {
                item.order = 1;
              }
              else {
                item.order = 2;
              }
            });
          }
        }
      }

      columnSortMetadata.current[index].index = index;
      // forcing the render on spreadsheet to updated sort configurations incase of no change in data after sorting
      if (!areEditorsVisible) {
        setEditMode();
      }

      if (!sortPressed.current) {
        sortPressed.current = true;
      }

      props.onSort(columnSortMetadata.current, props.componentId);
    }
    else {
      if (!areEditorsVisible && editing) {
        setEditing(false);
      }
    }
  };

  // Function to render text back in the spreadsheet when users click outside of spreadsheet. This listener gets added when component is mounted and gets removed when component unmounts.
  const resetSpreadsheetState = (event: any) => {
    const sheetCoordinates = component?.current.getBoundingClientRect() || {};

    if (!((sheetCoordinates.left < event.clientX && event.clientX < (sheetCoordinates.width + sheetCoordinates.left)) && (sheetCoordinates.top < event.clientY && event.clientY < (sheetCoordinates.top + sheetCoordinates.height)))) {
      if (!isEventBubbledThroughOverlay(event)) {
        setSelectedCellValue(-1, -1, -1, -1, false, false);

        if (!areEditorsVisible) {
          setEditing(false);
        }
      }
    }
  };

  const resizeObserver = new ResizeObserver(() => {
    if (component?.current) {
      setHeight(component.current.clientHeight - rowHeight);
      setWidth(component.current.clientWidth);

      if (!areEditorsVisible) {
        setEditing(false);
      }
    }
  });

  // Function to set focus on input / buttons / cells if editing mode is turned on
  const setFocusToEditor = (cell: HTMLElement, event?: React.KeyboardEvent) => {
    const focusableElements: any = cell.querySelectorAll('div.cell-content a, div.cell-content input, div.cell-content button, div.cell-content select, div.cell-content div[tabindex]:not([tabindex = "-1"]), div.cell-content sdf-icon-button, div.cell-content sdf-button');
    event?.preventDefault();
    event?.stopPropagation();

    let noFocusableElement = true;

    if (focusableElements[0]?.childNodes[0]?.nodeName === 'SDF-ICON') {
      if (focusableElements.length > 0) {
        // Focus the first focusable element in the cell.
        for (const element of focusableElements) {
          if (!element?.disabled) {
            noFocusableElement = false;
            element.setFocus();
            break;
          }
        }
      }
    }
    else {
      if (focusableElements.length > 0) {
        // Focus the first focusable element in the cell.
        for (const element of focusableElements) {
          if (!element?.disabled) {
            noFocusableElement = false;
            element.focus();
            break;
          }
        }
      }
    }

    if (noFocusableElement) {
      // If no focusable elements in the cell, focus the cell itself.
      cell.focus();
    }
  };

  const setRowHeights = () => {
    const lockedRows = component.current.querySelectorAll(`div.locked-body div.${componentClassName}-row`);
    const unLockedRows = component.current.querySelectorAll(`div.unlocked-body div.${componentClassName}-row`);

    // calculating the max height between unlocked row and locked row and setting the max height to rows in both the bodies.
    lockedRows.forEach((item, index) => {
      const maxRowHright = Math.max(unLockedRows[index].clientHeight, item.clientHeight);
      item.style['min-height'] = (item.clientHeight === maxRowHright ? maxRowHright : maxRowHright + 1) + 'px';
      unLockedRows[index].style['min-height'] = (unLockedRows[index].clientHeight === maxRowHright ? maxRowHright : maxRowHright + 1) + 'px';
    });
  };

  useEffect(() => {
    // Anything in here is fired on component mount.
    if (component) {
      if (storageName?.trim().length) {
        const columnDataStored: any = JSON.parse(localStorage.getItem(storageName));

        props.columns.forEach((item) => {
          columnDataStored?.forEach((column) => {
            if ((item.key === column.key) && (item.width !== column.width)) {
              if (item.children && item.children.length) {
                item.children.forEach((child) => {
                  child.width = (column.width) / (item.children.length);
                });
              }

              item.width = column.width;
            }
          });
        });
      }

      setWidth(component.current.clientWidth);

      if (props.onFetchItems && !props.rows?.length) {
        props.onFetchItems(0, props.pageSize || 100, props.componentId);
      }
    }

    if (props.initialRow && props.initialRow > 1 && props.initialRow < componentRows.length) {
      const unlocked = component.current?.querySelector('.unlocked-body')?.parentElement;

      if (unlocked) {
        const initialRow = ((props.initialRow - 1) * rowHeight) + props.initialRow;
        unlocked.scrollTo(0, initialRow);
      }
    }

    // A resize Observer is placed on the component to see for changes in width and height of component.
    resizeObserver.observe(component.current);

    return () => {
      // Anything in here is fired on component unmount.
      // Removing the click event on document which resets the spreadsheet editing state
      document.removeEventListener('click', resetSpreadsheetState);
      // remove Observer on component unmount.
      resizeObserver.disconnect();
    };
  }, []);

  useLayoutEffect(() => {
    // Adding click event on document to reset the spreadsheet editing state property to false
    document.addEventListener('click', resetSpreadsheetState);
  }, []);

  useEffect(() => {
    // Anything in here is fired on component mount. code is equivalent to componentDidUpdate
    let cell;

    // restore the focus after the components gets re-rendered
    if (selectedCell.current.row > -1 && selectedCell.current.fakeIndex > -1) {
      if (selectedCell.current.isFooter) {
        cell = component.current.querySelector(`div.${componentClassName}-row.footer[data-row="${selectedCell.current.row}"] div.${componentClassName}-cell[data-row="${selectedCell.current.row}"][data-fakeindex="${selectedCell.current.fakeIndex}"]`);
      }

      if (selectedCell.current.group === -1) {
        cell = component.current.querySelector(`div.${componentClassName}-cell[data-row="${selectedCell.current.row}"][data-fakeindex="${selectedCell.current.fakeIndex}"]`);
      }
      else if (selectedCell.current.group > -1) {
        cell = component.current.querySelector(`div.${componentClassName}-row[data-group="${selectedCell.current.group}"][data-row="${selectedCell.current.row}"] div.${componentClassName}-cell[data-row="${selectedCell.current.row}"][data-fakeindex="${selectedCell.current.fakeIndex}"]`);
      }

      if (cell) {
        setFocusToEditor(cell);
      }

      isLoadingMoreRows.current = false;
    }

    const unlocked = component.current.querySelector('.unlocked-body')?.parentElement;

    if (props.scrollToTop) {
      if (unlocked) {
        unlocked.scrollTop = 0;
      }
    }
  }, [editing, width, height, selectedRows, renderTime]);

  const scrollToNewRow = () => {
    let newRowPos: any;

    if (renderSubHeader) {
      newRowPos = component.current?.querySelector(`div.${componentClassName}-row[data-group="${props.newRowIndex.group}"][data-row="${props.newRowIndex.row}"]`);
    }
    else {
      newRowPos = component.current?.querySelector(`div.unlocked-body div.${componentClassName}-row[data-row='${props.newRowIndex.row}']`);
    }

    component.current.querySelector('.unlocked-body')?.parentElement.scrollTo(0, newRowPos?.offsetTop);
  };

  useEffect(() => {
    isLoadingMoreRows.current = false;

    if (component.current && height !== (component.current.clientHeight - rowHeight)) {
      setHeight(component.current.clientHeight - rowHeight);
    }

    if (component.current) {
      isCalculatedVerticalScroll.current = ((component.current.querySelector('.scroll-container')?.scrollHeight - component.current.querySelector('.scroll-container')?.clientHeight) > 0);
    }

    if (props.scrollToNewRow) {
      scrollToNewRow();
    }

    // calling setRowHeights function to sync up the row heights between locked and unlocked bodies rows of the grid. This is needed only when we have locked columns in the Grid / SpreadSheet
    if (lockedColumnsList.current?.length) {
      setRowHeights();
    }

  }, [componentRows]);

  const findNumberByDataAttribute = (element: HTMLElement, attribute: string): number => {
    let el = element;

    while (el !== component.current && el) {
      for (let i = 0; i < el.attributes.length; i++) {
        if (el.attributes.item(i).name === attribute) {
          return parseInt(el.attributes.item(i).value, 10);
        }
      }

      el = el.parentElement;
    }

    return -1;
  };

  const autoScrollToAdjustView = (direction?: string, element?: HTMLElement): void => {
    if (element) {
      const lockedBody = elementByClassName('.locked-body');
      const lockedWidth = lockedBody.getBoundingClientRect().width;
      const unlockedBody = elementByClassName('.unlocked-body').parentElement;
      const unlockedWidth = unlockedBody.getBoundingClientRect().width;
      const unlockedHeight = unlockedBody.getBoundingClientRect().height;
      const elementLeft = element.getBoundingClientRect().left;
      const elementWidth = element.getBoundingClientRect().width;
      const elementTop = element.getBoundingClientRect().top;

      if (unlockedBody.scrollBy) {
        if (direction === 'left' && elementLeft < lockedWidth) {
          unlockedBody.scrollBy(-(elementWidth + 5), 0);
        }
      }
      else {
        // IE11 ugh...
        if (direction === 'left' && elementLeft < lockedWidth) {
          unlockedBody.scrollLeft = unlockedBody.scrollLeft - (elementWidth + 5);
        }
      }

      if (unlockedBody.scrollBy) {
        if (direction === 'right' && elementLeft + elementWidth > unlockedWidth) {
          unlockedBody.scrollBy(unlockedBody.scrollLeft + (elementLeft + elementWidth + 5), 0);
        }
      }
      else {
        // IE11 ugh...
        if (direction === 'right' && elementLeft + elementWidth > unlockedWidth) {
          unlockedBody.scrollLeft = unlockedBody.scrollLeft + (elementLeft + elementWidth + 5);
        }
      }

      if (unlockedBody.scrollBy) {
        if (direction === 'up' && elementTop < (unlockedBody.scrollHeight - unlockedBody.scrollTop)) {
          unlockedBody.scrollBy(0, -rowHeight);
        }
      }
      else {
        // IE11 ugh...
        if (direction === 'up' && elementTop < (unlockedBody.scrollHeight - unlockedBody.scrollTop)) {
          unlockedBody.scrollTop = unlockedBody.scrollTop - rowHeight;
        }
      }

      if (unlockedBody.scrollBy) {
        if (direction === 'down' && elementTop > unlockedHeight) {
          const rowNumber = findNumberByDataAttribute(element, 'data-row');
          unlockedBody.scrollBy(0, rowHeight + rowNumber);
        }
      }
      else {
        // IE11 ugh...
        if (direction === 'down' && elementTop > unlockedHeight) {
          const rowNumber = findNumberByDataAttribute(element, 'data-row');
          unlockedBody.scrollTop = unlockedBody.scrollTop + rowHeight + rowNumber;
        }
      }
    }
  };

  const autoScrollToAdjustHeaderView = (direction?: string, element?: HTMLElement): void => {
    if (element) {
      const lockedBody = elementByClassName('.locked-body');
      const lockedWidth = lockedBody.getBoundingClientRect().width;
      const unlockedBody = elementByClassName('.unlocked-body').parentElement;
      const unlockedWidth = unlockedBody.getBoundingClientRect().width;
      const elementWidth = element.getBoundingClientRect().width;
      const componentLeft = component.current.getBoundingClientRect().left;
      const elementLeft = element.getBoundingClientRect().left;

      if (unlockedBody.scrollBy) {
        if (direction === 'left' && unlockedBody.scrollLeft > 0) {
          if (elementLeft < lockedWidth) {
            unlockedBody.scrollTo(-(elementWidth + 5), 0);
          }
          else if (elementLeft < (lockedWidth + elementWidth)) {
            unlockedBody.scrollBy(-(elementWidth + 5), 0);
          }
          else if (unlockedBody.scrollLeft - elementLeft > 0) {
            if (unlockedBody.scrollLeft - elementLeft < elementWidth) {
              unlockedBody.scrollBy(-(elementWidth + 5), 0);
            }
            else if (unlockedBody.scrollWidth - unlockedBody.scrollLeft > elementLeft || (unlockedBody.scrollWidth - unlockedBody.scrollLeft) >= unlockedWidth) {
              unlockedBody.scrollBy(-(elementWidth + 5), 0);
            }
          }
        }
        else if (direction === 'right') {
          if ((elementLeft - lockedWidth + elementWidth) > unlockedWidth) {
            unlockedBody.scrollBy((elementWidth + 5), 0);
          }
          else {
            if (unlockedBody.scrollLeft > 0 && (elementLeft - lockedWidth) > 0) {
              if ((elementLeft - lockedWidth + elementWidth) > unlockedBody.scrollLeft) {
                unlockedBody.scrollBy((elementWidth + 5), 0);
              }
              else {
                if (elementLeft - lockedWidth < elementWidth) {
                  unlockedBody.scrollTo(elementLeft - componentLeft - lockedWidth + 5, 0);
                }
                else {
                  unlockedBody.scrollBy((elementWidth + 5), 0);
                }
              }
            }
          }
        }
      }
      else {
        // IE11 ugh...
        if (direction === 'left' && unlockedBody.scrollLeft > 0) {
          if (elementLeft < lockedWidth) {
            unlockedBody.scrollLeft = unlockedBody.scrollLeft - (elementWidth + 5);
          }
          else if (elementLeft < (lockedWidth + elementWidth)) {
            unlockedBody.scrollLeft = unlockedBody.scrollLeft - (elementWidth + 5);
          }
          else if (unlockedBody.scrollLeft - elementLeft > 0) {
            if ((unlockedBody.scrollLeft - elementLeft < elementWidth) || (unlockedBody.scrollWidth - unlockedBody.scrollLeft > elementLeft) || (unlockedBody.scrollWidth - unlockedBody.scrollLeft) >= unlockedWidth) {
              unlockedBody.scrollLeft = unlockedBody.scrollLeft - (elementWidth + 5);
            }
          }
        }
        else if (direction === 'right') {
          if ((elementLeft - lockedWidth + elementWidth) > unlockedWidth) {
            unlockedBody.scrollLeft = unlockedBody.scrollLeft + (elementWidth + 5);
          }
          else {
            if (unlockedBody.scrollLeft > 0 && (elementLeft - lockedWidth) > 0) {
              if ((elementLeft - lockedWidth + elementWidth) > unlockedBody.scrollLeft) {
                unlockedBody.scrollLeft = unlockedBody.scrollLeft + (elementWidth + 5);
              }
              else {
                if (elementLeft - lockedWidth < elementWidth) {
                  unlockedBody.scrollLeft = elementLeft - componentLeft - lockedWidth + 5;
                }
                else {
                  unlockedBody.scrollLeft = unlockedBody.scrollLeft + (elementWidth + 5);
                }
              }
            }
          }
        }
      }
    }
  };

  const getSortClassName = (index: number, accessibilityDirection?: boolean) => {
    if (columnSortMetadata.current[index].direction === 'asc') {
      return accessibilityDirection ? 'ascending' : 'fa fa-sort sort-arrow fa-sort-up';
    }
    else if (columnSortMetadata.current[index].direction === 'desc') {
      return accessibilityDirection ? 'descending' : 'fa fa-sort sort-arrow fa-sort-down';
    }
    else {
      return accessibilityDirection ? 'none' : 'fa fa-sort fa-plus sort-arrow';
    }
  };

  const fetchItems = () => {
    isLoadingMoreRows.current = true;
    props.onFetchItems(props.rows?.length ?? 0, props.pageSize || 100, props.componentId);
  };

  const fireScrollFunctions = (scrollHeight, scrollTop) => {
    if (!isLoadingMoreRows.current) {
      if (!enableWindowScroll) {
        if (((scrollHeight - height) - scrollTop) < rowHeight) {
          fetchItems();
        }
      }
      else {
        scrollHeight = component.current?.offsetHeight;
        if ((scrollHeight - scrollTop) < window.innerHeight) {
          fetchItems();
        }
      }
    }
  };

  useImperativeHandle(ref, () => ({ fireScrollFunctions, fetchItems }));

  const handleUnlockedScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (enableWindowScroll) {
      const unlockedHeader = elementByClassName('.unlocked-header');

      if (unlockedHeader.scrollTo) {
        unlockedHeader.scrollTo(event.currentTarget.scrollLeft, 0);
      }
      else {
        // IE11 ugh...
        unlockedHeader.scrollLeft = event.currentTarget.scrollLeft;
      }
    }
    else {
      if (event.currentTarget.scrollTop >= lastScrollPosition.current) {
        scrollingDirection = 'down';
      }
      else {
        scrollingDirection = 'up';
      }

      const unlockedHeader = elementByClassName('.unlocked-header');

      if (unlockedHeader.scrollTo) {
        unlockedHeader.scrollTo(event.currentTarget.scrollLeft, 0);
      }
      else {
        // IE11 ugh...
        unlockedHeader.scrollLeft = event.currentTarget.scrollLeft;
      }

      const unlockedFooter = elementByClassName('.unlocked-footer');

      if (unlockedFooter) {
        if (unlockedFooter.scrollTo) {
          unlockedFooter.scrollTo(event.currentTarget.scrollLeft, 0);
        }
        else {
          // IE11 ugh...
          unlockedFooter.scrollLeft = event.currentTarget.scrollLeft;
        }
      }

      const locked = elementByClassName('.locked-body');

      if (locked.scrollTo) {
        locked.scrollTo(0, event.currentTarget.scrollTop);
      }
      else {
        // IE11 ugh...
        locked.scrollTop = event.currentTarget.scrollTop;
      }

      if (scrollingDirection === 'down' && props.onFetchItems) {
        fireScrollFunctions(event.currentTarget.scrollHeight, event.currentTarget.scrollTop);
      }

      lastScrollPosition.current = event.currentTarget.scrollTop;
    }

    if (props.onInitialRow) {
      props.onInitialRow(Math.floor(event.currentTarget.scrollTop / rowHeight) + 1, props.componentId);
    }
  };

  const handleFooterRowsScroll = (event: React.UIEvent<HTMLDivElement>) => {
    const unlockedBody = elementByClassName('.unlocked-body').parentElement;

    if (unlockedBody) {
      if (unlockedBody.scrollTo) {
        unlockedBody.scrollTo(event.currentTarget.scrollLeft, 0);
      }
      else {
        // IE11 ugh...
        unlockedBody.scrollLeft = event.currentTarget.scrollLeft;
      }
    }
  };

  const handleColumnResize = (colIndex: number, columnWidth: number) => {
    const columnWidths = [...columns];

    if (columnWidths[colIndex].width !== columnWidth) {
      columnWidths[colIndex].width = columnWidth;

      if (columnWidths[colIndex].children && columnWidths[colIndex].children.length) {
        columnWidths[colIndex].children.forEach((item) => {
          item.width = columnWidth / 2;
        });
      }
    }

    setColumns(columnWidths);

    if (!areEditorsVisible) {
      setEditing(false);
    }

    if (storageName?.trim().length) {
      localStorage.setItem(storageName, JSON.stringify(props.columns.map((item) => ({ key: item.key, width: item.width }))));
    }
  };

  const highlightRow = (groupNumber: any, rowNumber: any, add: boolean) => {
    if (component?.current) {
      let highLightRows;

      if (groupNumber) {
        highLightRows = component.current.querySelectorAll('div[class*="-body"]' + ' div.' + componentClassName + '-row[data-row="' + rowNumber + '"][data-group="' + groupNumber + '"]');
      }
      else {
        highLightRows = component.current.querySelectorAll('div[class*="-body"]' + ' div.' + componentClassName + '-row[data-row="' + rowNumber + '"]');
      }

      highLightRows.forEach((row) => {
        if (add) {
          row.classList.add('row-highlight');
        }
        else {
          row.classList.remove('row-highlight');
        }
      });
    }
  };

  const onMouseMove = (e: any) => {
    if (columnResizeMetaData.mousePressed) {
      const delta = (e.clientX - columnResizeMetaData.clientX);
      columnResizeMetaData.width = ((columnResizeMetaData.elementWidth + delta) < 100 ? 100 : (columnResizeMetaData.elementWidth + delta));
      columnResizeMetaData.target.parentElement.style.width = columnResizeMetaData.width + 'px';
    }
  };

  const onMouseUp = (e: any) => {
    e.preventDefault();

    if (columnResizeMetaData.mousePressed) {
      document.removeEventListener('mousemove', onMouseMove);

      if (columnResizeMetaData.target && columnResizeMetaData.width) {
        handleColumnResize(findNumberByDataAttribute(columnResizeMetaData.target.parentElement, 'data-column'), columnResizeMetaData.width);
      }

      columnResizeMetaData = {
        clientX: 0,
        elementWidth: 0,
        mousePressed: false,
        width: 0,
        target: null
      };
      sortPressed.current = false;
    }
    else {
      if (!sortPressed.current) {
        sortPressed.current = true;
      }
    }
  };

  const onMouseDown = (event: React.MouseEvent<HTMLElement>) => {
    columnResizeMetaData.target = event.currentTarget as HTMLElement;
    columnResizeMetaData.clientX = event.clientX;

    if (columnResizeMetaData.target) {
      columnResizeMetaData.elementWidth = columnResizeMetaData.target.parentElement.offsetWidth;
    }

    columnResizeMetaData.mousePressed = true;
    document.addEventListener('mousemove', onMouseMove);
    document.addEventListener('mouseup', onMouseUp);
  };

  const onMouseEnter = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget as HTMLElement;
    const rowNumber = target.getAttribute('data-row');
    // rows with subheader
    const groupNumber = target.getAttribute('data-group');

    highlightRow(groupNumber, rowNumber, true);
  };

  const onMouseLeave = (event: React.MouseEvent<HTMLDivElement>) => {
    const target = event.currentTarget as HTMLElement;
    const rowNumber = target.getAttribute('data-row');
    // rows with subheader
    const groupNumber = target.getAttribute('data-group');

    highlightRow(groupNumber, rowNumber, false);
  };

  const unSetEditMode = () => {
    if (!areEditorsVisible) {
      if (editing) {
        // setTimeout is needed to update data on Spreadsheet cell if the editors are TextBox or ValidatedTextBox. This is for a special scenario where user edited some data on textbox and trying to tabout to focus on next cell.
        (document.activeElement as HTMLElement).blur();
        setEditing(false);
      }
    }
  };

  const handleRowSelect = (groupNumber, rowNumber) => {
    if (groupNumber > -1) {
      selectedRows[groupNumber] = selectedRows[groupNumber] || { rows: [] };
      selectedRows[groupNumber].rows[rowNumber] = selectedRows[groupNumber].rows[rowNumber] || {};

      // If only one row selection is allowed at a time, then deselect the previously selected row
      // enableSingleRowSelect is not included in the component by default
      if (enableSingleRowSelect) {
        selectedRows.forEach((subHeaderGroup) => {
          if (subHeaderGroup) {
            subHeaderGroup.rows.forEach((row) => {
              if (row) {
                row.isSelected = false;
              }
            });
          }
        });
      }

      selectedRows[groupNumber].rows[rowNumber].isSelected = !selectedRows[groupNumber].rows[rowNumber].isSelected;
    }
    else {
      selectedRows[rowNumber] = selectedRows[rowNumber] || {};

      // If only one row selection is allowed at a time, then deselect the previously selected row
      if (enableSingleRowSelect) {
        selectedRows.forEach((row) => {
          if (row) {
            row.isSelected = false;
          }
        });
      }

      selectedRows[rowNumber].isSelected = !selectedRows[rowNumber].isSelected;
    }

    setSelectedRows([...selectedRows]);
    selectRow(rowNumber, groupNumber);
  };

  const handleCellOnClick = (event: React.MouseEvent<HTMLDivElement>, isEditorActive) => {
    const columnNumber = findNumberByDataAttribute(event.currentTarget, 'data-column');
    const rowNumber = findNumberByDataAttribute(event.currentTarget, 'data-row');
    const groupNumber = findNumberByDataAttribute(event.currentTarget, 'data-group');
    const fakeIndex = findNumberByDataAttribute(event.currentTarget, 'data-fakeindex');

    if (selectRow) {
      handleRowSelect(groupNumber, rowNumber);
    }

    if (!selectedCell.current.isFooter && !areEditorsVisible && !isEditorActive) {
      if ((selectedCell.current.row !== rowNumber || selectedCell.current.column !== columnNumber || (groupNumber > -1 && selectedCell.current.group !== groupNumber)) || !editing) {
        if (columnNumber > -1) {
          if ((props.columns[columnNumber]?.children?.length) && (props.columns[columnNumber].children[0].editor || props.columns[columnNumber].children[1].editor)) {
            setEditMode();
          }
          else if (props.columns[columnNumber].editor) {
            setEditMode();
          }
          else {
            unSetEditMode();
          }
        }
        else {
          unSetEditMode();
        }
      }
    }

    setSelectedCellValue(columnNumber, fakeIndex, rowNumber, groupNumber, false, false);

    if (!editing && !isEditorActive && !areEditorsVisible) {
      event.currentTarget.focus();
    }
  };

  const handleFooterCellClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const columnNumber = findNumberByDataAttribute(event.currentTarget, 'data-column');
    const rowNumber = findNumberByDataAttribute(event.currentTarget, 'data-row');
    const groupNumber = findNumberByDataAttribute(event.currentTarget, 'data-group');
    const fakeIndex = findNumberByDataAttribute(event.currentTarget, 'data-fakeindex');

    setSelectedCellValue(columnNumber, fakeIndex, rowNumber, groupNumber, true, false);

    if (!editing) {
      event.currentTarget.focus();
    }
    else {
      if (!areEditorsVisible) {
        unSetEditMode();
      }
    }
  };

  // Handle the keyboard navigation using arrow keys and tab in the header.
  const handleHeaderKeyDown = (event: React.KeyboardEvent, index: any) => {
    let which = event.which ? event.which : event.keyCode;
    const isShiftDown = event.getModifierState('Shift');
    let continueHeaderNavigation = true;
    let element: HTMLElement;

    // Handling the event for keyboard assisted sort
    if (which === KeyboardConstants.ENTER_KEY) {
      handleSort(event, index);
    }

    // Focus does not move by pressing UP_ARROW as it is already on the top of the Grid and also on pressing shift key alone and
    // When space key is pressed for selecting a checkbox.
    if (which === KeyboardConstants.ARROW_UP_KEY || (isShiftDown && which === KeyboardConstants.SHIFT_KEY) || (which === KeyboardConstants.SPACE_KEY)) {
      return;
    }

    if (which === KeyboardConstants.TAB_KEY && isShiftDown) {
      which = KeyboardConstants.ARROW_LEFT_KEY;
    }
    else if (which === KeyboardConstants.TAB_KEY || which === KeyboardConstants.ARROW_RIGHT_KEY) {
      which = KeyboardConstants.ARROW_RIGHT_KEY;

      // Query and set the focus to first cell in the body
      // 'data-fakeindex' gives the index of columns that are present on DOM
      if (findNumberByDataAttribute(event.currentTarget as HTMLElement, 'data-fakeindex') === (visibleColumnCount - 1)) {
        if (componentRows.length) {
          element = component.current.querySelector((lockedColumnsList.current.length ? '.locked-body' : '.unlocked-body') + ' div.' + componentClassName + '-row:not(.subheader) div.' + componentClassName + '-cell');

          if (element) {
            setSelectedCellValue(findNumberByDataAttribute(element, 'data-column'), findNumberByDataAttribute(element, 'data-fakeindex'), findNumberByDataAttribute(element, 'data-row'), findNumberByDataAttribute(element, 'data-group'), false, false);
          }
        }
        else {
          setSelectedCellValue(-1, -1, -1, -1, false, false);
          return;
        }

        continueHeaderNavigation = false;
      }
    }

    if (continueHeaderNavigation) {
      setSelectedCellValue(findNumberByDataAttribute(event.currentTarget as HTMLElement, 'data-column'), findNumberByDataAttribute(event.currentTarget as HTMLElement, 'data-fakeindex'), (which === KeyboardConstants.ARROW_DOWN_KEY) ? 0 : -1, -1, false, (which !== KeyboardConstants.ARROW_DOWN_KEY));
    }

    // set the element value to equivalent column in the body in first row if user presses ARROW_DOWN from the header cell
    if (which === KeyboardConstants.ARROW_DOWN_KEY) {
      selectedCell.current.group = renderSubHeader ? 0 : -1;
      element = component.current.querySelector((props.columns[selectedCell.current.column].locked ? '.locked-body' : '.unlocked-body') + ' div[data-row="' + selectedCell.current.row + '"].' + componentClassName + '-row:not(.subheader) div[data-fakeindex="' + selectedCell.current.fakeIndex + '"].' + componentClassName + '-cell');
      continueHeaderNavigation = false;
    }

    if (continueHeaderNavigation) {
      let direction = '';

      switch (which) {
        case KeyboardConstants.ARROW_RIGHT_KEY:
          if (selectedCell.current.fakeIndex < (visibleColumnCount - 1)) {
            selectedCell.current.fakeIndex = selectedCell.current.fakeIndex + 1;
          }

          direction = 'right';
          break;

        case KeyboardConstants.ARROW_LEFT_KEY:
          if (selectedCell.current.fakeIndex > 0) {
            selectedCell.current.fakeIndex = selectedCell.current.fakeIndex - 1;
          }

          direction = 'left';
          break;
      }

      const header = selectedCell.current.fakeIndex <= (lockedColumnsList.current?.length - 1) ? 'locked-header' : 'unlocked-header';

      element = component.current.querySelector(`div.${header} div.${componentClassName}-row div.${componentClassName}-cell[data-fakeindex='${selectedCell.current.fakeIndex}']`);

      if (!element) {
        // check if it is a compound cell in the header
        element = component.current.querySelector(`div.${header} div.${componentClassName}-row div.${componentClassName}-compound-cell-header[data-fakeindex='${selectedCell.current.fakeIndex}']`);
      }

      if (element) {
        const isShiftTab = isShiftDown && ((event.which || event.keyCode) === KeyboardConstants.TAB_KEY);

        if (isShiftTab && (element === document.activeElement || element.contains?.(document.activeElement))) {
          return;
        }

        selectedCell.current.column = findNumberByDataAttribute(element, 'data-column');
        autoScrollToAdjustHeaderView(direction, element);
        setFocusToEditor(element, event);
      }
    }
    else {
      if (props.columns[selectedCell.current.column]?.editor) {
        setEditMode();
      }
      else {
        setFocusToEditor(element, event);
      }
    }

    event.stopPropagation();
    event.preventDefault();
  };

  const hasNextFocusElementsWithInCell = (event: React.KeyboardEvent) => {
    const target: any = event.target;
    const targetEle = event.currentTarget;
    const focusableEls = targetEle.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="tel"]:not([disabled]), select:not([disabled]), sdf-icon-button:not([disabled]), sdf-button:not([disabled]), div[role="combobox"]:not([disabled])');

    // Removed "=" to make the focus move inside an overlayTrigger by pressing TAB_KEY as the focusable element's length is 0.
    if (focusableEls?.length < 1) {
      return false;
    }
    else {
      let hasNextFocusElement = true;

      for (let index = 0; index < focusableEls.length; index++) {
        if (focusableEls[index] === target && index === focusableEls.length - 1) {
          hasNextFocusElement = false;
          break;
        }
      }

      return hasNextFocusElement;
    }
  };

  const hasPreviousFocusElementsWithInCell = (event: React.KeyboardEvent) => {
    const target: any = event.target;
    const targetEle = event.currentTarget;
    const focusableEls = targetEle.querySelectorAll('a[href]:not([disabled]), button:not([disabled]), textarea:not([disabled]), input[type="text"]:not([disabled]), input[type="radio"]:not([disabled]), input[type="checkbox"]:not([disabled]), input[type="tel"]:not([disabled]), select:not([disabled]), sdf-icon-button:not([disabled]), sdf-button:not([disabled]), div[role="combobox"]:not([disabled])');

    // Removed "=" to make the focus move back and forth while inside an overlayTrigger by pressing Shift + TAB_KEY.
    if (focusableEls?.length < 1) {
      return false;
    }
    else {
      let hasPreviousFocusElement = true;

      for (let index = 0; index < focusableEls.length; index++) {
        if (focusableEls[index] === target && index === 0) {
          hasPreviousFocusElement = false;
          break;
        }
      }

      return hasPreviousFocusElement;
    }
  };

  const handleKeyDown = (event: React.KeyboardEvent) => {
    const target: any = event.target;
    let targetEle;
    let proceed = false;
    let which = event.which ? event.which : event.keyCode;
    const isShiftDown = event.getModifierState('Shift');
    let proceedDomNavigation = false;

    if ((which === KeyboardConstants.TAB_KEY) || (which === KeyboardConstants.ARROW_DOWN_KEY) || (which === KeyboardConstants.ARROW_LEFT_KEY) || (which === KeyboardConstants.ARROW_RIGHT_KEY) || (which === KeyboardConstants.ARROW_UP_KEY) || (which === KeyboardConstants.ENTER_KEY) || (which === KeyboardConstants.ESC_KEY)) {
      if (!(target.classList.contains(componentClassName + '-cell') && target.tagName === 'DIV')) {
        targetEle = event.currentTarget;

        // Do not navigate(focus) to the next cell, if the cell has multiple focus elements in both edit and view mode
        if ((which === KeyboardConstants.TAB_KEY) && ((!isShiftDown && hasNextFocusElementsWithInCell(event)) || isShiftDown && hasPreviousFocusElementsWithInCell(event))) {
          proceed = false;
        }
        else {
          setSelectedCellValue(findNumberByDataAttribute(targetEle, 'data-column'), findNumberByDataAttribute(targetEle, 'data-fakeindex'), findNumberByDataAttribute(targetEle, 'data-row'), findNumberByDataAttribute(targetEle, 'data-group'), !!targetEle.parentElement.querySelector('div.' + componentClassName + '-row.footer'), false);

          if (editing && ((which === KeyboardConstants.ARROW_DOWN_KEY) || (which === KeyboardConstants.ARROW_LEFT_KEY) || (which === KeyboardConstants.ARROW_RIGHT_KEY) || (which === KeyboardConstants.ARROW_UP_KEY) || (which === KeyboardConstants.ENTER_KEY))) {
            proceed = false;
          }
          else {
            // when areEditorsVisible = true property is set, editors are always open
            // when the cell is an editor don't navigate to next cell with arrow keys.
            proceed = !((which === KeyboardConstants.ARROW_LEFT_KEY || which === KeyboardConstants.ARROW_RIGHT_KEY || which === KeyboardConstants.ARROW_DOWN_KEY || which === KeyboardConstants.ARROW_UP_KEY) && (areEditorsVisible && props.columns[selectedCell.current.column].editor));
          }
        }
      }
      else if (target.classList.contains(componentClassName + '-cell') && target.tagName === 'DIV') {
        proceed = true;
      }
    }

    if (proceed) {
      let direction = '';

      // If the shift key is down then tab will move us to the left.
      if (which === KeyboardConstants.TAB_KEY && isShiftDown) {
        which = KeyboardConstants.ARROW_LEFT_KEY;
      }

      switch (which) {
        case KeyboardConstants.ARROW_LEFT_KEY:
          if (selectedCell.current.fakeIndex === 0) {
            if (selectedCell.current.row > 0) {
              selectedCell.current.row -= 1;
              selectedCell.current.fakeIndex = visibleColumnCount - 1;
            }
            else {
              if (renderSubHeader) {
                if (selectedCell.current.group > 0) {
                  selectedCell.current.group -= 1;
                  selectedCell.current.row = componentRows[selectedCell.current.group].rows?.length - 1;
                  selectedCell.current.fakeIndex = visibleColumnCount - 1;
                }
                else {
                  proceedDomNavigation = true;
                }
              }
              else {
                if (!isGrid && footerRows && selectedCell.current.isFooter) {
                  setSelectedCellValue(-1, visibleColumnCount - 1, componentRows?.length - 1, -1, false, false);
                }
                else {
                  proceedDomNavigation = true;
                }
              }
            }
            direction = 'up';
          }
          else {
            selectedCell.current.fakeIndex -= 1;
            direction = 'left';
          }

          break;

        case KeyboardConstants.ARROW_RIGHT_KEY:
        case KeyboardConstants.TAB_KEY:
          if (selectedCell.current.fakeIndex === (visibleColumnCount - 1)) {
            if (renderSubHeader) {
              if (selectedCell.current.row < (componentRows[selectedCell.current.group].rows.length - 1)) {
                selectedCell.current.row = selectedCell.current.row + 1;
              }
              else if (selectedCell.current.group < componentRows.length) {
                selectedCell.current.group += 1;
                selectedCell.current.row = 0;
              }
              else if ((selectedCell.current.group === componentRows.length) && (selectedCell.current.row === componentRows[selectedCell.current.group]?.rows?.length - 1)) {
                if (!isGrid && footerRows && !selectedCell.current.isFooter) {
                  setSelectedCellValue(-1, 0, 0, 0, true, false);
                }
                else {
                  proceedDomNavigation = true;
                }
              }
            }
            else {
              if (selectedCell.current.row < (componentRows.length - 1)) {
                selectedCell.current.row = selectedCell.current.row + 1;
              }
              else if (selectedCell.current.row === (componentRows.length - 1)) {
                // Reached end of the rows in the body, check to see if footer rows exist then shift focus to first row first cell in the footer, footer exists only in SpreadSheet
                if (!isGrid && footerRows && !selectedCell.current.isFooter) {
                  setSelectedCellValue(-1, 0, 0, -1, true, false);
                }
                else {
                  proceedDomNavigation = true;
                }
              }
            }

            if (!proceedDomNavigation) {
              selectedCell.current.fakeIndex = 0;
              direction = 'down';
            }
          }
          else {
            selectedCell.current.fakeIndex += 1;
            direction = 'right';
          }

          break;

        case KeyboardConstants.ARROW_UP_KEY:
          if (renderSubHeader) {
            if (selectedCell.current.row === 0) {
              if (selectedCell.current.group > 0) {
                selectedCell.current.group -= 1;
                selectedCell.current.row = componentRows[selectedCell.current.group].rows?.length - 1;
              }
              else {
                selectedCell.current.isHeader = true;
              }
            }
            else {
              selectedCell.current.row -= 1;
            }
          }
          else {
            if (selectedCell.current.row === 0) {
              if (!isGrid && footerRows && selectedCell.current.isFooter) {
                selectedCell.current.row = componentRows?.length - 1;
                selectedCell.current.isFooter = false;
              }
              else {
                selectedCell.current.isHeader = true;
              }
            }
            else {
              selectedCell.current.row -= 1;
            }
          }

          direction = 'up';
          break;

        case KeyboardConstants.ARROW_DOWN_KEY:
          if (renderSubHeader) {
            if (selectedCell.current.row === (componentRows[selectedCell.current.group].rows?.length - 1)) {
              if (selectedCell.current.group !== componentRows?.length) {
                selectedCell.current.row = 0;
                selectedCell.current.group += 1;
              }
              else {
                proceedDomNavigation = true;
              }
            }
            else {
              selectedCell.current.row += 1;
            }
          }
          else {
            if (selectedCell.current.row === componentRows.length - 1) {
              if (!isGrid && footerRows && !selectedCell.current.isFooter) {
                selectedCell.current.row = 0;
                selectedCell.current.isFooter = true;
              }
              else {
                proceedDomNavigation = true;
              }
            }
            else {
              selectedCell.current.row += 1;
            }
          }

          direction = 'down';
          break;

        case KeyboardConstants.ENTER_KEY:
          console.log('Enter mode on (row: ' + selectedCell.current.row + ' column: ' + selectedCell.current.column + ')');

          if (selectRow) {
            targetEle = event.currentTarget;
            const groupNumber = findNumberByDataAttribute(targetEle, 'data-group');
            handleRowSelect(groupNumber, selectedCell.current.row);
          }

          if (!areEditorsVisible) {
            if (!editing) {
              setEditMode();
            }

            // Adding this to make the focus stay on the current cell and active element when ENTER_KEY is pressed
            setSelectedCellValue(findNumberByDataAttribute(targetEle, 'data-column'), findNumberByDataAttribute(targetEle, 'data-fakeindex'), findNumberByDataAttribute(targetEle, 'data-row'), findNumberByDataAttribute(targetEle, 'data-group') ? 0 : null, !!targetEle.parentElement.querySelector('div.' + componentClassName + '-row.footer'), false);
          }

          break;

        case KeyboardConstants.ESC_KEY:
          console.log('Enter mode off');

          if (!areEditorsVisible) {
            unSetEditMode();
          }

          break;
      }

      if (!proceedDomNavigation) {
        if (selectedCell.current.isHeader) {
          selectedCell.current.row = -1;
          const element: HTMLElement = component.current.querySelector((props.columns[selectedCell.current.column].locked ? '.locked-header' : '.unlocked-header') + ' div.' + componentClassName + '-row div[data-fakeindex="' + selectedCell.current.fakeIndex + '"].' + componentClassName + '-cell');
          setFocusToEditor(element, event);
        }
        else {
          let element: HTMLElement;

          if (!selectedCell.current.isFooter) {
            element = (selectedCell.current.group > -1) ? component.current.querySelector('[data-group="' + selectedCell.current.group + '"][data-row="' + selectedCell.current.row + '"] [data-fakeindex="' + selectedCell.current.fakeIndex + '"]') : component.current.querySelector('[data-row="' + selectedCell.current.row + '"] [data-fakeindex="' + selectedCell.current.fakeIndex + '"]');

            if (element) {
              selectedCell.current.column = findNumberByDataAttribute(element, 'data-column');
            }
          }
          else {
            element = component.current.querySelector(`.${componentClassName}-row.footer[data-row="${selectedCell.current.row}"] div.${componentClassName}-cell[data-fakeindex="${selectedCell.current.fakeIndex}"]`);
          }

          if (!selectedCell.current.isFooter && props.columns[selectedCell.current.column]?.editor) {
            event.preventDefault();
            event.stopPropagation();
            setEditMode(); // force update is needed as if the value editing variable is same then grid won't be re-rendered. In this case user might be in one editable cell but want to focus on next cell which is again editable using tab/keyboard controls
          }
          else {
            if (editing) {
              event.preventDefault();
              event.stopPropagation();
              unSetEditMode();
            }
            else {
              if (element) {
                setFocusToEditor(element, event);
              }
            }
          }

          if (element) {
            autoScrollToAdjustView(direction, element);
          }
        }
      }
      else {
        setSelectedCellValue(-1, -1, -1, -1, false, false);
      }
    }
  };

  const getLockedColumnsWidth = (): number => {
    let lockedColWidth = 0;

    lockedColumns().forEach((item) => {
      lockedColWidth += item.width;
    });

    if (lockedColumns()?.length && renderTreeStructure) {
      lockedColWidth += 30; // 30 pixel for expand collapse icon
    }

    return lockedColWidth;
  };

  const getUnlockedColumnsWidth = (): number => {
    let unlockedColWidth = 0;

    unlockedColumns().forEach((item) => {
      unlockedColWidth += item.width;
    });

    if (!lockedColumns()?.length && renderTreeStructure) {
      unlockedColWidth += 30; // 30 pixel for expand collapse icon
    }

    // Have to account for an extra px per column because of the right borders.
    unlockedColWidth += unlockedColumns.length;

    return unlockedColWidth;
  };

  const getCompoundColumnWidth = (column: any): number => {
    let compoundColWidth = 0;

    if (column.children) {
      column.children.forEach((item) => {
        compoundColWidth += item.width;
      });
    }

    return compoundColWidth;
  };

  const checkForCompoundColumn = (column: any): boolean => !!column?.children;

  const isEditingCell = (group: number, row: number, column: number): boolean => {
    if (!editing) {
      return false;
    }

    return selectedCell.current.row === row && selectedCell.current.column === column && ((group > -1) ? selectedCell.current.group === group : true);
  };

  const autoFocusElementsInFirstHeaderCell = (event) => {
    const colIndex = event.target?.getAttribute('data-column');

    // When this is the first column header and the focus is coming from outside the grid to this column.
    if (colIndex === '0' && !(event.target?.contains(event.relatedTarget))) {
      setFocusToEditor(event.target, event);
    }
  };

  const renderLockedHeader = (): React.ReactNode => {
    return (
      <Row className={componentClassName + '-row'} ariaRowIndex={1}>
        {
          lockedColumnsList.current.map((item, index) => {
            const View = item.headerLabel;
            const HeaderView = getView(View);

            const columnClasses = classNames(
              'vdl-col-xs',
              componentClassName + '-cell',
              {
                'sort-enabled': item.sortable === true,
                'justify-content-center': (item.alignHeader === 'center' || item.alignColumn === 'center'), // sometimes users may only set alignHeader to align header and use a separate view
                'justify-content-end': (item.alignHeader === 'right' || item.alignColumn === 'right') // sometimes users may only set alignHeader to align header and use a separate view
              },
              item.className
            );

            return (
              <Cell key={`locked-header-${item['columnIndex']}`} className={columnClasses}
                column={item['columnIndex']}
                width={item.width}
                onClick={item.sortable ? (event) => handleSort(event, item['columnIndex']) : null}
                onKeyDown={(event) => handleHeaderKeyDown(event, item['columnIndex'])}
                onFocus={(event) => autoFocusElementsInFirstHeaderCell(event)}
                tabIndex={0}
                ariaSortDirection={item.sortable ? getSortClassName(item['columnIndex'], true) : undefined}
                fakeIndex={index}
                role={'columnheader'}
                ariaColIndex={index + 1}
                id={gridId + '_' + item.key + '_' + item['columnIndex']}>
                <ResizeHandle onMouseDown={onMouseDown} resize={item.resize} />
                <CellContent>{HeaderView}</CellContent>
                {
                  item.sortable && <span className={getSortClassName(item['columnIndex'])} aria-hidden={true}>
                    {(columnSortMetadata.current[item['columnIndex']].order > 0) && (columnSortMetadata.current[item['columnIndex']].order)}
                  </span>
                }
              </Cell>
            );
          })
        }
      </Row>
    );
  };

  const addSelectedClass = (group, index) => {
    if (renderSubHeader) {
      return selectedRows[group]?.rows[index]?.isSelected;
    }
    else {
      return selectedRows[index]?.isSelected;
    }
  };

  const renderLockedColumn = (isFooter: boolean, row: any, rowNumber: number): React.ReactNode => lockedColumnsList.current.map((column, index) => {
    let editable: boolean;

    if (areEditorsVisible) {
      editable = (column.editor || column.view);
    }
    else {
      const isValidCell = (typeof row[column.key] === 'object') && (row[column.key]?.valid === false);
      editable = (column.editor && (isValidCell || isEditingCell(-1, rowNumber, column['columnIndex'])) || column.view);
    }

    let custom: any;

    if (editable) {
      const EditorView: any = column.editor ? column.editor : column.view;
      // moving the ...props.model to front to accommodate the nested arrays to pass it to the child grids
      custom = RenderHelper.renderContent(EditorView, { ...props.model, index: rowNumber, column: column.key, item: { ...row }, componentId: props.componentId });
    }
    else {
      custom = (typeof row[column.key] === 'object') ? row[column.key][column.valueField || 'value'] : row[column.key];
    }

    return (
      <Cell
        key={`locked-cell-${rowNumber}-${column['columnIndex']}`}
        title={!editable && custom}
        tabIndex={-1}
        row={rowNumber}
        editModeOn={editable}
        column={column['columnIndex']}
        fakeIndex={index}
        width={column.width}
        className={classNames('vdl-col-xs', componentClassName + '-cell', column.className, { editable: (column.editor && !isFooter) && true, 'vdl-text-center': column.alignColumn === 'center', 'vdl-text-right': column.alignColumn === 'right' })}
        onClick={(event) => (isFooter ? handleFooterCellClick(event) : handleCellOnClick(event, column.editor && editable))}
        onKeyDown={(event) => handleKeyDown(event)}
        isHeaderCell={false}
        ariaColIndex={index + 1}>
        <CellContent aria-describedby={gridId + '_' + column.key + '_' + column['columnIndex']}>{isFooter ? (editable ? ((typeof row[column.key] === 'object') ? row[column.key][column.valueField || 'value'] : row[column.key]) : custom) : custom}</CellContent>
      </Cell >
    );
  });

  const renderLockedRows = (): React.ReactNode => componentRows.map((row, index) =>
    <Row
      className={classNames(componentClassName + '-row', (addSelectedClass(null, index) && 'selected'), (isGrid && (tableStyle === 'list')) && 'white-bg')}
      key={`locked-row-${index}`}
      draggable={draggable}
      row={index}
      onMouseEnter={(event) => onMouseEnter(event)}
      onMouseLeave={(event) => onMouseLeave(event)}
      ariaRowIndex={index + 1}>
      {renderLockedColumn(false, row, index)}
    </Row>
  );

  const renderLockedFooterRows = (): React.ReactNode => {
    const ariaRowIndex = 1 + componentRows?.length; // starts with a value of 1(header row) + total # of rows

    return footerRows.map((row, index) =>
      <Row
        className={classNames('mdf-spreadsheet-row', 'footer')}
        key={`locked-footer-row-${index}`}
        row={index}
        ariaRowIndex={ariaRowIndex + index}
        onMouseEnter={(event) => onMouseEnter(event)}
        onMouseLeave={(event) => onMouseLeave(event)}>
        {renderLockedColumn(true, row, index)}
      </Row>
    );
  };

  const handleExpandKeyDown = (event: React.KeyboardEvent, row, groupNumber) => {
    if (event.key === 'Enter' || event.key === ' ') {
      event.stopPropagation();
      event.nativeEvent.stopImmediatePropagation();
      event.preventDefault();

      props.onExpand(row, groupNumber, row.expanded, props.componentId);
    }
  };

  const renderUnlockedColumn = (isFooter: boolean, groupNumber: number, row: any, rowNumber: number): React.ReactNode => {
    let index = lockedColumnsList.current?.length - 1;
    let isCompoundCell = false;

    // unlocked column ariaColIndex should start its first column index from 1 when there is no lockedColumn
    let ariaColIndex = lockedColumnsList.current?.length - 1 ? 0 : index;

    return unlockedColumnsList.current.map((column) => {
      let custom: any;
      index = index + 1;
      ariaColIndex = ariaColIndex + 1;

      if (!checkForCompoundColumn(column)) {
        if (isCompoundCell) {
          ariaColIndex = ariaColIndex + 1;
        }
        else {
          isCompoundCell = false;
        }

        const isValidCell = (typeof row[column.key] === 'object') && (row[column.key]?.valid === false);
        const editable = (column.editor && (areEditorsVisible ? areEditorsVisible : (isValidCell || isEditingCell(groupNumber, rowNumber, column['columnIndex'])))) || column.view;

        if (editable) {
          const EditorView: any = column.editor ? column.editor : column.view;
          // moving the ...props.model to front to accommodate the nested arrays to pass it to the child grids
          custom = RenderHelper.renderContent(EditorView, { ...props.model, index: rowNumber, groupIndex: groupNumber, column: column.key, item: { ...row }, componentId: props.componentId });
        }
        else {
          if (row[column.key] === null) {
            // typeof null === 'object', so we need to treat null differently, otherwise the code
            // in the else clause will throw an exception.
            custom = '';
          }
          else {
            custom = (typeof row[column.key] === 'object') ? row[column.key][column.valueField || 'value'] : row[column.key];
          }
        }

        return (
          // eslint-disable-next-line react/jsx-key
          <React.Fragment>
            {
              (renderTreeStructure && (column['columnIndex'] === 0)) && (
                <Cell
                  tabIndex={0}
                  onClick={() => {
                    props.onExpand(row, groupNumber, row.expanded, props.componentId);
                  }}
                  className={classNames('vdl-col-xs', componentClassName + '-cell', 'virtual-cell')}
                  expanded={row.expanded}
                  onKeyDown={(event) => handleExpandKeyDown(event, row, groupNumber)}
                >
                  <CellContent>
                    {(row.hasOwnProperty('expanded') && row?.children?.length) && <i className={`accent-0-dark fa fa-chevron-${row.expanded ? 'up' : 'down'}`}></i>}
                  </CellContent>
                </Cell>
              )
            }
            <Cell row={rowNumber}
              isHeaderCell={false}
              title={!editable && custom}
              tabIndex={0}
              column={column['columnIndex']}
              fakeIndex={index}
              width={column.width}
              ariaColIndex={ariaColIndex}
              editModeOn={editable}
              className={classNames('vdl-col-xs', componentClassName + '-cell', column.className, {
                editable: (column.editor && !isFooter) && true, 'vdl-text-center': column.alignColumn === 'center', 'vdl-text-right': column.alignColumn === 'right'
              })}
              onClick={(event) => (isFooter ? handleFooterCellClick(event) : handleCellOnClick(event, column.editor && editable))}
              onKeyDown={(event) => handleKeyDown(event)}>
              <CellContent aria-describedby={gridId + '_' + column.key + '_' + column['columnIndex']}>
                {isFooter ? (!editable ? custom : ((typeof row[column.key] === 'object') ? row[column.key][column.valueField || 'value'] : row[column.key])) : custom}
              </CellContent>
            </Cell>
          </React.Fragment>
        );
      }
      else {
        const isLeftCellInValid = (typeof row[column.children[0].key] === 'object') && (row[column.children[0].key]?.valid === false);
        const isRightCellInValid = (typeof row[column.children[1].key] === 'object') && (row[column.children[1].key]?.valid === false);
        let leftCustomItem: any;
        let rightCustomItem: any;
        const leftEditable = (column.children[0]?.editor && (areEditorsVisible ? areEditorsVisible : (isLeftCellInValid || isEditingCell(groupNumber, rowNumber, column['columnIndex'])))) || column.children[0].view;
        const rightEditable = (column.children[1]?.editor && (areEditorsVisible ? areEditorsVisible : (isRightCellInValid || isEditingCell(groupNumber, rowNumber, column['columnIndex'])))) || column.children[1].view;

        isCompoundCell = true;

        if (!isFooter) {
          if (leftEditable) {
            const Item: any = column.children[0].editor ? column.children[0].editor : column.children[0].view;
            // moving the ...props.model to front to accommodate the nested arrays to pass it to the child grids
            leftCustomItem = RenderHelper.renderContent(Item, { ...props.model, index: rowNumber, groupIndex: groupNumber, column: column.children[0].key, item: { ...row }, componentId: props.componentId });
          }
          else {
            leftCustomItem = (typeof row[column.children[0].key] === 'object') ? row[column.children[0].key][column.children[0].valueField || 'value'] : row[column.children[0].key];
          }

          if (rightEditable) {
            const Item: any = column.children[1].editor ? column.children[1].editor : column.children[1].view;
            // moving the ...props.model to front to accommodate the nested arrays to pass it to the child grids
            rightCustomItem = RenderHelper.renderContent(Item, { ...props.model, index: rowNumber, groupIndex: groupNumber, column: column.children[1].key, item: { ...row }, componentId: props.componentId });
          }
          else {
            rightCustomItem = (typeof row[column.children[1].key] === 'object') ? row[column.children[1].key][column.children[1].valueField || 'value'] : row[column.children[1].key];
          }
        }
        else {
          leftCustomItem = (typeof row[column.children[0].key] === 'object') ? row[column.children[0].key][column.children[0].valueField || 'value'] : row[column.children[0].key];
          rightCustomItem = (typeof row[column.children[1].key] === 'object') ? row[column.children[1].key][column.children[1].valueField || 'value'] : row[column.children[1].key];
        }

        return (
          // eslint-disable-next-line react/jsx-key
          <Cell
            tabIndex={-1}
            row={rowNumber}
            ariaColIndex={ariaColIndex}
            column={column['columnIndex']}
            fakeIndex={index}
            width={column.width}
            editModeOn={(leftEditable || rightEditable)}
            className={classNames(column.className, componentClassName + '-cell', { editable: (!isFooter && ((column.children[0].editor && true) || (column.children[1].editor && true))) })}
            onKeyDown={(event) => handleKeyDown(event)}
          >
            <CompoundCellContent
              ariaColIndex={ariaColIndex}
              width={column.width}
              leftTitle={!leftEditable && leftCustomItem}
              rightTitle={!rightEditable && rightCustomItem}
              leftValue={leftCustomItem}
              rightValue={rightCustomItem}
              leftCellId={gridId + '_' + column.children[0].key + '_' + column['columnIndex']}
              rightCellId={gridId + '_' + column.children[1].key + '_' + column['columnIndex']}
              onClick={(event, position) => (isFooter ? unSetEditMode() : handleCellOnClick(event, (position === 'left' ? (column.children[0]?.editor && leftEditable) : (column.children[1]?.editor && rightEditable))))}
            />
          </Cell>
        );
      }
    });
  };

  const renderUnlockedRowsChildren = (groupNumber: number, rowWidth: number, rows: object[]): React.ReactNode => {
    return rows.map((row, index) => (
      <Row subHeaderIndex={groupNumber}
        className={classNames(componentClassName + '-row', (addSelectedClass(null, index) && 'selected'), (isGrid && (tableStyle === 'list')) && 'white-bg')}
        row={index}
        width={rowWidth}
        height={rowHeight}
        key={`child-row-${index}`}
        onMouseEnter={(event) => onMouseEnter(event)}
        onMouseLeave={(event) => onMouseLeave(event)}>
        {renderUnlockedColumn(false, groupNumber, row, index)}
      </Row>
    ));
  };

  const renderUnlockedRows = (rowWidth: number): React.ReactNode => {
    return componentRows.map((row, index) => (
      <React.Fragment key={`unique-${index}`}>
        <Row
          className={classNames(componentClassName + '-row', (addSelectedClass(null, index) && 'selected'), (isGrid && (tableStyle === 'list')) && 'white-bg')}
          draggable={draggable}
          key={`row-${(renderTreeStructure ? 'group-' : '')}-${index}`}
          width={rowWidth}
          subHeaderIndex={renderTreeStructure ? (row.children?.length ? index : -1) : undefined}
          row={!(renderTreeStructure && row.children?.length) ? index : -1}
          ariaRowIndex={index + 1}
          expanded={row['expanded']}
          onMouseEnter={(event) => onMouseEnter(event)}
          onMouseLeave={(event) => onMouseLeave(event)}
        >
          {renderUnlockedColumn(false, (renderTreeStructure && row.children?.length) ? index : -1, row, !(renderTreeStructure && row.children?.length) ? index : -1)}
        </Row>
        {(row?.children && (row['expanded'] === true) && renderUnlockedRowsChildren(index, rowWidth, row.children))}
      </React.Fragment>
    ));
  };

  const renderUnlockedRowsWithSubheader = (rowWidth: number): React.ReactNode => {
    return componentRows.map(({ subheader, subHeaderData, rows }, index) => {
      const SubHeader = subheader && ComponentManager.getComponent(subheader);
      let subheaderNode;

      // Check to see if it is a custom view, if not render it as a string
      if (SubHeader) {
        subheaderNode = <Row className={classNames(componentClassName + '-row', 'subheader')} height={subHeaderRowHeight} width={rowWidth} subHeaderIndex={index}><Cell className={componentClassName + '-cell'} onClick={() => unSetEditMode()} ><SubHeader {...subHeaderData} /></Cell></Row>;
      }
      else {
        subheaderNode = subheader && <Row className={classNames(componentClassName + '-row', 'subheader')} height={subHeaderRowHeight} width={rowWidth} subHeaderIndex={index}><Cell className={componentClassName + '-cell'} onClick={() => unSetEditMode()}><CellContent>{subheader}</CellContent></Cell></Row>;
      }

      const rowNode = rows.map((row, i) => {
        return (
          <Row
            className={classNames(componentClassName + '-row', (addSelectedClass(index, i) && 'selected'), (isGrid && (tableStyle === 'list')) && 'white-bg')}
            height={rowHeight}
            draggable={props.draggable}
            key={`row-with-subheader-${index + '-' + i}`}
            width={rowWidth}
            subHeaderIndex={index}
            row={i}
            ariaRowIndex={i + 1}
            onMouseEnter={(event) => onMouseEnter(event)}
            onMouseLeave={(event) => onMouseLeave(event)}
          >
            {renderUnlockedColumn(false, index, row, i)}
          </Row>
        );
      });

      return [subheaderNode, rowNode];
    });
  };

  // Footer rows are only for SpreadSheet component
  const renderUnlockedFooterRows = (unlockedBodyWidth: number): React.ReactNode => {
    let rowWidth = getUnlockedColumnsWidth() - (unlockedColumns().length - 1);
    const ariaRowIndex = 1 + componentRows?.length; // starts with a value of 1(header row) + total # of rows

    if (rowWidth < unlockedBodyWidth) {
      rowWidth = unlockedBodyWidth;
    }

    return footerRows.map((row, index) =>
      <Row
        className={classNames(componentClassName + '-row', 'footer')}
        key={`footer-row-${index}`}
        row={index}
        ariaRowIndex={ariaRowIndex + index}
        width={rowWidth}
        onMouseEnter={(event) => onMouseEnter(event)}
        onMouseLeave={(event) => onMouseLeave(event)}
      >
        {renderUnlockedColumn(true, -1, row, index)}
      </Row>
    );
  };

  const getView = (View) => {
    if (!View || typeof View === 'string') {
      return View;
    }

    if (React.isValidElement(View) === true) {
      return React.cloneElement(View, { componentId: props.componentId } as any);
    }

    if (typeof View === 'function' || typeof View === 'object') {
      return <View componentId={props.componentId} />;
    }
  };

  const renderUnlockedHeader = (): React.ReactNode => {
    let fakeIndex = lockedColumnsList.current?.length - 1;

    // unlocked header ariaColIndex should start its first column index with 1 when there is no lockedColumn
    let ariaColIndex = (lockedColumnsList.current?.length - 1) === -1 ? 0 : fakeIndex;
    let isCompoundColumn = false;

    return (
      <Row className={componentClassName + '-row'} ariaRowIndex={1}>
        {
          unlockedColumnsList.current.map((item) => {
            const View = item.headerLabel;
            const HeaderView = getView(View);
            fakeIndex = fakeIndex + 1;
            ariaColIndex = ariaColIndex + 1;

            if (!checkForCompoundColumn(item)) {
              if (isCompoundColumn) {
                ariaColIndex = ariaColIndex + 1;
              }
              else {
                isCompoundColumn = false;
              }

              const columnClasses = classNames(
                'vdl-col-xs',
                componentClassName + '-cell',
                {
                  'sort-enabled': item.sortable === true,
                  'justify-content-center': (item.alignHeader === 'center' || item.alignColumn === 'center'),
                  'justify-content-end': (item.alignHeader === 'right' || item.alignColumn === 'right')
                },
                item.className
              );

              return (
                // eslint-disable-next-line react/jsx-key
                <React.Fragment>
                  {(renderTreeStructure && item['columnIndex'] === 0) && <Cell className={classNames('vdl-col-xs', componentClassName + '-cell', 'virtual-cell')}></Cell>}
                  <Cell
                    id={gridId + '_' + item.key + '_' + item['columnIndex']}
                    className={columnClasses}
                    column={item['columnIndex']}
                    fakeIndex={fakeIndex}
                    width={item.width}
                    ariaColIndex={ariaColIndex}
                    role={'columnheader'}
                    onClick={item.sortable ? (event) => handleSort(event, item['columnIndex']) : null}
                    tabIndex={0}
                    onKeyDown={(event) => handleHeaderKeyDown(event, item['columnIndex'])}
                    onFocus={(event) => autoFocusElementsInFirstHeaderCell(event)}
                    ariaSortDirection={item.sortable ? getSortClassName(item['columnIndex'], true) : undefined}
                  >
                    <ResizeHandle onMouseDown={onMouseDown} resize={item.resize} />
                    <CellContent>{HeaderView}</CellContent>
                    {
                      item.sortable && <span className={getSortClassName(item['columnIndex'])} aria-hidden={true}>
                        {(columnSortMetadata.current[item['columnIndex']].order > 0) && (columnSortMetadata.current[item['columnIndex']].order)}
                      </span>
                    }
                  </Cell>
                </React.Fragment>
              );
            }
            else {
              const CompoundCellTitle = item.headerLabel;
              const FormattedCompoundCellTile = getView(CompoundCellTitle);

              // We only allow two child columns when column is a compound column.
              isCompoundColumn = true;

              const LeftCellTitle = item.children[0].headerLabel;
              const subTitleLeft = getView(LeftCellTitle);
              const RightCellTitle = item.children[1].headerLabel;
              const subTitleRight = getView(RightCellTitle);

              return (
                // eslint-disable-next-line react/jsx-key
                <CompoundCellHeader
                  className={classNames(componentClassName + '-compound-cell-header', item.className)}
                  column={item['columnIndex']}
                  fakeIndex={fakeIndex}
                  ariaColIndex={ariaColIndex}
                  title={FormattedCompoundCellTile}
                  subTitleLeft={subTitleLeft}
                  subTitleRight={subTitleRight}
                  leftCellId={gridId + '_' + item.children[0].key + '_' + item['columnIndex']}
                  rightCellId={gridId + '_' + item.children[1].key + '_' + item['columnIndex']}
                  sortable={item.sortable}
                  sortClass={item.sortable && getSortClassName(item['columnIndex'])}
                  sortOrder={columnSortMetadata.current[item['columnIndex']].order}
                  width={getCompoundColumnWidth(item)}
                  onClick={item.sortable ? (event) => handleSort(event, item['columnIndex']) : null}
                  ariaSortDirection={item.sortable ? getSortClassName(item['columnIndex'], true) : undefined}
                  onKeyDown={(event) => handleHeaderKeyDown(event, item['columnIndex'])}
                >
                  <ResizeHandle onMouseDown={onMouseDown} resize={item.resize} />
                </CompoundCellHeader>
              );
            }
          })
        }
      </Row>
    );
  };

  const calculateUnlockedRowsWidth = (unlockedBodyWidth: number) => {
    let rowWidth = getUnlockedColumnsWidth() - (unlockedColumns().length);

    if (rowWidth < unlockedBodyWidth) {
      rowWidth = unlockedBodyWidth;
    }

    return rowWidth;
  };

  const lockedColumnsWidth = getLockedColumnsWidth();
  const scrollWidth = width - lockedColumnsWidth;
  const unlockedColumnsWidth = calculateUnlockedRowsWidth(scrollWidth);
  const isHorizontalScrollExists = unlockedColumnsWidth > scrollWidth;
  // Calculate the height of the containing div based on the row height.
  const totalSubHeaderMetaData = calculateSubHeaderMetaData();
  const totalRowCount = renderTreeStructure ? totalSubHeaderMetaData : (totalSubHeaderMetaData.totalRows > 0 ? totalSubHeaderMetaData.totalRows : componentRows?.length);

  // Need to calculate numberOfVisibleRows i.e. number of visible rows to display in the grid, as grid height depends on this number whenever it gets re-rendered
  const calculatePageSize = () => {
    if (enableWindowScroll) {
      numberOfVisibleRows = totalRowCount;
    }
    else {
      if (!visibleRows) {
        numberOfVisibleRows = totalRowCount;
      }
      else {
        numberOfVisibleRows = visibleRows;
      }
    }
  };

  calculatePageSize();

  const gridBodyHeight = numberOfVisibleRows * rowHeight;
  const lockedRowsHeight = (noData || (lockedColumnsList.current.length === 0)) ? 0 : gridBodyHeight;
  const unlockedRowsHeight = noData ? 0 : (gridBodyHeight + (isHorizontalScrollExists ? SCROLLBAR_THICKNESS.current : 0));
  const footerRowsHeight = (footerRows?.length > 0 ? (rowHeight * footerRows?.length) : 0);
  const componentHeight = footerRows ? gridBodyHeight + ((footerRows?.length + 1) * rowHeight) + (isHorizontalScrollExists ? SCROLLBAR_THICKNESS.current : 0) : gridBodyHeight + rowHeight + (isHorizontalScrollExists ? SCROLLBAR_THICKNESS.current : 0);

  // Wrapping locked-header & unlocked-header in a empty div to support normal tab navigation as oppose to programatically set focus and tab through cells like in body
  return (
    <div>
      <p id={captionId} className="vdl-invisible caption">{caption}</p>
      <div id={props.componentId} aria-labelledby={captionId} ref={component} className={classNames('vdl-container-fluid', componentClassName)} role={'grid'} unselectable={'on'} aria-rowcount={componentRows?.length} aria-colcount={props.columns?.length} style={{ height: 'auto', maxHeight: (noData ? 'none' : componentHeight) }}>
        <div unselectable={'on'} role={'presentation'} className={classNames('vdl-row', 'mdf-flex-nowrap', componentClassName + '-header')}>
          {
            <div className="locked-header" style={{ width: lockedColumnsWidth }} unselectable={'on'} role={'presentation'}>
              {(lockedColumnsList.current?.length > 0) && renderLockedHeader()}
            </div>
          }

          <div className="unlocked-header" style={{ left: lockedColumnsWidth, width: scrollWidth + 'px' }} unselectable={'on'} role={'presentation'}>
            {renderUnlockedHeader()}
          </div>
        </div>
        <div className={(isGrid ? 'grid' : 'spreadsheet') + '-body'}>
          {
            /** adding additional layer of div to support autoscroll functionality on dom when dragging a row */
            <div className="locked-body" style={{ width: lockedColumnsWidth, maxHeight: lockedRowsHeight + 'px' }} unselectable={'on'} role={'presentation'}>
              <div ref={props.collectContainers} unselectable={'on'}>
                {(lockedColumnsList.current?.length > 0) && renderLockedRows()}
              </div>
            </div>
          }

          {
            /** adding additional layer of div to support autoscroll functionality on dom when dragging a row */
            (!isGrid || (!enableWindowScroll && isGrid)) ?
              (<div className={'scroll-container'} style={{ maxHeight: unlockedRowsHeight + 'px', width: noData ? 0 : (scrollWidth + (isCalculatedVerticalScroll.current ? SCROLLBAR_THICKNESS.current : 0)) + 'px', marginRight: noData ? 0 : -SCROLLBAR_THICKNESS.current }} onScroll={(event) => handleUnlockedScroll(event)}>
                <div className="unlocked-body" style={{ minWidth: unlockedColumnsWidth }} unselectable="on" role={'rowgroup'}>
                  <div ref={props.collectContainers} unselectable={'on'}>
                    {!renderSubHeader ? (renderUnlockedRows(unlockedColumnsWidth)) : (renderUnlockedRowsWithSubheader(unlockedColumnsWidth))}
                  </div>
                </div>
              </div>) :
              (isGrid && enableWindowScroll) &&
              (<div className={'scroll-container noVerticalScroll'} style={{ maxHeight: unlockedRowsHeight + 'px', width: noData ? 0 : ('calc(100% - ' + lockedColumnsWidth + 'px)') }} onScroll={(event) => handleUnlockedScroll(event)}>
                <div className="unlocked-body" style={{ minWidth: unlockedColumnsWidth }} unselectable="on" role={'rowgroup'}>
                  <div ref={props.collectContainers} unselectable={'on'}>
                    {!renderSubHeader ? (renderUnlockedRows(unlockedColumnsWidth)) : (renderUnlockedRowsWithSubheader(unlockedColumnsWidth))}
                  </div>
                </div>
              </div>)
          }
        </div>

        {
          (!isGrid && footerRows) &&
          <div className={'spreadsheet-footer'} style={{ height: footerRowsHeight + 'px' }}>
            <div className="locked-footer" style={{ width: lockedColumnsWidth }} unselectable={'on'} role={'rowgroup'}>
              {(lockedColumnsList.current?.length > 0) && renderLockedFooterRows()}
            </div>
            <div className="unlocked-footer" style={{ left: lockedColumnsWidth, width: scrollWidth + 'px' }} onScroll={(event) => handleFooterRowsScroll(event)} unselectable={'on'} role={'rowgroup'}>
              {renderUnlockedFooterRows(unlockedColumnsWidth)}
            </div>
          </div>
        }
      </div>
      {
        noData && <div className={classNames('vdl-container-fluid', componentClassName)} tabIndex={0} unselectable={'on'} aria-live="polite" role={'presentation'}>
          <span className="no-data" unselectable={'on'}>{noDataMessage || `${FormatHelper.formatMessage('@@NoRowsFound')}`}</span>
        </div>
      }
    </div>
  );
});

MDFSpreadSheet.displayName = 'MDFSpreadSheet';
