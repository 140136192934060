import React, { useEffect, useState } from 'react';
import { FormatHelper } from '@adp-wfn/mdf-core';
import classNames from 'classnames';
import { IRuleRow } from './RuleRow';
import { IRuleCard, RuleCard, RuleCardAlert, RuleCardBottom, RuleCardFooter, RuleCardHeader, RuleCardRows } from './RuleCard';
import { IRuleGroup, RuleGroup } from './RuleGroup';
import { ILabelValuePair, IRuleColumn, OperatorType } from './RuleCell';
import { addNewGroupOrSet, arrangeDataSetWithGroupCardIndex, deleteGroupOrSet, editGroupOrSet, moveGroupOrSet, validateRules } from '../../util/ruleSet';
import { cloneDeep } from 'lodash';

// const MAX_ALLOWED_SETS = 3;

export enum RulesetActions {
  ADD_NEW_SET_FROM_GROUP = 'ADD_NEW_SET_FROM_GROUP',
  ADD_NEW_GROUP_FROM_GROUP = 'ADD_NEW_GROUP_FROM_GROUP',
  COPY_SET_FROM_GROUP = 'COPY_SET_FROM_GROUP',
  DELETE_SET_FROM_GROUP = 'DELETE_SET_FROM_GROUP',
  MOVE_DOWN_FROM_GROUP = 'MOVE_DOWN_FROM_GROUP',
  MOVE_UP_FROM_GROUP = 'MOVE_UP_FROM_GROUP',
  BETWEEN_SET_JOIN_OPERATOR_FROM_GROUP = 'BETWEEN_SET_JOIN_OPERATOR_FROM_GROUP',

  ADD_NEW_SET_FROM_CARD = 'ADD_NEW_SET_FROM_CARD',
  ADD_NEW_GROUP_FROM_CARD = 'ADD_NEW_GROUP_FROM_CARD',
  COPY_SET_FROM_CARD = 'COPY_SET_FROM_CARD',
  DELETE_SET_FROM_CARD = 'DELETE_SET_FROM_CARD',
  MOVE_DOWN_FROM_CARD = 'MOVE_DOWN_FROM_CARD',
  MOVE_UP_FROM_CARD = 'MOVE_UP_FROM_CARD',

  ADD_NEW_SET_ROW = 'ADD_NEW_SET_ROW',
  DELETE_ROW = 'DELETE_ROW',

  BETWEEN_SET_JOIN_OPERATOR = 'BETWEEN_SET_JOIN_OPERATOR',
  BETWEEN_ROW_JOIN_OPERATOR = 'BETWEEN_ROW_JOIN_OPERATOR',

  FIELD_NAME_CHANGE = 'FIELD_NAME_CHANGE',
  OPERATOR_CHANGE = 'OPERATOR_CHANGE',
  FIELD_VALUE_CHANGE = 'FIELD_VALUE_CHANGE',
  FIELD_JOIN_OPERATOR_CHANGE = 'FIELD_JOIN_OPERATOR_CHANGE'
}

export interface IRuleSetProps {
  // A CSS classname to override the styles of this component.
  className?: string;

  // To enable/disable the component
  disabled?: boolean;

  // The title text for this component.
  title?: string;

  // If true, render Preview Rules.
  showPreview?: boolean;

  // If false, the user cannot add new groups to the RuleSet.
  canAddNewGroup?: boolean;

  // If false, show join operator in header or row
  showHeaderJoinOperator?: boolean;

  // show validation on render
  validateOnRender?: boolean;

  // Number of max allowed cards per rule set.
  maxAllowedSets?: number;

  // Number of max allowed rows per rule card.
  maxAllowedRows?: number;

  // rule field name
  fieldNames?: IRuleColumn[];

  data: (IRuleCard | IRuleGroup)[];

  // Async function call to load dynamic data for the value field (MDFSelectBox)
  onFetchValueOptions: (fieldName: any, inputValue: string, prevOptions: any) => Promise<any>;

  // A function to call when component changes its value.
  onChange: (value: any, changeType: string) => void;
}

interface IRuleSetWrapperProps {
  disabled?: boolean;
  maxAllowedSets?: number;
  maxAllowedRows?: number;
  fieldNames?: IRuleColumn[];
  joinOperatorOptions: ILabelValuePair<string>[];
  dataSet: {
    data: (IRuleCard | IRuleGroup)[];
    isValid: boolean;
    validationMessage?: string
  };
  showPreview?: boolean;
  showHeaderJoinOperator?: boolean;
  canAddNewGroup?: boolean;
  onChange?: (cardIndex: number, card: IRuleCard, changeType: string) => void;
  onFetchValueOptions: (fieldName: any, inputValue: string, prevOptions: any) => Promise<any>;
}

const RuleSetRowSummary = (props: any) => {
  const row: IRuleRow = props.row;
  const field = props.fieldNames?.find((item: any) => item.value === row.field);
  const operator = field?.operatorOptions?.find((item: any) => item.value === row.operator);

  return (
    <div>
      <div className="rule-summary-text">
        {`${field?.label} ${operator?.label || ''} ${row.values?.map((val, index) => {
          if (index === 0) {
            return (`${val?.label || ''}`);
          }
          else if (index === row.values.length - 1) {
            if (operator?.value === OperatorType.IS_EQUAL_TO) {
              return (`${index !== 1 ? ',' : ''} ${FormatHelper.formatMessage('@@or')} ${val?.label || ''}`);
            }
            else if (operator?.value === OperatorType.IS_NOT_EQUAL_TO) {
              return (`${index !== 1 ? ',' : ''} ${FormatHelper.formatMessage('@@and')} ${val?.label || ''}`);
            }
          }
          return (`, ${val?.label || ''}`);
        })?.join('')}`}
      </div>
      {!props.isLastRow && <div className="rule-summary-operator"><h5>{props.rowJoinOperator}</h5></div>}
    </div>
  );
};

const RuleSetCardSummary = (props: any) => {
  const item: IRuleCard = props.card;
  const selectedRows: IRuleRow[] = item.ruleRows?.filter((row) => (!!row?.field) && ((!!row?.operator) || (props.fieldNames?.find((itm: any) => itm.value === row.field)?.hasOperator === false)));
  const rowJoinOperator = props.joinOperatorOptions?.find((option: ILabelValuePair<string>) => option.value === item.rowJoinOperator)?.valueLabel || '';
  const setJoinOperator = props.joinOperatorOptions?.find((option: ILabelValuePair<string>) => option.value === item.setJoinOperator)?.valueLabel || '';

  return (
    <React.Fragment>
      <div className="rule-summary-main">
        <div className="rule-summary-title"><h2 className="rule-summary-title-medium">{`${FormatHelper.formatMessage('@@RuleSet')} ${item.cardIndex}`}</h2></div>
        <div className="rule-summary-value">
          {selectedRows?.length !== 0 && selectedRows?.map((row, index) => {
            const joinOperator = props.showHeaderJoinOperator ? rowJoinOperator : props.joinOperatorOptions?.find((option: ILabelValuePair<string>) => option.value === row.joinOperator)?.valueLabel || '';
            return (
              <RuleSetRowSummary key={`row-${index}`} fieldNames={props.fieldNames} row={row} rowJoinOperator={joinOperator} isLastRow={selectedRows.length - 1 === index} />
            );
          })}
        </div>
      </div>
      {selectedRows?.length === 0 && <div className="rule-summary-empty">{FormatHelper.formatMessage('@@noRuleMessage')}</div>}
      {props.renderJoinOperator &&
        <div className="rule-summary-divider">
          <div className="rule-summary-divider-medium">{setJoinOperator}</div>
          <div className="rule-summary-divider-line">
            <div className="rule-summary-divider-line-border" />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

const RuleSetGroupSummary = (props: any) => {
  const group: IRuleGroup = props.card;
  const setJoinOperator = props.joinOperatorOptions?.find((option: ILabelValuePair<string>) => option.value === group.setJoinOperator)?.valueLabel || '';

  return (
    <React.Fragment>
      <div className="rule-summary-group">
        <div className="rule-summary-title"><h2 className="rule-summary-title-medium">{`${FormatHelper.formatMessage('@@Group')} ${group.groupIndex}`}</h2></div>
        <div className="rule-summary-group-value">
          {group?.data?.map((cardOrGroup, idx) => {
            if (cardOrGroup.hasOwnProperty('data')) {
              const item: IRuleGroup = cardOrGroup as IRuleGroup;

              return (
                <RuleSetGroupSummary key={`group-${item.groupIndex}`} fieldNames={props.fieldNames} joinOperatorOptions={props.joinOperatorOptions} card={item} renderJoinOperator={idx !== group.data?.length - 1} showHeaderJoinOperator={props.showHeaderJoinOperator} />
              );
            }
            else {
              const item: IRuleCard = cardOrGroup as IRuleCard;

              return (
                <RuleSetCardSummary key={`card-${item.cardIndex}`} fieldNames={props.fieldNames} joinOperatorOptions={props.joinOperatorOptions} card={item} renderJoinOperator={idx !== group.data?.length - 1} showHeaderJoinOperator={props.showHeaderJoinOperator} />
              );
            }
          })}
        </div>
      </div>
      {props.renderJoinOperator &&
        <div className="rule-summary-divider">
          <div className="rule-summary-divider-span">{setJoinOperator}</div>
          <div className="rule-summary-divider-line">
            <div className="rule-summary-divider-line-border" />
          </div>
        </div>
      }
    </React.Fragment>
  );
};

const RuleSetSummary = (props: any) => {
  return (
    <div className="rule-summary">
      <div className="rule-summary-header">
        <h2>{FormatHelper.formatMessage('@@RuleSummary')}</h2>
      </div>
      <div className="rule-summary-panel">
        {props.dataSet?.data?.map((cardOrGroup, idx) => {
          if (cardOrGroup.hasOwnProperty('data')) {
            const item: IRuleGroup = cardOrGroup as IRuleGroup;

            return (
              <RuleSetGroupSummary key={`group-${item.groupIndex}`} fieldNames={props.fieldNames} joinOperatorOptions={props.joinOperatorOptions} card={item} renderJoinOperator={idx !== props.dataSet.data?.length - 1} showHeaderJoinOperator={props.showHeaderJoinOperator} />
            );
          }
          else {
            const item: IRuleCard = cardOrGroup as IRuleCard;

            return (
              <RuleSetCardSummary key={`card-${item.cardIndex}`} fieldNames={props.fieldNames} joinOperatorOptions={props.joinOperatorOptions} card={item} renderJoinOperator={idx !== props.dataSet.data?.length - 1} showHeaderJoinOperator={props.showHeaderJoinOperator} />
            );
          }
        })}
      </div>
    </div>
  );
};

const RuleSetWrapper = (props: IRuleSetWrapperProps) => {
  const dataset = cloneDeep(props.dataSet);
  const setIndex = { currentCardIndex: 1, currentGroupIndex: 1 };
  arrangeDataSetWithGroupCardIndex(dataset, setIndex);

  return (
    <React.Fragment>
      {
        dataset?.data?.map((cardOrGroup, idx) => {
          if (cardOrGroup.hasOwnProperty('data')) {
            const item: IRuleGroup = cardOrGroup as IRuleGroup;

            return (
              <RuleGroup
                key={idx}
                groupIndex={item.groupIndex}
                canMoveUp={idx !== 0}
                canMoveDown={idx !== dataset.data?.length - 1}
                data={item.data}
                disabled={props.disabled}
                isValid={item.isValid}
                validationMessage={item.validationMessage}
                maxAllowedRows={props.maxAllowedRows}
                fieldNames={props.fieldNames}
                joinOperatorOptions={props.joinOperatorOptions}
                showHeaderJoinOperator={props.showHeaderJoinOperator ?? true}
                renderJoinOperator={idx !== dataset.data?.length - 1}
                isSingleSet={dataset.data?.length <= 1}
                setJoinOperator={item.setJoinOperator}
                onChange={props.onChange}
                onFetchValueOptions={props.onFetchValueOptions}
              />
            );
          }
          else {
            const item: IRuleCard = cardOrGroup as IRuleCard;

            return (
              <RuleCard
                key={idx}
                cardIndex={item.cardIndex}
                disabled={props.disabled}
                maxAllowedRows={props.maxAllowedRows}
                ruleRows={item.ruleRows || [{ field: null, operator: null, values: [{ value: null, label: null }], ...(props?.showHeaderJoinOperator === false && { joinOperator: 'and' }) }]}
                rowJoinOperator={item.rowJoinOperator}
                isValid={item.isValid}
                validationMessage={item.validationMessage}
                fieldNames={props.fieldNames}
                joinOperatorOptions={props.joinOperatorOptions}
                showHeaderJoinOperator={props.showHeaderJoinOperator ?? true}
                setJoinOperator={item.setJoinOperator}
                showAlert={item.showAlert}
                alertTitle={item.alertTitle}
                alertContent={item.alertContent}
                alertType={item.alertType}
                onChange={props.onChange}
                onFetchValueOptions={props.onFetchValueOptions}
              >
                <RuleCardHeader canMoveUp={idx !== 0} canMoveDown={idx !== dataset.data?.length - 1} isSingleSet={dataset.data?.length <= 1} />
                <RuleCardAlert showAlert={item.showAlert} alertTitle={item.alertTitle} alertContent={item.alertContent} alertType={item.alertType} />
                <RuleCardRows showJoinOperator={true} />
                <RuleCardFooter />
                <RuleCardBottom canAddNewGroup={props.canAddNewGroup} renderJoinOperator={idx !== dataset.data?.length - 1} disableAdd={props?.maxAllowedSets < setIndex.currentCardIndex} />
              </RuleCard>
            );
          }
        })
      }
      {props.showPreview && <RuleSetSummary fieldNames={props.fieldNames} joinOperatorOptions={props.joinOperatorOptions} showHeaderJoinOperator={props.showHeaderJoinOperator ?? true} dataSet={dataset} />}
    </React.Fragment>
  );
};

export const RuleSet = (props: IRuleSetProps) => {
  const [dataSet, setDataSet] = useState({ data: [], isValid: false, validationMessage: '' });

  useEffect(() => {
    if (!props.data) {
      setDataSet({ data: ([{ ruleRows: null, rowJoinOperator: 'and', setJoinOperator: 'and', isValid: false, validationMessage: '' }] as IRuleCard[]), isValid: false, validationMessage: '' });
    }
    else {
      setDataSet({ data: props.data, isValid: false, validationMessage: '' });
    }
  }, [props.data]);

  const joinOperatorOptions = [
    {
      label: FormatHelper.formatMessage('@@match_all_statements'),
      valueLabel: FormatHelper.formatMessage('@@and').toUpperCase(),
      value: 'and'
    },
    {
      label: FormatHelper.formatMessage('@@match_any_statement'),
      valueLabel: FormatHelper.formatMessage('@@or').toUpperCase(),
      value: 'or'
    }
  ];

  // validate rule set rules whenever there is a change
  const validateSet = (dataset: any) => {
    validateRules(dataset, { currentCardIndex: 1 });

    dataset.isValid = dataset.data.every((item) => item.isValid);
    dataset.validationMessage = dataset.data.filter((item) => !item.isValid).map((item) => item.validationMessage).join(',');
  };

  const handleDataChanged = (cardIndex: number, card: IRuleCard | IRuleGroup, changeType: string) => {
    const dataset = { ...dataSet };

    switch (changeType as RulesetActions) {
      case RulesetActions.ADD_NEW_SET_FROM_CARD:
      case RulesetActions.ADD_NEW_GROUP_FROM_CARD:
      case RulesetActions.COPY_SET_FROM_CARD:
        addNewGroupOrSet(dataset, cardIndex, card, { currentCardIndex: 1 });
        break;

      case RulesetActions.ADD_NEW_SET_FROM_GROUP:
      case RulesetActions.ADD_NEW_GROUP_FROM_GROUP:
      case RulesetActions.COPY_SET_FROM_GROUP:
        addNewGroupOrSet(dataset, cardIndex, card, { currentGroupIndex: 1 });
        break;

      case RulesetActions.DELETE_SET_FROM_CARD:
        deleteGroupOrSet(dataset, cardIndex, card, { currentCardIndex: 1 });
        break;

      case RulesetActions.DELETE_SET_FROM_GROUP:
        deleteGroupOrSet(dataset, cardIndex, card, { currentGroupIndex: 1 });
        break;

      case RulesetActions.MOVE_DOWN_FROM_CARD:
        moveGroupOrSet(dataset, cardIndex, 1, { currentCardIndex: 1 });
        break;

      case RulesetActions.MOVE_UP_FROM_CARD:
        moveGroupOrSet(dataset, cardIndex, -1, { currentCardIndex: 1 });
        break;

      case RulesetActions.MOVE_DOWN_FROM_GROUP:
        moveGroupOrSet(dataset, cardIndex, 1, { currentGroupIndex: 1 });
        break;

      case RulesetActions.MOVE_UP_FROM_GROUP:
        moveGroupOrSet(dataset, cardIndex, -1, { currentGroupIndex: 1 });
        break;

      case RulesetActions.ADD_NEW_SET_ROW:
      case RulesetActions.DELETE_ROW:
      case RulesetActions.BETWEEN_SET_JOIN_OPERATOR:
      case RulesetActions.BETWEEN_ROW_JOIN_OPERATOR:
      case RulesetActions.FIELD_NAME_CHANGE:
      case RulesetActions.OPERATOR_CHANGE:
      case RulesetActions.FIELD_VALUE_CHANGE:
      case RulesetActions.FIELD_JOIN_OPERATOR_CHANGE:
        editGroupOrSet(dataset, cardIndex, card, { currentCardIndex: 1 });
        break;

      case RulesetActions.BETWEEN_SET_JOIN_OPERATOR_FROM_GROUP:
        editGroupOrSet(dataset, cardIndex, card, { currentGroupIndex: 1 });
        break;

      default:
        break;
    }

    validateSet(dataset);
    setDataSet(dataset);

    if (props.onChange) {
      props.onChange(dataset, changeType);
    }
  };

  const { className, fieldNames } = props;

  return (
    <React.Fragment>
      <div className={classNames(className)}>
        <RuleSetWrapper
          disabled={props.disabled}
          maxAllowedSets={props.maxAllowedSets}
          maxAllowedRows={props.maxAllowedRows}
          fieldNames={fieldNames}
          joinOperatorOptions={joinOperatorOptions}
          dataSet={dataSet}
          canAddNewGroup={props.canAddNewGroup}
          showHeaderJoinOperator={props.showHeaderJoinOperator}
          showPreview={props.showPreview}
          onChange={handleDataChanged}
          onFetchValueOptions={props.onFetchValueOptions}
        />
      </div>
    </React.Fragment>
  );
};

RuleSet.defaultProps = {
  showHeaderJoinOperator: true,
  showPreview: true,
  canAddNewGroup: true
};

RuleSet.displayName = 'RuleSet';
